@use '../Color.scss';

.modrulestatus {
  flex-direction: column;
  margin-top: 2vh;
  padding: 0 1.5vw;
}

.modrulestatus__badge {
  display: inline-block;
  padding: 8px 5px;
  border-radius: 7px;
  width: 100px;
  font-size: 0.9rem;

  &.badgedraft {
    background-color: #f09c5c;
    color: #ffffff;
  }

  &.badgerejected {
    background-color: #e8645f;
    color: #ffffff;
  }

  &.badgeexamined {
    color: #ffffff;
    background-color: #47ac6a;
  }

  &.badgeapproved {
    color: #ffffff;
    background-color: #5598cf;
  }
}

.modrulestatus__header {
  display: flex;
  position: relative;
  border-bottom: solid 2px Color.$background;
  width: 100%;

  .modrulestatus__tabitem {
    z-index: 10;
    width: 15.3vw;
    height: 4vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .badge {
      position: absolute;
      top: -1.8vh;
      margin-left: 12vw;
      color: Color.$white;
      width: 2vw;
      height: 3vh;
      font-size: 1.1rem;
      background-color: green;
    }
  }

  .btn-primary {
    background-color: Color.$background_sub;
    color: Color.$black;
    opacity: 0.8;
    border: none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &:hover {
      opacity: 1;
      background-color: Color.$background;
    }

    &:active {
      opacity: 1;
      background-color: Color.$background;
    }
  }
  .btn.active {
    opacity: 1;
    background-color: Color.$background;
    color: Color.$color;
    border: none;
  }
}

.modrulestatus__main {
  display: block;
  min-height: 35vh;
  padding-top: 1vh;

  &__header {
    display: flex;
    justify-content: space-between;

    &__searchBox {
      width: 20vw;
    }
    .headerRight {
      padding: 1vh;

      &__ruleWrite {
        display: flex;
      }
    }
  }

  &__tableheader {
    display: flex;
    justify-content: space-between;
    height: 5vh;
    margin-bottom: 1vh;
    & .btns__item {
      min-width: 5vw;
      padding: 5px 10px;
      margin: 0.5vh;
      border-width: 2px;
      border-radius: 10px;

      &:hover {
        // color: Color.$color_1;
        background-color: Color.$background;
      }
    }
    &__searchbar {
      display: flex;
      align-items: center;
      justify-content: space-around;

      select {
        border: none;
      }
    }

    .headerLeft__searchBox {
      margin-left: 15px;
    }
  }

  &__tablecontainer {
    max-height: 67vh;
    overflow-y: auto;
  }

  &__table {
    width: 100%;
    position: relative;

    &__header {
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: #f2f3f4;
      width: auto;
      height: 40px;
      font-weight: bold;
      border-bottom: 1px solid Color.$gray;

      & tr {
        height: 30px;
      }
      & td {
        width: 7vw;
        height: 40px;
        text-align: center;
        border-bottom: 1px solid Color.$gray;
      }

      & .depSelect {
        text-align: center;
        font-weight: bold;
        background-color: Color.$color;
        border: none;

        &:focus {
          box-shadow: none;

          & option {
            position: relative;
            text-align: center;
            background-color: Color.$white;
          }
        }
      }
    }

    &__body {
      // text-align: center;
      // overflow: hidden;

      & td {
        height: 5vh;
        border-bottom: 1px solid Color.$gray;
      }
      & td:nth-child(3) {
        padding-left: 1vw;
      }

      &__button {
        & Button {
          color: #000;
          border: none;
          font-size: 14px;
          margin-left: 20px;

          &:hover {
            background-color: Color.$background_1;
          }
          &:focus {
            color: Color.$white;
            background-color: Color.$background_1;
          }
        }
      }
    }
  }
}

.modrulestatus__main__table__before {
  height: 75vh;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}
