@use '../Color';

.mobileRuleChart {
  padding: 1vh;
  &__header {
    margin: 1vh 0 2vh 0;
    font-size: 0.8rem;
  }
  &__lanBtn {
    display: flex;
    border-bottom: solid 2px Color.$background;

    & a {
      height: 4vh;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.3vw;
      font-size: 0.8rem;
      background-color: Color.$background_sub;
      color: Color.$black;
      border: none;
      border-top-right-radius: 1vh;
      border-top-left-radius: 1vh;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        background-color: Color.$background;
      }
      &:active {
        background-color: Color.$background;
      }

      & a.on {
        border-bottom: none;
      }
    }
    .btn-check:checked + .btn,
    :not(.btn-check) + .btn:active,
    .btn:first-child:active,
    .btn.active,
    .btn.show {
      background-color: Color.$background;
      color: Color.$color;
      border: none;
    }
  }

  &__body {
    font-size: 0.8rem;
    height: 80vh;
    overflow-y: auto;

    & thead {
      position: sticky;
      top: 0;
      background-color: Color.$background_sub;
      & > tr > td:nth-child(n + 2) {
        writing-mode: vertical-rl;
      }
    }
  }
}
