@use '../../Color.scss';

.mobileRule {
  width: 100%;
  position: relative;

  &__header {
    display: flex;
    padding: 10px 10px 0 10px;
    min-height: 5vh;
    max-height: auto;

    &__back {
      padding: 10px;
    }

    // &__back {
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   min-width: 15%;
    //   height: 50px;
    //   margin: 0px 15px 15px 0px;
    // }
  }

  &__subHeader {
    width: 100%;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding: 1vh;
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    &__selectBox {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1vh;
    }

    .chapterItem {
      width: 63%;
    }
    .dateItem {
      width: 35%;
    }
    &__targetguide {
      float: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-radius: 0.5vh;
      border: 1px solid Color.$gray;
      box-shadow: 1px 1px 4px 1px #bbb;
      // width: 80%;
      height: 4vh;
      padding: 2vh;

      &__textBox {
        min-width: 8vw;
        display: flex;
        justify-content: space-around;
        & span:nth-child(1) {
          margin-right: 2vh;
        }
        & span:nth-child(2) {
          margin-right: 1vh;
          color: Color.$pointColor_1;
        }
        & span:nth-child(3) {
          margin-right: 2vh;
        }
      }
      &__btnBox {
        .btn {
          padding: 0;
          border: none;
          &:hover {
            color: Color.$pointColor_1;
          }
          &:active {
            color: Color.$pointColor_1;
          }
        }
      }
    }
  }

  &__body {
    width: 100%;
    height: 100vh; // 전체 화면 높이로 설정
    &__content {
      width: auto;
      height: 100%; // 부모 요소의 높이와 동일하게 설정
      overflow-y: auto;
      overflow-x: auto;
    }

    .mobileRuleContent {
      padding: 0 10px;

      &__title {
        text-align: center;
        font-size: 1.3rem;
        padding: 1vh;
        text-decoration: underline;
      }

      &__items {
        height: auto;
      }
    }
  }
}

// 우선순위 점수 100 id, #
#overide-css-mobileRuleCon {
  width: 100%;
  & img {
    margin: 0 auto;
    width: 80%;
  }
  & p {
    font-size: 0.9rem;
  }
  & ol > li {
    font-size: 0.9rem;
  }

  & b {
    font-size: 0.9rem;
    background-color: Color.$pointColor;
  }

  .ck-mobileContent {
    padding: 0.3vh;
    & h2 {
      font-size: 1.3rem;
      color: #0070c0;
    }
    & h3 {
      font-size: 1.1rem;
      color: #0070c0;
    }
    & h4 {
      font-size: 1rem;
    }
  }
  & div table {
    font-size: 0.6rem;
    width: 100%;
    border: 1px solid Color.$black;
    border-collapse: collapse;
    & thead th {
      border: 1px solid Color.$black;
      border-collapse: collapse;
    }
    & tbody td {
      border: 1px solid Color.$black;
      border-collapse: collapse;
    }
  }
}
