@use '../Color.scss';

.revinput {
  &__body {
    max-height: 73vh;
    overflow-y: auto;
    margin-bottom: 2vh;
    &__tablecontainer {
      min-height: 20vh;
      max-height: 65vh;
      overflow-y: auto;
      & table thead {
        background-color: Color.$gray;
        position: sticky;
        top: 0px;
        margin: 0;
      }
      & svg {
        cursor: pointer;
        &:hover {
          color: orange;
        }
      }
    }
    &__inputcontainer {
      display: flex;
      & > div {
        padding: 3px 10px;
      }
      & svg {
        cursor: pointer;

        &:hover {
          color: orange;
        }
      }
    }
  }

  &__footer {
    height: 5vh;
    text-align: right;
    & > button {
      margin-left: 0.5vw;
    }
  }
}
