@use '../Color.scss';
.draft {
  width: 100%;
  display: block;
  padding: 0 1.5vw;
  & > ul,
  li {
    // list-style: none;
    margin: 0;
    padding: 0;
  }

  &__subContent {
    display: flex;
    justify-content: flex-end;
    margin: 0.5vh;
    &__temporaryBox {
      padding-right: 40vh;
      align-items: center;
      display: flex;
      & > span {
        margin-right: 2vw;
      }
      .css-b62m3t-container {
        width: 22vw;
      }
      .css-13cymwt-control {
        width: 22vw;
      }
    }

    .btn {
      font-size: 1.1rem;
      // color: Color.$pointColor;
      border: none;

      &:hover {
        color: Color.$pointColor_1;
      }
      &:active {
        color: Color.$pointColor_1;
      }
    }
  }

  &__mainBody {
    max-height: 81vh;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
    h5 {
      color: gray;
    }
  }

  &__draftinfo {
    margin-top: 1vh;
    padding: 0.5vh;
    border: 1px solid #ccc;
    position: relative;

    &__title {
      position: relative;
      display: inline-block;
      top: -2vh;
      margin: 0;
      padding: 0 0.5vh;
      font-size: 1.2rem;
      min-width: 6vw;
      background-color: white;
    }

    &__top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 5vh;

      & > div {
        width: 33vw;
        display: flex;
        align-items: center;
        & > div:nth-child(1) {
          width: 5vw;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #ccc;
          background-color: lighten(Color.$gray, 15%);
        }
        & > div:nth-child(2) {
          width: 25.8vw;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #ccc;
          border-left: none;

          .cateDefaultValueBox {
            text-align: left;
            width: 24vw;
          }

          .cateSelectBox {
            text-align: left;
            width: 24vw;
          }

          .lanradiobtn {
            width: 20vw;
            .btn-outline-primary {
              // border-color: Color.$color;
            }
          }
        }
      }
    }
    &__editbottom {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 5vh;

      & > div {
        width: 33vw;
        display: flex;
        align-items: center;
        & > div:nth-child(1) {
          width: 5vw;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #ccc;
          background-color: lighten(Color.$gray, 15%);
        }
        & > div:nth-child(2) {
          width: 25.8vw;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #ccc;
          border-left: none;

          .ruleDefaultValueBox {
            text-align: left;
            width: 22vw;
          }

          .ruleSelectBox {
            text-align: left;
            width: 22vw;
          }

          .lanradiobtn {
            width: 20vw;
            .btn-outline-primary {
              // border-color: Color.$color;
            }
          }
          .temporaryList {
            text-align: left;
            width: 24vw;
          }
        }
      }
    }

    &__bottom {
      display: flex;
      width: 100%;
      height: 5vh;
      .temporaryList {
        text-align: left;
        width: 24vw;
      }

      .makeBtn {
        display: flex;
        justify-content: center;

        .btn-primary {
          width: 12vw;
          margin: 0.5vh 0 0 5vw;
          font-size: 1.1rem;
          border: none;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          // background-color: Color.$color;
          // &:hover {
          //   background-color: Color.$color_1;
          // }
          // &:active {
          //   background-color: Color.$color_1;
          // }
          // &:disabled {
          //   background-color: Color.$color_1;
          // }

          & svg {
            margin-top: 0.2vh;
            font-size: 1.5rem;
          }
        }
      }

      & > div {
        width: 33vw;
        display: flex;
        position: relative;
        text-align: center;
        align-items: center;
        & > div:nth-child(1) {
          width: 5vw;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #ccc;
          border-top: none;
          background-color: lighten(Color.$gray, 15%);
        }
        & > div:nth-child(2) {
          width: 25.8vw;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-right: 1px solid #ccc;
          border-bottom: 1px solid #ccc;

          & input {
            margin: 0 auto;
            width: 24vw;
            border: none;
            box-shadow: none;
          }
        }
      }

      &__newbtn {
        width: 15vw;
      }

      &__newpopup {
        top: 6vh;
        left: 5vw;
        padding: 10px;
        width: 25vw;
        position: absolute;
        z-index: 9999;
        border: 1px solid #ccc;
        box-shadow: 3px 3px 8px 0px;
        border-radius: 10px;
        background-color: Color.$white;

        & > .newpopup__header {
          text-align: center;
          font-size: 1.3rem;
          padding-bottom: 1.2vh;
          border-bottom: 1px solid Color.$gray;
        }
        & > .newpopup__body {
          padding-top: 2vh;
          padding-bottom: 2vh;

          & > div {
            display: flex;
            margin-top: 1vh;
            margin-bottom: 1vh;
            & label {
              width: 20%;
              align-self: center;
            }
            & input {
              width: 80%;
            }
          }
        }
        & > .newpopup__footer {
          padding-top: 1vh;
          padding-bottom: 1vh;
          border-top: 1px solid Color.$gray;
          text-align: right;
          & button {
            margin-left: 0.5vw;
          }
          // margin-top: 3vh;
        }
      }
    }
  }

  &__contentsForm {
    width: 100%;
    margin-top: 2vh;
    display: flex;
    height: 60vh;

    &__left {
      display: flex;
      width: 50%;
    }

    &__right {
      width: 50%;
    }

    &__formlist {
      display: flex;
      flex-direction: column;

      &__title {
        position: relative;
        top: -1.3vh;
        left: 0.5vh;
        margin: 0;
        width: 9vw;
        padding-left: 0.2vw;
        font-size: 1.2rem;
        background-color: white;
      }
    }

    &__forminput {
      &__container {
        padding: 1vh 2vw;
        border: 1px solid gray;
      }
      &__btns {
        padding: 1vh 2vw;
        & > button {
          float: right;
        }
        // text-align: right;
      }
    }

    &__notargetrule {
      &__ani {
        text-align: center;

        & > svg {
          margin: 0 3vw;
          animation-duration: 0.5s;
          animation-name: jumping;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }

        & > svg:nth-child(1) {
          animation-delay: 0.2s;
        }
        & > svg:nth-child(2) {
          animation-delay: 0.3s;
        }
        & > svg:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
      &__banner {
        text-align: center;
        width: 62vw;
        margin-top: 2vh;

        & > .banner__textbox {
          box-shadow: 3px 3px 8px 0px;
          padding: 2vh 2vw;
          border-radius: 10px;
          font-size: 1.2rem;
          & > span:nth-child(2) > span {
            box-shadow: Color.$pointColor 0px 0px 0px 1px,
              rgb(209, 213, 219) 0px 0px 0px 1px inset;
          }
        }
      }
    }
  }

  // 기안 메인컨텐츠
  &__contents {
    width: 100%;
    margin-top: 2vh;
    display: flex;
    height: 79vh;

    &__subContents {
      display: flex;
    }

    &__chapterlistWrap {
      display: flex;
      flex-direction: column;
      gap: 3vh;
    }

    &__chapterinfoWrap {
      display: flex;
      flex-direction: column;
      gap: 3vh;
    }

    &__chapterlist {
      width: 17vw;
      font-size: 1rem;
      font-weight: 700;
      position: relative;
      margin-right: 1vw;
      border: 1px solid #ccc;

      &__title {
        min-width: 7vw;
        display: inline-block;
        position: relative;
        top: -1.3vh;
        left: 0.5vh;
        margin: 0;
        padding: 0 0.5vh;
        font-size: 1.2rem;
        background-color: white;
      }

      &__contents {
        padding: 0 1vw;
        display: flex;
        flex-direction: column;
        width: 100%;
        overflow: auto;
        .btn {
          align-items: center;
          border: 1px solid Color.$background;
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.5vh;
          &:hover {
            border: 2px solid Color.$color;
          }
          &:active {
            border: 2px solid Color.$color;
          }
          &:focus {
            border: 2px solid Color.$color;
          }
          & svg:active,
          svg:hover {
            fill: Color.$pointColor;
          }
        }
      }

      &__old {
        display: flex;
        flex-direction: column;
        gap: 0.5vh;
        padding: 0 1vw;
        height: 35vh;
        max-height: 35vh;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          // display: none;
        }
        Button {
          height: 3vh;
          padding: 3px 5px;
        }

        & .chapterlist__old__item {
          color: black;
          background-color: transparent;
        }
        & .active {
          color: white;
          background-color: Color.$pointColor;
        }
      }
      &__new {
        // display: block;
        height: 35vh;
        max-height: 35vh;
        padding: 0 1vw;
        overflow: auto;

        &__addItem {
          display: flex;
          flex-direction: column;
          margin-bottom: 1vh;
        }
        &__list {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 29vh;
          overflow: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          .btn {
            height: 3.5vh;
            align-items: center;
            border: 1px solid Color.$background;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5vh;
            &:hover {
              border: 2px solid Color.$color;
            }
            &:active {
              border: 2px solid Color.$color;
            }
            &:focus {
              border: 2px solid Color.$color;
            }
            & svg:active,
            svg:hover {
              fill: Color.$pointColor;
            }
          }
        }
      }
    }
    &__chapterinfo {
      position: relative;
      gap: 2vh;
      border: 1px solid #ccc;

      &__title {
        position: relative;
        display: inline-block;
        margin: 0;
        padding: 0 0.5vh;
        font-size: 1.2rem;
        min-width: 2.3vw;
        background-color: white;
      }

      &__enactBody {
        display: flex;
        flex-direction: column;
        height: 75vh;
        max-height: 75vh;
        padding: 0 0.8vw;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__body {
        display: flex;
        flex-direction: column;
        height: 35vh;
        max-height: 35vh;
        padding: 0 0.8vw;
        overflow: auto;
        .btn {
          margin-bottom: 1vh;
        }
        .revStatusBox {
          margin-bottom: 0.5vh;
        }
      }

      &__contents {
        overflow-wrap: break-word;
      }

      .revstatusSection {
        position: absolute;
        padding: 5%;
        min-height: 30vh;
        max-height: 50vh;
        width: 40vw;
        top: 8vh;
        z-index: 9999;
        box-shadow: 0.4vh 0.4vh 0.8vh 0.3vh gray;
        border-radius: 10px;
        background-color: Color.$white;
        &__header {
          text-align: center;
          font-size: 1.3rem;
          padding-bottom: 1vh;
        }
        &__body {
          padding-top: 1vh;

          &__contents {
            // min-height: 10vh;
            height: 30vh;
            overflow: auto;
            &::-webkit-scrollbar {
              display: none;
            }
            & thead {
              position: sticky;
              top: 0;
              background-color: Color.$background_sub;
            }
            .form-control {
              box-shadow: none;
              border-color: Color.$gray;
            }
          }
        }
        &__bottom {
          float: right;
          margin-top: 2vh;
          .btn {
            border: none;
          }
        }
        &__oneTr > td:nth-child(1) {
          vertical-align: middle;
        }
      }

      &__summary {
      }

      &__chaptercontents {
      }
    }

    &__side {
      width: 34vw;
      display: flex;
      gap: 1.5vw;
      padding-right: 1.5vw;
    }

    &__main {
      width: 58vw;
      display: block;

      & .ck-editor__editable {
        min-height: 65.5vh;
        max-height: 65.5vh;
      }

      & .ck.ck-editor__editable_inline {
        border: 1px solid var(--ck-color-base-border);
      }

      &__header {
        display: block;
        gap: 1vw;
        margin-bottom: 1vh;

        .makeBtn {
          display: flex;
          justify-content: flex-end;
          margin-bottom: 1vh;

          .btn {
            width: 15vw;
            margin-right: 5vw;
          }
        }

        .mainConBtn {
          display: flex;
          justify-content: space-between;
          & > span:nth-child(1) {
            width: 15%;
            & .btn-primary {
              width: 100%;
              display: flex;
              flex-direction: row;
              border: none;
              .chapBtnSvg {
                display: flex;
                padding: 0;
                // width: 2vw;
                & svg {
                  font-size: 1.4rem;
                }
              }
              .chapAdd {
                width: 10vw;
                margin: 0;
                padding: 0;
              }
            }
          }
          .form-control {
            box-shadow: none;
            border-color: Color.$gray;
          }
          & > span:nth-child(2) {
            width: 25%;
            & > label {
              width: 100%;
            }
          }
          & > span:nth-child(3) {
            width: 58%;
            & > label {
              width: 100%;
            }
          }
        }
      }

      &__footer {
        display: block;
        // float: right;
      }

      &__editor {
        width: 100%;
        height: 30vh;
      }
      .toastui-editor-defaultUI {
        width: 100%;
      }
    }
  }
}

.draftpage {
  display: flex;

  &__maincontent {
    width: 82%;
    &__subContent {
      display: flex;
      // justify-content: flex-end;
      border-top: 1px solid gray;
      padding: 0.5vh;
      &__temporaryBox {
        padding-right: 40vh;
        align-items: center;
        display: flex;
        & > span {
          margin-right: 2vw;
        }
        .css-b62m3t-container {
          width: 22vw;
        }
        .css-13cymwt-control {
          width: 22vw;
        }
      }

      .btn {
        font-size: 1.1rem;
        // color: Color.$pointColor;
        border: none;

        &:hover {
          color: Color.$pointColor_1;
        }
        &:active {
          color: Color.$pointColor_1;
        }
      }
    }
    &__formfileinput {
      &__form {
        width: 50%;
        display: flex;
        align-items: center;
        .form-label {
          width: 5vw;
          padding-top: 0.8vh;
        }
        .form-control {
          width: 25vw;
        }
      }
    }
    &__formtextinput {
      display: flex;
      & > dl {
        width: 40%;
        display: flex;
        align-items: center;
        dt {
          width: 5vw;
        }
        dd {
          padding-top: 1vh;
        }
      }
    }
  }
}

@keyframes jumping {
  from {
    // margin-bottom: 0;
    // width: 300%
  }

  to {
    transform: translateY(-5px);
  }
}
