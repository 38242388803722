@use '../Color';

.revstatus {
  &__main {
    display: flex;
    gap: 1vw;
  }
  &__leftBox {
    width: 20%;
    height: 100%;
    display: flex;
    flex-direction: column;

    // DCN 상단
    .leftBox {
      &__header {
        height: 5vh;

        &__lanBtn {
          width: auto;
          display: flex;
          gap: 2%;
        }
        &__searchBox {
          width: 100%;
        }
      }

      &__table {
        text-align: center;
        overflow-y: auto;
        height: 80vh;
        &::-webkit-scrollbar {
          display: none;
        }

        & .tableBodyBox {
          width: 100%;
          height: 70vh;
          overflow-y: auto;
        }

        & thead {
          background-color: Color.$background_sub;
          position: sticky;
          top: 0;
          font-weight: bold;
        }
        & tbody > tr {
          cursor: pointer;
          &:hover,
          &:active,
          &:focus {
            background-color: #f7f7f7;
          }
        }
      }
    }
  }
  &__rightBox {
    width: 80%;
    .dcnTitle {
      margin-bottom: 2vh;
    }
    .table {
      max-height: 79vh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      & thead {
        background-color: Color.$background_sub;
        position: sticky;
        top: 0;

        font-weight: bold;
      }
      & tbody {
        font-size: 0.9rem;
        & > tr > td {
          border-right: 1px solid #ccc;
          &:nth-child(1) {
          }
          // 챕터 & 개정요약 table
          &:nth-child(2) {
            padding: 0;
            margin: 0;
            // 챕터 & 개정요약 table > tr
            .statusBox {
              border-bottom: 1px solid #ccc;
              &:nth-last-child(1) {
                border-bottom: none;
              }
            }
            // 챕터번호 <td>
            .chapterNumberBox {
              display: flex;
              flex-direction: column;
              width: 8.5vw;
              // margin-right: 1vh;
              padding: 1vh;
              // border-top: 1px solid #ccc;
              // &:nth-child(1) {
              //   border-top: none;
              // }
            }
            // 개정요약 <td>
            .summaryBox {
              width: 100%;
              padding: 1vh;
              vertical-align: top;
              border-left: 1px solid #ccc;
              // border-top: 1px solid #ccc;
              &:nth-child(1) {
                // border-top: none;
              }
            }
          }
        }
        & > tr > td:nth-child(1) {
          border-left: 1px solid #ccc;
        }
      }
    }
  }

  &__content {
    height: 62vh;
    display: block;

    &__header {
      height: 4.5vh;
      &__left {
        display: inline-flex;
        align-items: center;
        .css-b62m3t-container {
          width: 15vw;
          margin-right: 2vw;
        }
      }
      &__right {
        float: right;
      }
    }

    // DCN one
    &__enact {
      display: flex;
      justify-content: space-between;
      height: 55vh;

      &__chapterBtns {
        width: 10vw;
        min-height: 55vh;
        max-height: 55vh;
        overflow-y: auto;

        .enactchapter__chapterBtn {
          margin-bottom: 0.3vh;
        }

        .enactchapter__chapterBtn .btn {
          width: 100%;
          border: 1px solid Color.$black;
          color: Color.$black;
        }

        .enactchapter__chapterBtn .btn.active {
          color: Color.$pointColor;
          border: 2px solid Color.$pointColor;
        }
      }

      &__chapterContents {
        min-width: 18vw;
        max-width: 20vw;
        min-height: 55vh;
        max-height: 55vh;
        overflow-y: auto;
        border: 1px solid #ddd;
        > div:nth-child(1) {
          padding: 0.7vh;
          font-size: 1.1rem;
        }
        > div:nth-child(2) {
          padding-left: 1.2vw;
        }
      }

      &__content {
        min-width: 62vw;
        min-height: 55vh;
        max-height: 55vh;
        overflow-y: auto;
        padding: 0.5%;
        border: 1px solid Color.$black;
      }
    }

    &__amend {
      display: flex;
      justify-content: space-between;
      height: 55vh;

      &__oldRule {
        padding: 0.5%;
        min-height: 55vh;
        max-height: 55vh;
        width: 49.5%;
        border: 1px solid gray;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__NewRule {
        padding: 0.5%;
        min-height: 55vh;
        max-height: 55vh;
        width: 49.5%;
        border: 1px solid gray;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
        & span > ins,
        & span > ins > p,
        & span > ins > h2,
        & span > ins > h3,
        & span > ins > img {
          background-color: green;
        }

        & span > del,
        & span > del > p,
        & span > del > h2,
        & span > del > h3,
        & span > del > img {
          background-color: red;
        }
      }
    }
  }
}
