@use '../Color';

.revstatus {
  & ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__title {
    margin-bottom: 2.5vh;
  }
  .page-link {
    color: Color.$button;
    background-color: Color.$white;
    border: 1px solid Color.$gray;
  }

  .page-item.active .page-link {
    color: Color.$background;
    background-color: Color.$white;
    border: 1px solid Color.$gray;
  }

  .page-link:focus,
  .page-link:hover {
    color: Color.$background;
    background-color: Color.$white;
    border-color: Color.$gray;
  }

  &__subContent {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__left {
      padding-top: 1vh;
      float: left;
    }

    &__right {
      float: right;
      .btn {
        margin-right: 10px;
        border: 1px solid Color.$background;
      }
      & svg {
        margin-left: 10px;
      }
    }
  }

  &__mainbody {
    overflow-y: auto;
    margin-top: 1vh;
    max-height: 80vh;

    & > table {
      text-align: center;

      & thead {
        position: sticky;
        top: 0px;
        background-color: Color.$background_sub;
      }

      & > tbody td {
        vertical-align: middle;
        border-left: 1px solid Color.$gray;
      }

      & > tbody.table__amend td:nth-child(5) > span {
        text-align: left;
      }

      & > tbody.table__enact td:nth-child(5) > span {
        display: block;
      }
    }
  }

  // RevStatusList.jsx
  &__main {
    &__search {
      width: 300px;
      margin-bottom: 1vh;
      float: right;
    }

    &__list {
      text-align: center;

      &__title {
        background-color: Color.$background_sub;
        height: 40px;
        position: sticky;
        font-weight: bold;
      }
    }
  }
}
