@use '../../Color.scss';

.MobileRuleMix {
  width: 100%;
  // 아래 공간 생겨서 변경
  height: 90vh;

  &__header {
    // position: sticky;
    // top: 7vh;
    // z-index: 200;
    background-color: Color.$white;
    height: 6vh;
    // align-items: center;
    padding: 1vh;
    border-bottom: 2px solid Color.$black;

    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;

    &__back {
      padding: 10px;
    }

    & > span:nth-child(1) {
      width: 82%;
      font-size: 0.9rem;
    }
    & > span:nth-child(2) {
      width: 18%;
      font-size: 0.6rem;
      padding-top: 1vh;
      display: flex;
      justify-content: flex-end;
    }
  }

  &__body {
    margin: 0 auto;
    display: block;
    justify-content: center;

    &__contents__text {
      padding: 0 10px;
    }

    &__sidetabs {
      top: 13vh;
      height: 15vh;
      z-index: 200;
      overflow-y: auto;
      border-bottom: 2px solid Color.$black;

      &__wrapper {
        padding: 1vh;
        display: flex;
        flex-direction: column;

        & > Button {
          background-color: Color.$white;
          font-size: 0.6rem;
          border: 1px solid Color.$black;
          color: Color.$black;
          text-align: left;
          margin-bottom: 0.5vh;
          white-space: nowrap;
          word-wrap: normal;
          overflow: hidden;
        }
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__contents {
      width: 100%;
      max-height: 70vh;
      overflow-y: auto;
      overflow-x: hidden;

      position: relative;
      margin: 2vh 0;
      transform-origin: center;

      & .hysiap {
        overflow-y: hidden;
      }
      .docviewer__container {
      }
    }
  }
  #react-doc-viewer {
    & > div > img {
      width: 90%;
      max-width: 75vh;
    }
    #msdoc-renderer {
      min-height: 75vh;
    }
    #pdf-renderer {
      max-height: 75vh;
    }
  }
  &__main__formpartbtns {
    // width: 35%;
  }
}

// 우선순위 점수 100 id, #
#overide-css-mobileRuleCon {
  width: 100%;
  & img {
    margin: 0 auto;
    width: 80%;
  }
  & p {
    font-size: 0.9rem;
  }
  & ol > li {
    font-size: 0.9rem;
  }

  & b {
    font-size: 0.9rem;
    background-color: Color.$pointColor;
  }

  .ck-mobileContent {
    padding: 0.3vh;
    & h2 {
      font-size: 1.3rem;
      color: #0070c0;
    }
    & h3 {
      font-size: 1.1rem;
      color: #0070c0;
    }
    & h4 {
      font-size: 1rem;
    }
  }
  & div table {
    font-size: 0.6rem;
    width: 100%;
    border: 1px solid Color.$black;
    border-collapse: collapse;
    & thead th {
      border: 1px solid Color.$black;
      border-collapse: collapse;
    }
    & tbody td {
      border: 1px solid Color.$black;
      border-collapse: collapse;
    }
  }
}
