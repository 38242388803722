@use '../Color';

.gropJoin {
  h4 {
    margin-bottom: 4vh;
  }
  & > svg {
    width: 2.5em;
    height: 2.5em;
    fill: Color.$background;
    &:hover {
      fill: Color.$pointColor_1;
    }
  }

  ul,
  li {
    list-style: none;
  }
  &__contents {
    width: 100%;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;

    .leftBox {
      width: 48%;
    }
    .rightBox {
      width: 48%;
    }
    &__orderList {
      padding: 2%;
      box-shadow: 0.2vh 0.2vh 0.4vh 0.2vh Color.$gray;
      border-radius: 1vh;
      height: 45vh;

      & h5 {
        text-align: center;
        margin-bottom: 3vh;
      }
      & ul {
        & li {
          padding-bottom: 2vh;
          & span {
            color: Color.$pointColor;
          }
        }
      }
      & p {
        font-size: 0.8rem;
        color: Color.$pointColor;
      }
    }
    &__uploadFile {
      &__form {
        margin-top: 2vh;
        border-radius: 1vh;
        & svg {
          font-size: 1.5rem;
        }
      }

      &__inputBox {
        width: 100%;
        margin-top: 2vh;
        padding: 1vh;
        border-radius: 1vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0.2vh 0.2vh 0.4vh 0.2vh Color.$gray;

        .form-label {
          height: 2vh;
          min-width: 10vw;
          line-height: 5vh;
          font-size: 1.2rem;
        }
        .form-control {
          margin-top: 10px;
          box-shadow: none;
        }
      }
    }

    .fileSaveCheck {
      position: absolute;
      top: 13vh;
      width: 30vw;
      max-height: 50vh;
      background-color: Color.$white;
      box-shadow: 4px 4px 5px 3px #ccc;
      padding: 1vh;

      &__header {
        display: flex;
        justify-content: space-between;
        svg:hover {
          fill: Color.$pointColor_1;
          cursor: pointer;
        }
      }
      &__bottom {
        display: flex;
        flex-direction: column;
        &__successList {
          padding: 1vh 0;
        }
        &__failList {
          border: 1px solid #ccc;
          .listHeader {
            display: flex;
            justify-content: center;
            border-bottom: 1px solid #ccc;
          }
          .listContents {
            padding: 1vh;
            min-height: 20vh;
            max-height: 35vh;
            overflow-y: scroll;
            display: flex;
            flex-direction: column;
            &::-webkit-scrollbar {
              display: none;
            }
            & > div {
              display: flex;
              & > span:nth-child(1) {
                width: 50%;
              }
            }
          }
        }
      }
    }
    .photoSaveCheck {
      position: absolute;
      top: 13vh;
      width: 40vw;
      max-height: 50vh;
      background-color: Color.$white;
      box-shadow: 4px 4px 5px 3px #ccc;
      padding: 1vh;

      &__header {
        display: flex;
        justify-content: space-between;
        svg:hover {
          fill: Color.$pointColor_1;
          cursor: pointer;
        }
      }
      &__main {
        display: flex;
        gap: 2%;
        justify-content: space-between;
        width: 100%;
        margin-top: 2vh;
        h5 {
          position: relative;
        }
        & > div {
          width: 48%;
          .listHeader {
            display: flex;
            justify-content: center;
          }
        }
        &__noupload {
          border: 1px solid #ccc;
          & > span {
            padding: 1vh;
            border-bottom: 1px solid #ccc;
          }
          & > div {
            margin: 1vh;
            min-height: 20vh;
            max-height: 35vh;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            line-height: 4vh;
            padding: 0 1vh;
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
        &__okupload {
          border: 1px solid #ccc;

          & > span {
            padding: 1vh;
            border-bottom: 1px solid #ccc;
          }
          & > div {
            display: flex;
            flex-direction: column;
            margin: 1vh;
            min-height: 20vh;
            max-height: 35vh;
            overflow-y: auto;
            line-height: 4vh;
            padding: 0 1vh;
            // border: 1px solid #ccc;
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }
  }
}
