@use 'Color';

.loginpage {
  width: 100%;
  height: 93vh;
  background-color: lighten(Color.$button, $amount: 24%);

  &__logo {
    position: absolute;
    top: 20vh;
    left: 47.4vw;
    width: 100px;
    height: 100px;
    z-index: 999;
    border: 1px solid Color.$background;
    box-shadow: 0px 0px 15px 2px Color.$gray;
    border-radius: 50%;
    background-color: Color.$white;

    & svg {
      display: flex;
      margin: 0 auto;
      justify-content: center;
      align-items: center;
      padding-top: 1.5vh;
      fill: Color.$background;
      font-weight: 100;
      stroke-width: '1px';
    }
  }

  &__main {
    position: absolute;
    top: 26vh;
    left: 37.6vw;
    width: 25vw;
    min-height: 50vh;
    max-height: 60vh;
    display: flex;
    justify-content: center;
    padding: 8vh 1vh 1vh 1vh;
    border: 1px solid Color.$background;
    box-shadow: 0px 0px 15px 2px Color.$gray;
    border-radius: 1vh;
    background-color: Color.$white;

    & > .loginForm {
      width: 23vw;

      .form__group {
        display: block;
        color: Color.$gray;

        .mb-3 {
          display: block;
        }
        .form-control {
          width: 100%;
          display: block;
          border: none;
          box-shadow: none;
          border: 1px solid #ccc;
        }
        &:focus {
          border: 1px solid Color.$background;
          box-shadow: none;
        }

        & .checkbox {
          margin-right: 1vh;
        }
      }
      .failedBox {
        &__messageBox {
          width: 100%;
          height: 6vh;
          margin-top: 3vh;
        }
      }
      .loginButton.btn.btn-primary {
        margin: 2vh 0;
        width: 23vw;
        height: 6vh;
        background: Color.$background;
        border: none;
        box-shadow: 0px 0px 3px 1px #aaa;
        &:hover {
          background-color: Color.$background_1;
        }
        &:active {
          background-color: Color.$background_1;
        }
        &:focus {
          background-color: Color.$background_1;
        }
      }
    }
  }
}
