@use '../Color.scss';

.approvalmodal {
  width: 54vw;
  min-width: 54vw;
}

.approvalmodal__top {
  width: 100%;
  margin-bottom: 1vh;
  display: flex;
  justify-content: space-between;
  gap: 4vw;
  .leftBox {
    width: 34vw;
    .payBox {
      padding: 5%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-bottom: 1px solid #ccc;
      &__title {
        text-align: center;
      }
      &__select {
        width: 20vw;
      }
      &__subCon {
        padding-right: 4vw;
        color: Color.$gray;
      }
      & .btn {
        &:hover,
        &:active,
        &:focus {
          color: Color.$pointColor_1;
        }
      }
    }
    .searchBox {
      padding-top: 4.5vh;
      float: right;
      .SearchBar {
        margin-right: 3px;
        width: 17vw;
      }
    }
  }
}

.writerBox {
  width: 14vw;
  height: 18.5vh;

  &__tbody {
    width: 14vw;
    height: 16vh;
    text-align: center;
    border: 1px solid gray;
    // border-radius: 0.8vh;
    list-style: none;
    line-height: 2.9vh;
    padding: 0;
    & li {
      width: 100%;
      align-items: center;
    }
    & li:nth-child(2) {
      width: 100%;
      height: 5vh;
      gap: 5%;
      .signatureimg {
        height: 100%;
      }
    }
  }
}

.approvalmodal__bottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1vh;
  & > .list {
    width: 34vw;
    display: flex;
    justify-content: space-between;

    .list__orgs__body {
      min-height: 51vh;
      max-height: 51vh;
      width: 15vw;
      padding: 0 1vh;
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid Color.$gray;
      // 스크롤 커스텀
      &::-webkit-scrollbar {
        width: 0.8vw;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(117, 117, 117);
        border-radius: 0.5vh;
        background-clip: padding-box;
        border: 2px solid transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: #dcdcdc;
        border-radius: 0.5vh;
        box-shadow: inset 0px 0px 5px white;
      }

      & > .list__orgs__item > .list__orgs__item__title {
        margin-bottom: 0.5vh;
        border-bottom: 1px solid Color.$gray;
        height: 3.5vh;
        & > span {
          line-height: 3.5vh;
        }
      }
    }

    .list__orgs__item {
      &__title {
        cursor: pointer;
        &:hover {
          color: Color.$pointColor_1;
        }
        &.active {
          background-color: lighten(Color.$pointColor, 30%);
        }
      }

      &__title > span:first-child {
        padding-right: 0.1vw;
      }

      & > .orgchild > .list__orgs__item {
        padding-left: 0.8vw;
      }
    }

    .list__users__body {
      min-height: 51vh;
      max-height: 51vh;
      width: 18vw;
      border: 1px solid Color.$gray;
      overflow-y: auto;
      overflow-x: hidden;
      // 스크롤 커스텀
      &::-webkit-scrollbar {
        width: 0.8vw;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(117, 117, 117);
        border-radius: 0.5vh;
        background-clip: padding-box;
        border: 2px solid transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: #dcdcdc;
        border-radius: 0.5vh;
        box-shadow: inset 0px 0px 5px white;
      }

      & > table {
        width: 100%;
        padding-top: 10px;
        text-align: center;

        & > thead {
          position: sticky;
          top: 0;
          height: 3.5vh;
          background-color: Color.$white;
          & > tr > th {
            box-shadow: inset 0 -1px 0 Color.$gray;
          }
        }

        & > tbody > tr > td {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .list__users__item {
          cursor: pointer;
          &:hover {
            color: Color.$pointColor_1;
          }
          &.active {
            background-color: lighten(Color.$pointColor, 30%);
          }
        }
      }
    }
  }

  & > .approvalContainer {
    > .examineBox,
    .approveBox,
    .referenceBox {
      display: flex;
      height: 17vh;
      max-height: 17vh;
    }
  }
}

.approvalContainer > div .arrowBox {
  width: 4vw;
  height: 17vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5%;
  & > div svg {
    cursor: pointer;
  }
  & > div:first-child > svg:hover {
    color: green;
  }
  & > div:nth-child(2) > svg:hover {
    color: red;
  }
}

.approvalContainer > div .listContainer {
  & > .listBox {
    width: 14vw;
    max-width: 15.2vw;
    height: 14vh;
    border: 1px solid gray;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .btn {
      padding: 3px 4px;
      display: block;
      border: none;
      text-align: left;
      font-size: 0.82rem;
      & > span {
        // font-size: 0.8rem;
      }
    }
    .btn:hover,
    .btn:focus {
      border: none;
      color: Color.$pointColor_1;
    }
  }
}

.approvalmodal__footer {
  & .btn {
    padding: 3px 0;
    &:hover,
    &:active,
    &:focus {
      color: Color.$pointColor_1;
    }
  }
}
