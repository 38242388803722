@use '../Color.scss';

.mobile-header {
  height: 7vh;
  position: sticky;
  top: 0;
  width: 100%;
  background: Color.$background;
  padding: 0 1vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  & > div {
    width: 20vw;
    .btn {
      background-color: none;
      border: none;
      & > span {
        color: Color.$white;
        font-size: 1.3rem;
      }
      svg {
        fill: Color.$white;
      }
    }
  }
  &__wrap:nth-child(1) {
    float: left;
  }
  &__wrap:nth-child(2) {
    display: flex;
    justify-content: center;
    width: auto;
  }
  &__wrap:nth-child(3) {
    display: flex;
    justify-content: flex-end;
    .btn {
      padding: 0 1.1vh;
    }
    // width: 22%;
    // .search {
    //   width: 90%;
    //   height: 12vh;
    //   position: relative;
    //   top: 6vh;
    //   right: 1vw;
    //   padding: 1vh;
    //   box-shadow: 1px 1px 3px 2px #aaa;
    //   border-radius: 1vh;
    //   background-color: Color.$white;
    //   & > div:nth-child(1) {
    //     padding: 0.6vh;
    //     display: flex;
    //     justify-content: space-between;
    //   }
    //   & > div:nth-child(2) {
    //     margin: 0 auto;
    //     width: 80vw;
    //     height: 5vh;
    //   }
    // }
  }
}
.mobileSearchModal {
  position: absolute;
  right: 0.5vh;
  top: 6vh;
  width: 98%;
  height: 10vh;
  display: flex;
  background-color: Color.$white;
  box-shadow: 0.4vh 0.4vh 1vh 0.1vh #aaa;
  & > div {
    width: 90%;
    margin: 0 auto;
  }
}
.mobileUserInfoModal {
  & > .modal-dialog {
    position: absolute;
    top: 6vh;
    right: 0;
  }
  &__sec {
    border-radius: 1vh;
    display: block;

    &__name {
      font-size: 1.2rem;
      text-align: center;
    }

    &__page > a {
      text-decoration: none;
      padding: 2vh;
      color: Color.$white;
      border-inline-start: none;
      text-align: center;
      display: block;
      font-size: 1rem;
      font-weight: 600;
      border-radius: 1vh;
      background-color: Color.$color;
      &:hover {
        color: Color.$white;
        background-color: Color.$color_1;
      }
    }
    &__out {
      background-color: antiquewhite;
      .btn {
        margin: 0 auto;
        font-size: 0.9rem;
      }
    }
  }
}
