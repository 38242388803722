@use '../Color';

.mobilsection {
  width: 100%;
  height: 93vh;
  &__top {
    width: 100%;
    height: 25%;
    background-color: #cccccc34;
    & > div {
      width: 70%;
      height: 100%;
      margin: 0 auto;
    }
  }
  &__body {
    width: 100%;
    height: 65%;
    margin: 0 auto;
    // display: grid;
    // flex-direction: column;
    display: block;
    gap: 3vh;

    .menuBox {
      padding-top: 8vh;
      width: 90%;
      height: 100%;
      margin: 0 auto;

      &__upContents {
        width: 100%;
        display: flex;
        margin-bottom: 3vh;
        justify-content: center;
        gap: 3vh;
        & > div {
          width: 100px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 1vh;
          border: 4px solid Color.$background;
          & > a {
            text-decoration: none;
            & > svg {
              fill: Color.$background;
              display: flex;
              justify-content: center;
              overflow-y: auto;
              font-size: 4rem;
            }
            & > span {
              color: Color.$background;
              display: flex;
              justify-content: center;
              font-size: 0.9rem;
            }
          }
        }
      }
      &__downContents {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 3vh;
        & > div {
          width: 100px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 1vh;
          border: 4px solid Color.$background;
          & > a {
            text-decoration: none;
            color: Color.$background;
            & > svg {
              // fill: Color.$background;
              display: flex;
              justify-content: center;
              font-size: 4rem;
            }
            & > span {
              color: Color.$background;
              display: flex;
              justify-content: center;
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}
