@use 'Color';

.alarm__popup {
  position: absolute;
  top: 6vh;
  right: 0;
  z-index: 9999;
  width: 35vw;
  height: auto;
  border-radius: 1vh;
  // border: 1px solid Color.$black;
  box-shadow: 2px 2px 5px 1px #aaa;
  background-color: Color.$white;

  &__header {
    height: 5vh;
    display: flex;
    padding: 0px 0.5vw;
    width: 100%;
    background-color: Color.$white;
    border-bottom: 1px solid Color.$gray;
    border-top-left-radius: 1vh;
    border-top-right-radius: 1vh;
    align-items: center;

    > span {
    }

    & > .btn {
      margin-left: 0.5vw;
      padding: 3px 10px;
    }

    & > .close__btn {
      margin-left: auto;
      align-items: center;
      vertical-align: middle;
      &:hover {
        cursor: pointer;
      }
    }
  }

  &__body {
    padding: 7px;
    max-height: 70vh;
    overflow-y: auto;
  }

  // &__footer {
  //   height: 3vh;
  //   font-size: 1rem;
  //   padding: 3px 0.5vw;
  //   color: gray;
  // }

  & ul {
    .preview {
      width: 100%;
      margin-bottom: 1vh;
      display: flex;
      // align-items: center;
      justify-content: space-between;
      background-color: #f8f8f8;
      box-shadow: 3px 3px 3px 1px #ddd;

      &.read {
        opacity: 50%;
      }

      &__tagbox {
        width: 13%;
        font-size: 0.9rem;
        text-align: center;
        height: 7vh;
        display: flex;
        justify-content: center;

        span {
          padding: 3px 5px;
          align-self: center;
          color: Color.$white;
          background-color: Color.$background;
          border-radius: 5px;
          border: 1px solid Color.$background;
        }
        .tag__amend {
          background-color: Color.$color;
          border: 1px solid Color.$color;
        }
        .tag__cir {
          background-color: #569daa;
          border: 1px solid #569daa;
        }
        .tag__checklist {
          background-color: #ff7f50;
          border: 1px solid #ff7f50;
        }
      }
      &__contents {
        flex-grow: 1;
        height: 7vh;
        & > div {
          height: 3.5vh;
        }
        & > div:nth-child(1) {
          flex: 1;
          color: Color.$pointColor;
          font-size: 0.92rem;
          display: flex;
          align-items: center;
        }
        & > div:nth-child(2) {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.92rem;
          & > span {
            line-height: 3.5vh;
          }
          & > span:nth-child(2) {
            font-size: 0.8rem;
            color: #5b5b5b;
          }
        }
      }
      &__svgBtns {
        width: 14%;
        padding: 0 1vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &__linkExternal {
          color: Color.$background;
        }

        &__linkExternal:hover {
          color: Color.$pointColor;
        }
        &__delete {
          color: Color.$background;
        }
        &__delete:hover {
          color: red;
        }
      }
    }
  }
}

// .preview {
//   width: 80%;
//   display: flex;
//
// }

// .svgBtns {
//   width: 20%;
// }
