@use '../Color.scss';
.reference {
  & ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__subContent {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__left {
      padding-top: 1vh;
      float: left;
    }

    &__right {
      float: right;
      .btn {
        margin-right: 10px;
        font-size: 1.1rem;
        border: none;
        &:hover {
          border: none;
          color: Color.$pointColor_1;
        }
        &:active {
          border: none;
          color: Color.$pointColor_1;
        }
      }
      & svg {
        margin-left: 10px;
      }
    }
  }

  &__mainBody {
    max-height: 82vh;

    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__table {
    margin-top: 1vh;
    & thead {
      background-color: Color.$background_sub;
    }
  }

  &__line {
    max-width: 100%;
    display: flex;
    justify-content: center;

    &__itemBox {
      display: flex;
      overflow-x: auto;

      &__subSection {
        display: flex;
        margin-right: 3vh;
      }
    }
    .modRuleBox {
      min-width: 8vw;
      max-width: 10vw;
      height: 18vh;
      text-align: center;
      border: 2px solid gray;
      margin-right: 1vh;
      border-radius: 0.5vh;
      &__thead {
        width: 100%;
        height: 3vh;
        color: Color.$black;
        border-bottom: 2px solid gray;
        border-top-right-radius: 0.5vh;
        border-top-left-radius: 0.5vh;
      }
      &__tbody {
        & ul {
          min-width: 7.8vw;
          max-width: 9.8vw;
          height: 14.5vh;
          & li {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          & li:nth-child(1) {
            height: 3vh;
          }
          & li:nth-child(2) {
            width: 100%;
            height: 5.6vh;
            gap: 5%;
            .signatureimg {
              // width: 100%;
              height: 100%;
            }

            .btn {
              border: none;
              background-color: Color.$pointColor_1;
            }
          }
          & li:nth-child(3) {
            height: 3vh;
          }
          & li:nth-child(4) {
            height: 3vh;
          }
        }
      }
    }

    .drafterBox {
      margin-right: 3vh;
    }

    .reviewerBox {
      border: 2px solid Color.$color_1;
      &__thead {
        color: Color.$color_1;
        border-bottom: 2px solid Color.$color_1;
      }
    }

    .paymentBox {
      border: 2px solid Color.$pointColor_1;
      &__thead {
        color: Color.$pointColor_1;
        border-bottom: 2px solid Color.$pointColor_1;
      }
    }

    .readerBox {
      &__tbody {
        height: 14.5vh;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }
        & ul {
          min-width: 8vw;
          max-width: 10vw;
          height: 3.5vh;
          line-height: 4vh;
        }
      }
    }
  }

  &__content {
    height: 62vh;
    display: block;

    &__header {
      height: 5vh;
      &__left {
        display: inline-flex;
        align-items: center;
        .css-b62m3t-container {
          width: 15vw;
          margin-right: 2vw;
        }
      }
      &__right {
        float: right;
      }
    }

    &__enact {
      display: flex;
      justify-content: space-between;
      height: 55vh;

      &__chapterBtns {
        width: 10vw;
        min-height: 55vh;
        max-height: 55vh;
        overflow-y: auto;

        .enactchapter__chapterBtn {
          margin-bottom: 0.3vh;
          border: 2px solid Color.$black;
          border-radius: 0.5vh;
        }

        .enactchapter__chapterBtn .btn {
          width: 100%;
          color: Color.$black;
          border: none;
          &:hover {
            background-color: lighten(Color.$pointColor, 30%);
            border: none;
          }
        }

        .enactchapter__chapterBtn .btn.active {
          color: Color.$pointColor_1;
          border: none;
        }
      }

      &__chapterContents {
        min-width: 15vw;
        max-width: 20vw;
        min-height: 55vh;
        max-height: 55vh;
        overflow-y: auto;
      }

      &__content {
        min-width: 60vw;
        min-height: 55vh;
        max-height: 55vh;
        overflow-y: auto;
        padding: 0.5%;
        border: 1px solid gray;
      }
    }

    &__amend {
      display: flex;
      justify-content: space-between;
      height: 55vh;

      & > ins {
        background-color: green;
      }

      & > ins > p {
        background-color: green;
      }

      & > ins > img {
        border: 2px solid green;
      }

      & > del {
        background-color: red;
      }

      & > del > p {
        background-color: red;
      }

      & > del > img {
        border: 2px solid red;
      }
      &__oldRule {
        padding: 0.5%;
        min-height: 55vh;
        max-height: 55vh;
        width: 49.5%;
        border: 1px solid gray;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      &__NewRule {
        padding: 0.5%;
        min-height: 55vh;
        max-height: 55vh;
        width: 49.5%;
        border: 1px solid gray;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .commentTable {
    height: 18vh;
    overflow-y: auto;
    &__thead {
      width: 100%;
      height: 4vh;
      display: flex;
      align-items: center;
      position: sticky;
      top: 0;
      padding-left: 1vh;
      background-color: Color.$background_sub;
      border-bottom: 1px solid #ccc;
    }
    &__tbody {
      padding-left: 1vh;
      & div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        height: 4vh;

        & span:nth-child(1) {
          width: 8vw;
          font-size: 0.9rem;
        }
        & span:nth-child(2) {
          width: 8vw;
          font-size: 0.9rem;
        }
        & span:nth-child(3) {
          width: 75vw;
        }
        .targetComment {
          .btn {
            width: 2vw;
            height: 3vh;
            padding: 0;
            margin: 0;
            font-size: 0.7em;
          }
        }
      }
    }
  }
}

.modRuleOne__dateModal {
  .modal-title {
    padding-left: 5vw;
  }
  .modRuleOne__dateModal__body {
    display: block;
    align-items: center;

    & > .dateBox__info {
      font-size: 11pt;
      color: Color.$pointColor_1;
    }

    &__title {
      text-align: center;
      margin-bottom: 1vh;
      width: 100%;
      height: 4vh;
      color: Color.$pointColor;
      background-color: Color.$background;
      border-radius: 10px;
      span {
        line-height: 4vh;
      }
    }
  }
}

.modRuleOne__infoModal {
  .modal-title {
    padding-left: 5vw;
  }
  .modRuleOne__infoModal__body {
    display: flex;
    align-items: center;
    text-align: center;
    line-height: 5vh;

    .table {
      & tbody {
        & tr {
          & th {
            width: 100px;
          }
        }
      }
    }
  }
}

.modRuleOne__revStatusModal-modal {
  width: 90vw !important;
  max-width: 90vw !important;
}

.modRuleOne__revStatusModal {
  &__title {
    width: 100%;
  }

  &__body {
    width: 100%;
    height: 70vh;
    align-items: center;
  }

  &__bodycon {
    display: flex;
  }

  &__tablecon {
    width: 50%;
    overflow-y: auto;
    max-height: 65vh;
    border-bottom: 1px solid gray;
  }

  &__table {
    width: 100%;
    table-layout: fixed;
    & pre {
      font-family: 'Pretendard', sans-serif;
      font-size: 0.9em;
      white-space: pre-wrap;
      word-break: break-all;
      overflow: auto;
    }
    thead th {
      font-size: 1em;
    }

    & th,
    td {
      text-align: center;
      border: 1px solid #444444;
    }
    .sumtd {
      padding: 5px;
      text-align: left;
    }
    .chaptd {
      padding: 5px;
    }
  }

  &__inputs {
    width: 50%;
    padding-left: 1vw;
    table,
    th,
    td {
      border: 1px solid #bcbcbc;
      text-align: center;
    }
    & > .inputs__table {
      width: 100%;
      max-height: 65vh;
    }

    & > .modRuleOne__revStatusModal__bottom {
      float: right;
    }
  }

  &__bottom {
  }
}
