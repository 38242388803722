body {
  margin: 0;
}

#root {
  display: block;
}

.main-container {
  display: flex;
}

.mainsection {
  width: 96vw;
  padding: 1vh 1.5vw 0 1.5vw;
}

.draftsection {
  width: 96vw;
}

.toastui-editor-contents table thead p {
  // background-color: #fff !important;
  display: inline;
}

.Toastify {
  &__toast {
    box-shadow: 2px 2px 5px 1px #ccc;
  }
  // &__toast-container--bottom-right {
  //   width: 20vw;
  // }
  // &__toast-container--top-center {
  //   width: auto;
  // }
  &__toast-body {
    padding: 1vh;
  }
}

.Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__B-rightIn;
}
@keyframes Toastify__B-rightIn {
  from {
    transform: translateY(100%);
    animation-timing-function: ease-in;
  }
  to {
    opacity: 1;
  }
}
.Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__B-rightOut;
}
@keyframes Toastify__B-rightOut {
  to {
    opacity: 0;
  }
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__T-centerIn;
}
@keyframes Toastify__T-centerIn {
  from {
    transform: translateY(-130%);
  }
  to {
    transform: translateY(0);
  }
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__T-centerOut;
}
@keyframes Toastify__T-centerOut {
  to {
    transform: translateY(0);
  }
}

// .editor-container {
//   min-height: 64vh;
//   max-height: 64vh;
//   overflow: auto;
// }
