@use '../Color';

.mobile-sidenav {
  position: fixed;
  top: 7vh;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.808);

  &__menubtns {
    width: 40%;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: Color.$white;
    .btn {
      width: 45vw;
      height: 7vh;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0;
      margin-top: 0.2vh;
      border: 0;
      padding: 0 5vw;
      svg {
        width: 10vw;
        font-size: 1.8rem;
        padding: 0;
      }
      span {
        min-width: 16vw;
      }
    }
  }
}
