@use '../Color.scss';

.ruleinfo__rulelist {
  &__header {
    height: 4vh;
    position: relative;
  }
  &__tableBox {
    max-height: 85vh;
    overflow-y: auto;
    thead {
      position: sticky;
      top: 0;
      background-color: Color.$background_sub;
    }
  }
}

.table.rulelist__table {
  width: 100%;
}
