@use 'Color';

.ruleChart {
  &__header {
    margin-bottom: 2vh;
  }
  &__lanBtn {
    display: flex;
    justify-content: space-around;

    & span {
      font-size: 1.3rem;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    height: 78vh;
    .ruleBtn {
      padding: 0;
      border: none;
      margin-left: 0.6vh;
      svg {
        font-size: 0.9rem;
      }
      svg:hover,
      svg:active,
      svg:focus {
        color: Color.$pointColor;
      }
    }
    .icon {
      transition: all ease 0.3s;
    }
    .icon.open {
      transform: rotate(180deg);
      color: rgb(241, 133, 70);
    }
    .ruleBox {
      background-color: #f9f9f9;
      & > td:nth-child(1) {
        padding-left: 1.3vw;
      }
    }
    &__left {
      width: 45.5vw;
      float: left;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &__right {
      width: 45.5vw;
      float: right;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    & thead {
      position: sticky;
      top: 0;
      font-size: 0.9rem;
      background-color: Color.$background_sub;
      & tr {
        & td:nth-child(1) {
          text-align: center;
        }
        & td:nth-child(2) {
          width: 3vw;
          text-align: center;
        }
        & td:nth-child(3) {
          width: 3vw;
          text-align: center;
        }
        & td:nth-child(4) {
          width: 3vw;
          text-align: center;
        }
        & td:nth-child(5) {
          width: 3vw;
          text-align: center;
        }
        & td:nth-child(6) {
          width: 4vw;
          text-align: center;
        }
        & td:nth-child(7) {
          width: 3vw;
          text-align: center;
        }
      }
    }
    & tbody {
      & tr {
        & td:nth-child(1) {
          // padding-left: 1vw;
        }
        & td:nth-child(2) {
          width: 3vw;
          text-align: center;
        }
        & td:nth-child(3) {
          width: 3vw;
          text-align: center;
        }
        & td:nth-child(4) {
          width: 3vw;
          text-align: center;
        }
        & td:nth-child(5) {
          width: 3vw;
          text-align: center;
        }
        & td:nth-child(6) {
          width: 4vw;
          text-align: center;
        }
        & td:nth-child(7) {
          width: 3vw;
          text-align: center;
        }
      }
    }
  }
}
