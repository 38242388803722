@use '../Color';

.loginForm {
  display: block;
  width: 90%;
  margin: 0 auto;
  padding-top: 8vh;

  &__title {
    text-align: center;
    font-size: 1rem;
    font-weight: bolder;
    margin-bottom: 6vh;
    color: Color.$background;
  }
  &__inputsBox {
    & > div {
      width: 100%;
      height: 6vh;
      margin: 3vh 0;
      display: flex;
      // align-items: center;

      .form-label {
        display: flex;
        align-items: center;
        width: 20%;
      }
      .form-control {
        width: 75%;
        &:focus {
          box-shadow: none;
          border: 1px solid Color.$black;
        }
      }
    }
  }
  &__checkBox {
    display: flex;
    align-items: center;
    .checkbox {
      margin-left: 1vw;
    }
  }
  &__messageBox {
    margin-top: 1vh;
    height: 5vh;
  }

  &__saveBox {
    margin-top: 5vh;
    display: block;
    .btn {
      width: 100%;
      height: 6vh;
      margin: 1vh 0;
    }
  }
}
