@use 'Color';

// .modal-backdrop.UserInfo {
//  modal 영역 클릭 안되는 문제로 변경 중
//   background-color: Color.$background_sub;
//   opacity: 0.6;
// }
.header {
  height: 7vh;
  background: Color.$background_sub;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  * ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  * a {
    text-decoration: none;
    color: black;
    &:hover {
      color: Color.$pointColor;
    }
  }
  &__button {
    margin-left: 1.5vw;

    &__splitline {
      align-self: center;
      color: Color.$black;
      margin: 0 1.2vw;
    }

    &__Styleleft {
      padding-left: 1vw;
      background-color: transparent;
      border: none;
      color: Color.$black;
      font-weight: bold;
    }
    &__Styleright {
      background-color: transparent;
      border: none;
      font-weight: bold;
      color: Color.$black;
      font-weight: bold;
    }
  }

  &__changePassword {
    position: absolute;
    top: 20vh;
    width: 100%;
    z-index: 300;
    &__popup {
      position: relative;
      padding: 1.5vw;
      width: 50vw;
      height: 47vh;
      margin: 0 auto;
      // border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: 0px 0px 15px 2px Color.$gray;
      background-color: Color.$white;
      .headerBox {
        height: 12%;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
      }
      .textBox {
        padding: 1vw 0;
        height: 73%;
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-bottom: 1px solid Color.$gray;
        &__exBox {
          display: flex;
          flex-direction: column;
          line-height: 3vh;

          & > span:nth-child(4) {
            margin-top: 1vh;
          }
        }
        &__noticeBox {
          padding: 1vh 1vh;
          // margin-top: 2vh;
          font-size: 0.8rem;
          background-color: #f5f5f5;
          display: flex;
          flex-direction: column;
        }
      }
      .btnBox {
        height: 20%;
        width: 100%;
        padding: 1vw 0;

        .btn {
          margin-right: 1vw;
        }
      }
    }
  }

  &__rightList {
    width: 35vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    margin-right: 3vw;

    &__userBox {
      position: relative;
      display: flex;
      align-items: center;

      & > span {
        align-self: center;
        color: Color.$black;
      }
      .userLan {
        // display: flex;
        // align-items: center;
        // padding-right: 0.5vh;
        // padding: 0;
        // background-color: aqua;
        .btn {
          border: none;
          display: flex;
          align-items: center;
          span {
            display: flex;
            flex-direction: row;
          }
        }
      }
      .userGuide {
        display: flex;
        // margin-left: 1.5vh;
        padding-top: 1vh;
        border: none;
        cursor: pointer;
      }
      .alarm {
        position: relative;
        display: flex;
        margin-right: 1.8vh;
        margin-top: 0.4vh;
        border: none;
        cursor: pointer;
        animation: shake 1s ease-in-out infinite alternate;

        &__svg {
          margin-right: 1vh;
        }
        &__btn {
          position: absolute;
          top: 0;
          right: 5px;

          .badge {
            font-size: 0.6rem;
            color: Color.$white;
            background-color: orangered;
          }
        }
      }

      & > img {
        width: 1.5em;
        height: 1.5em;
        border-radius: 7.5em;
        border: 1px solid gray;
      }

      &__login {
        font-size: 0.7em;
        font-weight: bold;
        // border: none;
        // width: 6vw;

        & svg {
          width: 1.3em;
          height: 1.3em;
          margin-left: 1vw;
        }
        &:hover {
          background-color: Color.$background_1;
        }
        &:active {
          background-color: Color.$background_1;
        }
      }

      .header__search__logout {
        background-color: none;

        img:hover {
          cursor: pointer;
          box-shadow: 0.1vh 0.1vh 0.3vh 0.1vh #bbb;
        }
        &:active {
          background-color: none;
          border: none;
        }

        &.userDialog {
          img {
            box-shadow: 0.1vh 0.1vh 0.3vh 0.1vh #bbb;
          }
        }

        svg {
          color: Color.$background;
          width: 1.5em;
          height: 1.5em;
          &:hover {
            color: Color.$background_1;
            cursor: pointer;
            box-shadow: 0 0 4px 2px Color.$background_1;
            border-radius: 50%;
          }
          &:active {
            color: Color.$background_1;
          }
        }
      }
    }

    & > .btn-close {
      float: right;
    }
    & > .modal-header {
      font-size: 1.3em;
      color: Color.$black;
      font-weight: bold;
    }
    & > .modal-header > svg {
      opacity: 0.5;
    }

    & > .modal-title {
      text-align: center;
      margin: 0 auto;
    }
  }
}

.UserInfoModal {
  & > .modal-header {
    padding: 5%;
    margin: 10px;
  }
  & > .modal-dialog {
    margin: 35px;
    // width: 500px;
    top: 3vh;
    right: 1vw;
    position: absolute;
  }
  .modal-header {
    text-align: center;
  }
  &__sec {
    display: block;

    &__name {
      // font-size: 1.4rem;
    }
    &__Page {
      display: block;
    }

    &__Page > a {
      text-decoration: none;
      padding: 20px;
      color: Color.$white;
      border-inline-start: none;
      text-align: center;
      display: block;
      font-size: 1.2rem;
      font-weight: 600;
      border-radius: 20px;
      background-color: Color.$color;
      &:hover {
        color: Color.$white;
        background-color: Color.$color_1;
      }
    }
    &__PageBtn.btn.btn-primary {
      --bs-btn-border-color: none;
      float: right;
      justify-items: center;
      &:hover {
        background-color: Color.$black;
      }
    }

    &__out {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__logoutBtn.btn.btn-primary {
      padding: 1vh 1.5vh;
      background: Color.$background;
      --bs-btn-border-color: none;
      &:hover {
        background-color: Color.$background_1;
      }
    }
  }
}

.profile-container {
  position: absolute;
  top: 3.5rem;
  right: 0;
  width: 16rem;
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  border: 2px solid #e5e7eb;
  z-index: 40;
  padding: 1rem;
}

.profile-close-btn {
  position: absolute;
  right: 1rem;
  display: flex;
  justify-content: flex-end;

  .close-icon {
    font-size: 1.25rem;
    cursor: pointer;
    color: #4b5563;

    &:hover {
      color: #1f2937;
    }
  }
}

// Profile section
.profile-section {
  .profile-name {
    display: flex;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  .icon {
    color: #3b82f6;
    margin-right: 0.5rem;
  }
}

// Divider line
.divider {
  height: 1px;
  background-color: #d1d5db;
  margin-bottom: 1rem;
}

// Buttons
.button-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .btn {
    width: 100%;
    padding: 0.5rem 0;
    border-radius: 0.5rem;
    transition: background-color 0.2s;
    color: white;
    font-size: 0.875rem;

    &.mypage {
      background-color: #f1b647;
    }
  }
}

.modal-backdrop.UserInfo.show {
  --bs-backdrop-bg: none;
}
