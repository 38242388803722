@use '../Color';

.modclistdefault {
  padding-left: 40vw;
}

.clistside__item {
  padding-left: 2vw;
  align-items: center;
  display: flex;
  justify-content: space-between;
  & > span > .clistbtn-new:hover {
    color: green;
  }
  & > span > .clistbtn-del:hover {
    color: orange;
  }
  & > span > .clistbtn-edit:hover {
    color: blue;
  }
}

.modclistnew {
  display: flex;
  width: 100%;

  &__side {
    width: 32vw;

    &__titletype {
      font-size: 1.1rem;
      padding: 5px 10px;
      border-radius: 5px;
      background-color: #5dbb63;
      color: white;
    }

    &__currentcirlist {
      max-height: 38vh;
      overflow-y: auto;
      padding: 0 10px;

      &__item {
        border-bottom: 1px solid gray;
        padding: 3px 10px;
      }
    }
  }

  &__previewer {
    width: 57vw;
    display: flex;

    border: 1px solid navy;
    height: 75vh;
    padding: 0.5vh;
    & > .hysiap {
      overflow: hidden;
    }
  }
}

.modclistdel {
  display: flex;
  width: 100%;

  &__side {
    width: 32vw;
    padding-right: 1vh;

    &__titletype {
      font-size: 1.1rem;
      padding: 5px 10px;
      border-radius: 5px;
      background-color: #dc143c;
      color: white;
    }

    &__currentcirlist {
      max-height: 73vh;
      overflow-y: auto;
      padding: 0 10px;

      &__item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid gray;
        text-align: center;
        padding: 3px 10px;

        & > div:nth-child(1) {
          display: flex;
          max-width: 90%;
          height: auto;
          align-items: center;
          cursor: pointer;
        }
        & > div:nth-child(2) {
          font-size: 0.8rem;
        }
        & .btn:hover {
          color: #dc143c;
        }
      }
    }
  }

  &__previewer {
    width: 57vw;
    display: flex;

    border: 1px solid navy;
    height: 75vh;
    padding: 0.5vh;
    & > .hysiap {
      overflow: hidden;
    }
  }
}

.modclistedit {
  display: flex;
  width: 100%;

  &__side {
    width: 32vw;

    &__titletype {
      font-size: 1.1rem;
      padding: 5px 10px;
      border-radius: 5px;
      background-color: navy;
      color: white;
    }

    &__currentclists {
      max-height: 37vh;
      overflow-y: auto;
      padding: 0 10px;

      &__item {
        border-bottom: 1px solid gray;
        padding: 5px 10px;
      }
    }
  }

  &__previewer {
    width: 57vw;
    display: flex;

    border: 1px solid navy;
    height: 75vh;
    padding: 0.5vh;
    & > .hysiap {
      overflow: hidden;
    }
  }
}
