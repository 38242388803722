@use '../Color';

.MobileSearchBar {
  width: 100%;
  height: 100%;
  font-size: 0.8rem;
  color: #ccc;
  &__input {
    display: block;
    // width: 100%;
    height: 40px;
    font-size: 0.8rem;
    float: inline-end;
    align-self: center;
    justify-self: start end;
    border: 1px solid Color.$gray;
    border-radius: 5px;
    text-align: center;
    &:focus {
      // !important 안 주면 적용 안됨.
      box-shadow: none !important;
    }
  }
  .form-control:focus {
    border: 1px solid Color.$black;
  }

  & svg {
    width: 18px;
    height: 18px;
    border: none;
  }
  &__btn.btn.btn-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    height: 40px;
    color: Color.$white;
    --bs-btn-border-color: none;
    &:hover {
      // background-color: Color.$background_1;
    }
    &:active {
      // background-color: Color.$background_1;
    }
  }
}
