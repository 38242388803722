@use '../Color.scss';

.groupEdit {
  h4 {
    margin-bottom: 4vh;
  }
  & > svg {
    width: 2.5em;
    height: 2.5em;
    fill: Color.$background;
    &:hover {
      fill: Color.$pointColor_1;
    }
  }

  ul,
  li {
    list-style: none;
  }
  &__contents {
    width: 45vw;
    height: auto;
    margin: 0 auto;
    display: block;

    &__orderList {
      padding: 3%;
      box-shadow: 0.2vh 0.2vh 0.4vh 0.2vh Color.$gray;
      border-radius: 1vh;

      & ul {
        & h5 {
          margin-bottom: 2vh;
        }
        & li {
          padding-bottom: 2vh;
          & span {
            color: Color.$pointColor;
          }
        }
      }
      & p {
        font-size: 0.9rem;
        color: Color.$pointColor;
      }
    }
    &__uploadFile {
      &__form {
        display: flex;
        margin-top: 2vh;
        padding: 1vh;
        border-radius: 1vh;
        box-shadow: 0.2vh 0.2vh 0.4vh 0.2vh Color.$gray;

        & > span {
          width: 9vw;
          height: 3vh;
          display: flex;
          margin-top: 6vh;
          font-size: 1.2rem;
        }
        .editList {
          display: block;
          width: 40vw;

          .css-b62m3t-container {
            width: 15vw;
            margin: 0.5vh 0 1vh 0;
          }
          .css-13cymwt-control {
            width: 15vw;
          }

          &__editFileList {
            display: flex;
            flex-wrap: wrap;
            gap: 3%;
            margin-top: 1.5vh;

            & > div > span {
              margin-right: 1vh;
            }
          }

          &__editFileBtn {
            display: flex;
            justify-content: space-between;
            margin-top: 3vh;
            & span {
              font-size: 0.8rem;
              color: Color.$pointColor;
            }
            .btn {
              width: auto;
              height: 4vh;
            }
            & svg {
              margin-left: 0.5vw;
            }
          }
        }
      }

      &__inputBox {
        width: 100%;
        margin-top: 2vh;
        padding: 1vh;
        border-radius: 1vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-shadow: 0.2vh 0.2vh 0.4vh 0.2vh Color.$gray;

        .form-label {
          min-width: 10vw;
          height: 2vh;
          margin-top: 1.5vh;
          font-size: 1.2rem;
        }
        .form-control {
          margin-top: 10px;
          // width: 25vw;
          box-shadow: none;
        }
      }
    }
    &__saveBtn {
      width: 30%;
    }
  }
}
