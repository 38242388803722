@use '../Color';

.teamManual {
  margin-left: 15vw;
  width: 100%;

  h4 {
    margin-bottom: 2.5vh;
  }
  &__content {
    display: flex;
    flex-direction: column;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    &__search {
      width: 20vw;
    }
  }
  &__body {
    display: flex;
    gap: 2%;
    margin-top: 1vh;
    .teamManualBox {
      width: 100%;
      max-height: 80vh;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      &__table {
        thead {
          position: sticky;
          top: 0;
          text-align: center;
          background-color: Color.$background_sub;
        }
        tbody > tr {
          text-align: center;

          & > td:nth-child(2) {
            cursor: pointer;
            &:hover {
              color: Color.$pointColor_1;
            }
          }

          & > td:nth-child(3) {
            cursor: pointer;
            &:hover {
              color: Color.$pointColor_1;
            }
          }

          .btn {
            padding: 0;
            border: none;
            svg:hover {
              fill: Color.$pointColor_1;
            }
          }
          .show {
            svg {
              fill: Color.$pointColor_1;
            }
          }
        }
        .checkShow {
          background-color: #f1f1f1f1;
        }
        .manualHistory {
          min-width: 20vw;
          max-width: 30vw;
          height: 50vh;
          position: absolute;
          right: 8%;
          top: 23%;
          padding: 1vh;
          color: Color.$black;
          z-index: 999;
          border-radius: 1vh;
          background-color: Color.$white;
          box-shadow: 0.2vh 0.2vh 0.5vh 0.1vh #aaa;
          &__header {
            font-size: 1rem;
            height: 10%;
            padding-top: 2%;
            text-align: center;
            display: flex;
            justify-content: space-between;
            cursor: default;
            & > div {
              width: 95%;
            }
            & > svg {
              float: right;
              cursor: pointer;
              &:hover {
                fill: orangered;
              }
            }
          }
          &__body {
            height: 40vh;
            overflow-y: auto;
            border-top: 1px solid Color.$gray;
            border-bottom: 1px solid Color.$gray;
            &::-webkit-scrollbar {
              display: none;
            }
            & > div {
              padding: 1vh;
              width: 100%;
              height: 5vh;
              display: flex;
              align-items: center;
              justify-content: space-between;
              border-bottom: 1px solid #eee;
              & span:nth-child(1) {
                width: 73%;
                font-size: 0.9rem;
                text-align: left;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                cursor: default;
                svg {
                  margin-right: 0.5vw;
                }
              }
              & span:nth-child(2) {
                width: 22%;
                font-size: 0.7rem;
                cursor: default;
                margin-right: 0.5vw;
              }
              & span:nth-child(3) {
                width: 5%;
                .btn {
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  &__fakeheader > h4 > button {
    float: right;
    margin-left: 0.5vw;
  }

  &__one {
    display: flex;
    height: 78vh;
    &__left {
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &__top {
        height: 45%;
        width: 23vw;
        border-bottom: 1px solid #ddd;
        dl {
          padding: 1vh 0;
          display: flex;
          align-items: center;
          dt {
            width: 25%;
          }
          dd {
            margin-bottom: 0;
            width: 75%;
            max-height: 9vh;
            white-space: pre-line; // 줄바꿈 적용
            overflow-y: auto;
            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
      &__bottom {
        .title {
          font-size: 1.1rem;
          background-color: Color.$white;
        }
        & > div {
          width: 23vw;
          height: 38vh;
          overflow-y: auto;
          box-shadow: 2px 2px 5px 1px #ccc;
          padding: 0.5vh;
          &::-webkit-scrollbar {
            display: none;
          }
          .oldManualBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 0.9rem;
            border-bottom: 1px solid #ccc;
            & > dl {
              height: 13vh;
              margin: 0;
              margin-bottom: 0.5vh;
              display: flex;
            }

            & dl:nth-child(1) {
              width: 92%;
              display: flex;
              flex-direction: column;
              & > dt {
              }
              & > dd {
                padding: 0.5vh;
                height: 10.5vh;
                margin-bottom: 0;
                white-space: pre-line; // 줄바꿈 적용
                overflow-y: scroll;
                &::-webkit-scrollbar {
                  display: none;
                }
              }
            }
            & dl:nth-child(2) {
              width: 6%;
              align-items: center;
              .btn {
                padding: 0;
                border: none;
              }
            }
            .leaveBox {
              display: none;
            }
            .hoveredBox {
              position: absolute;
              bottom: 40vh;
              left: 25.5vw;
              width: 18vw;
              z-index: 9999;
              background-color: #fff;
              // border-radius: 1vh;
              box-shadow: 2px 2px 5px 1px #bbb;
              padding: 0.5vh;
              & > div:nth-child(1) {
                color: Color.$color_1;
              }
              & > div:nth-child(2) {
                padding: 0.5vh;
              }
            }
          }
        }
      }
    }
    &__right {
      position: relative;
      width: 70%;
      height: 78vh;

      &__spinner {
        position: absolute;
        top: 40vh;
        left: 25vw;
        margin: 0 auto;
        align-self: center;
      }
    }

    // &__oldManuals {
    //   height: 25%;
    // }
  }
}
