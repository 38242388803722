@use '../Color';

.mobilebreadcrumb {
  // line-height: 2vh;
  --bs-breadcrumb-divider: '>';

  .breadcrumb-item {
    font-size: 0.7rem;
    color: Color.$background;
    &:active {
      color: Color.$background;
    }
    &.active {
      // breadcrumb 링크 불가능 아이템 색상
      pointer-events: none;
      text-decoration: none;
      color: Color.$background;
    }
  }
  .breadcrumb-item > a {
    // breadcrumb 링크가능 아이템 색상
    color: gray;
    text-decoration: none;
    &:hover {
      font-weight: 700;
      color: Color.$pointColor_1;
    }
    &:focus {
      font-weight: 700;
      color: Color.$pointColor_1;
    }
    &:active {
      font-weight: 700;
      color: Color.$pointColor_1;
    }
  }
}
