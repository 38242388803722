@use '../../Color.scss';

.mobileNoticeList {
  width: 100%;
  height: 100%;

  &__header {
    padding: 0 1rem;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #000;
    &__count {
      padding: 0.5vh;
      font-size: 0.8rem;
    }
    .btn {
      padding: 0;
      font-size: 0.8rem;
    }
  }

  &__body {
    &__contents {
      display: flex;
      justify-content: start;
      align-items: center;
      width: 100%;
      height: 4.5rem;
      display: flex;
      padding: 0 1rem;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      ul,
      ul > li {
        list-style: none;
        padding: 0;
        margin: 0;
      }
      & > ul > li:nth-child(1) {
        font-size: 0.9rem;
        a {
          color: Color.$black;
          text-decoration: none;
        }
      }
      & > ul > li:nth-child(2) {
        padding: 0.5vh 0 0 0.2vh;
        font-size: 0.7rem;
      }
      & > div {
        display: flex;
        align-items: center;
        svg {
          fill: Color.$black;
        }
      }
    }
  }
}
