@use '../Color.scss';

.ruleinfo__rule {
  width: 100%;
  position: relative;

  .spinner__container {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
  }
  &__header {
    display: flex;
    justify-content: space-between;

    &__targetguide {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      border-radius: 0.5vh;
      border: 1px solid Color.$gray;
      box-shadow: 1px 1px 3px 1px #bbb;
      height: 4vh;
      padding: 2vh;

      &__textBox {
        min-width: 8vw;
        display: flex;
        justify-content: space-around;
        & span:nth-child(1) {
          margin-right: 2vh;
        }
        & span:nth-child(2) {
          margin-right: 1vh;
          color: Color.$pointColor_1;
        }
        & span:nth-child(3) {
          margin-right: 2vh;
        }
      }
      &__btnBox {
        .btn {
          padding: 0;
          border: none;
          &:hover {
            color: Color.$pointColor_1;
          }
          &:active {
            color: Color.$pointColor_1;
          }
        }
      }
    }
  }
}

.rule__header {
  border-top: 2px solid Color.$black;
  border-bottom: 2px solid Color.$black;
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0 10px;
  // margin-top: 10px;
  &__select {
    width: 10vw;
    padding: 10px;
  }

  &__btns {
    padding: 1vh 1vw;
    display: flex;
    gap: 0.9%;

    & .btns__item {
      font-size: 0.8vw;
      margin: 0.1em;
      padding: 3px 0;
      width: 6vw;
      // min-height: 16px;
      text-align: center;
      display: flex;
      gap: 5%;
      align-items: center;
      justify-content: center;
      border: none;

      svg {
        width: 16px;
        height: 16px;
        display: inline-block;
        display: flex;
        align-items: center;
        vertical-align: middle;
      }

      &:hover {
        background-color: Color.$background_1;
        border: none;
      }
    }
  }
}

.rule__body {
  display: flex;

  // 사이드네브 4vw / 토글네브 15vw / 규정본문사이드 20vw / 규정콘텐트 58vw
  &__sidenav {
    width: 20vw;
    & > .sidenav__title {
      padding: 0.5vh 0.5vw;

      span {
        font-size: large;
        font-weight: 700;
      }
    }
  }

  &__content {
    width: 58vw;
    padding: 0 var(--ck-spacing-standard);
    // border: 1px solid transparent;
    height: 79vh;
    overflow-y: auto;
    overflow-x: hidden;
    // 스크롤바 커스텀
    &::-webkit-scrollbar {
      width: 0.8vw;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(117, 117, 117);
      border-radius: 0.5vh;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: #dcdcdc;
      border-radius: 0.5vh;
      box-shadow: inset 0px 0px 5px white;
    }

    &__chapterBox {
      margin: 2vh 1vh 1vh 0;
      width: 100%;
      top: 15vh;
      height: 76vh;
      overflow-y: auto;
      z-index: 200;
      &::-webkit-scrollbar {
        display: none;
      }
      .table {
        text-align: center;
        background-color: #fff;
        & > thead {
          height: 4vh;
          // position: sticky;
          // top: 0;
          border: 1px solid #ccc;
          background-color: Color.$background_sub;
        }
        tr > td {
          border: 1px solid #ccc;
        }
      }
    }
  }

  // react-bootstrap 검색창 border 삭제
  .input-group:not(.has-validation)
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
    border: 0;
    background-color: #fff;
  }
  .input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border: 0;
  }

  #serchbar {
    width: 17vw;
  }
  .sidenav__searchbar {
    margin: 2vh 0 2vh 0;
    margin-left: 0.6vw;
    border: 0;
    border-bottom: 2px solid Color.$pointColor;

    & svg {
      color: Color.$pointColor;
    }
    &__form {
      width: 16vw;
      &:focus {
        // !important 안 주면 적용 안됨.
        box-shadow: none !important;
      }
    }

    & Button {
      border: 1px solid #ced4da;
    }
  }

  & .content__items {
    overflow-x: hidden;
  }
}

.sidenav__chapters {
  width: 100%;
  max-height: 65vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 0 0.5vw;

  &::-webkit-scrollbar {
    display: none;
  }
  &__chapterBox {
    overflow-y: auto;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
    &__chapterBtn {
      display: flex;
      align-items: center;
    }
    .sidenav__chapters__togglebtn {
      transition: all ease 0.3s;
      &.open {
        transform: rotate(180deg);
        color: Color.$color_1;
      }
    }
    .sidenav__chapters__subContent {
      font-size: 0.8rem;
      padding: 0 0 1.5vh 2vw;
      line-height: 3vh;
    }
  }

  & Button {
    display: block;
    overflow: hidden;
    width: 20vw;
    height: 4vh;
    background-color: Color.$white;
    font-size: 0.8rem;
    border: none;
    margin-bottom: 0.3vh;
    color: Color.$black;
    text-align: left;
    white-space: nowrap;
    cursor: pointer;
  }
}

.content__title {
  text-align: center;
  margin: 2vh 0 2vh 0;

  span {
    font-size: 1.7rem;
    font-weight: 700;
  }
}

.modal__revstatus {
  &__title {
    width: 100%;
  }

  &__body {
    width: 100%;
    align-items: center;
    // 스크롤바 커스텀
    &::-webkit-scrollbar {
      width: 0.8vw;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(117, 117, 117);
      border-radius: 0.5vh;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: #dcdcdc;
      border-radius: 0.5vh;
      box-shadow: inset 0px 0px 5px white;
    }
  }
  &__table {
    width: 100%;
    table-layout: fixed;
    // & pre {
    //   font-family: 'Pretendard', sans-serif;
    //   font-size: 0.9em;
    //   white-space: pre-wrap;
    //   word-break: break-all;
    //   overflow: auto;
    // }
    thead th {
      font-size: 1em;
      background-color: #f0f0f0;
      padding: 0.5vh;
    }

    & th,
    td {
      text-align: center;
      border: 1px solid #aaa;
      vertical-align: top;
      font-size: 0.9rem;
      // padding: 0;
    }
    & > tbody > tr > td:nth-child(3) > tr {
      width: 100%;
      display: flex;

      border-bottom: 1px solid #aaa;
      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
    .chaptd {
      text-align: left;
      padding-left: 1vh;
      width: 17.8%;
      border-top: none;
      border-left: none;
      border-bottom: none;
    }
    .sumtd {
      text-align: left;
      width: 82%;
      border: none;
      padding-left: 1vh;
    }
  }
}

#showModal {
  min-width: 1884px;
  height: 88vh;

  .showModal__title {
    position: fixed;
    min-width: 1882px;
    font-size: 1.5rem;
    z-index: 999;
  }

  .showModal__body {
    position: relative;
    top: 7vh;
    width: 100%;
    height: 88vh;
    overflow-y: auto;
    border-radius: 10px;
    line-height: 25px;
    background-color: Color.$white;
    // 스크롤바 커스텀
    &::-webkit-scrollbar {
      width: 0.8vw;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(117, 117, 117);
      border-radius: 0.5vh;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: #dcdcdc;
      border-radius: 0.5vh;
      box-shadow: inset 0px 0px 5px white;
    }
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
}

// @media 수정 예정
@media all and (max-width: 1590px) {
  .rule__header__select {
    width: 150px;
  }
  .btns__item > .hide__icon {
    display: none;
  }
}

@media all and (max-width: 1110px) {
  .rule__header {
    height: 7vh;
  }

  .rule__header__select {
    width: 150px;
  }

  .btns__item > .hide__name {
    display: none;
  }

  .btns__item > .hide__icon {
    display: contents;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: none;
  }
}

@media all and (max-width: 585px) {
  .rule__header {
    height: 7vh;
  }

  .rule__header__select {
    padding: 5px;
  }
}
