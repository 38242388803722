@use '../Color.scss';

.myProfile {
  width: 100%;
  height: 80vh;
  max-height: 80vh;
  border-top: 2px solid Color.$background;
  padding-top: 2vh;

  &__item {
    width: 100%;
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 4vh;
    display: flex;
    align-items: center;

    & > span {
      display: inline-block;
      margin-right: 10px;
    }

    & > svg {
      color: Color.$background;
      margin-right: 10px;
    }

    & > span:nth-child(2) {
      color: Color.$background;
      width: 200px;
    }
  }

  &__container {
    width: 49%;
    display: flex;
    padding: 4vh;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    & .img__container {
      width: 30%;
      // padding: 0 1vh;

      & .profileImg {
        margin-bottom: 2vh;

        img {
          width: 170px;
          height: 170px;
          border-radius: 10px;
          margin-bottom: 1vh;
        }

        &__isRegistered {
          width: 170px;
          height: 5vh;
          text-align: center;
          padding: 0 2vh;
          & > .title {
            color: Color.$background;
          }
        }
      }

      & .signatureImg {
        margin-bottom: 2vh;
        & img {
          border: 1px solid Color.$background;
          width: 170px;
          height: 170px;
          border-radius: 10px;
          margin-bottom: 1vh;
        }
        &__isRegistered {
          width: 170px;
          height: 5vh;
          text-align: center;
          padding: 0 2vh;
          & > .title {
            color: Color.$background;
          }
        }
      }
    }
  }
}
