@use '../Color.scss';

.sortchapter__modal {
  padding: 10px;
  position: absolute;
  top: 21vh;
  right: 13vw;
  width: 22vw;
  z-index: 9999;
  border: 1px solid #ccc;
  box-shadow: 3px 3px 8px 0px;
  border-radius: 10px;
  background-color: Color.$white;

  &__header {
    text-align: center;
    font-size: 1.3rem;
    padding-bottom: 1.2vh;
  }
  &__body {
    padding-top: 1vh;
  }
  &__nameinput {
    margin: 1vh 0;
  }
  &__bottom {
    float: right;
    margin-top: 3vh;
    .btn {
      border: none;
    }
  }
}
