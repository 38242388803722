@use '../../Color.scss';

.mobileNotice {
  &__btnList {
    padding: 0.8vh;
    position: sticky;
    top: 7vh;
    background-color: #fff;
    height: 6vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid #000;

    .btn {
      // padding: 0;
      border: none;
    }
    &__btns {
      width: 20%;
      display: flex;
      justify-content: space-around;
      .btn {
        padding: 0;
        font-size: 0.8rem;
        border: none;
      }
    }
  }
}
.mainContent {
  &__header {
    & > td > p {
      font-size: 1.1rem;
    }
    & > td > div {
      display: flex;
      font-size: 0.8rem;
      justify-content: space-between;
    }
  }

  &__fileBox > td {
    display: flex;
    font-size: 0.8rem;
    gap: 1%;
    & > span {
      min-width: 23%;
      max-width: 40%;
    }
  }

  &__body {
    height: auto;
  }
}

.mainContent__fileBox__fileLabel__span {
  margin-right: 5px;
}
