@use '../Color';

.manualNew {
  width: 100%;
  &__header {
    height: 16vh;
    .btn {
      margin-top: 2vh;
      padding: 0;
    }

    & svg {
      width: 2.5em;
      height: 2.5em;
      fill: Color.$background;

      &:hover {
        fill: Color.$pointColor_1;
      }
    }
    &__spinner {
      width: 80%;
      height: 5vh;
      text-align: center;
      display: flex;
      justify-content: center;
      span {
        width: 3rem;
        height: 3rem;
        color: Color.$pointColor_1;
      }
      .spinnerText {
        margin-left: 1vw;
        width: 8vw;
        display: flex;
        align-items: center;
      }
    }
  }
  &__main {
    height: 65vh;

    &__inputBox {
      width: 100%;
      display: flex;
      flex-direction: column;
      & > div {
        width: 100%;
        display: flex;
        gap: 5%;
        dl {
          width: 40%;
          display: flex;
          align-items: center;
          dt {
            width: 8vw;
          }
          dd {
            padding-top: 1vh;
          }
        }
      }
      &__topContents {
        & > dl:nth-child(1) {
          input {
            border: none;
            color: Color.$background;
            background-color: Color.$white;
          }
        }
      }
      &__middleContents {
        width: 100%;
        display: flex;
        margin: 3vh 0;
        &__form {
          display: flex;
          align-items: center;
          .form-label {
            width: 8vw;
            padding-top: 0.8vh;
          }
          .form-control {
            width: 25vw;
          }
        }
      }
    }
    input {
      width: 25vw;
      &:focus {
        box-shadow: none;
        border: 1px solid Color.$black;
      }
    }

    &__additional {
      width: 100%;
      display: flex;
      gap: 5%;
      margin-top: 4vh;
      dl {
        width: 40%;
        display: flex;
        dt {
          width: 8vw;
        }
        dd {
          height: 20vh;
          textarea {
            width: 25vw;
            height: 20vh;
            resize: none;
            border: 1px solid #ccc;
            border-radius: 0.6vh;
            padding: 0.5vh;
            &:focus {
              outline: none;
              border: 1px solid Color.$black;
            }
          }
        }
      }
    }
  }
  &__bottom {
    display: flex;
    .btn {
      margin-right: 1vh;
    }
  }
}
