@use '../Color.scss';

.ruleinfo__formlist {
  &__header {
    height: 4vh;
    position: relative;
  }
  &__tablecontainer {
    max-height: 86vh;
    overflow-y: auto;
    table {
      border-collapse: separate;
      border-spacing: 0;
    }
    thead {
      position: -webkit-sticky;
      position: sticky;

      top: 0;
      background-color: Color.$background_sub;

      & tr th {
        z-index: 2;
      }
    }
  }
}

.table.formlist__table {
  width: 100%;
}
