@use '../Color.scss';

.noticeEdit {
  margin-top: 5vh;
  height: 82vh;
  flex-direction: column;

  & dl {
    width: 50vw;
    height: 5vh;
    display: flex;
  }

  &_header {
    width: 100%;
    height: auto;

    & > svg {
      fill: Color.$background;
    }
  }

  &__main {
    width: 100%;
    height: 73vh;
    flex-direction: column;
    display: flex;
    margin: 0 auto;
    border-top: 1px solid Color.$background_1;
    border-bottom: 1px solid Color.$background_1;

    &__title {
      display: flex;
      height: 5vh;
      align-items: center;

      & > div:nth-child(1) {
        flex-shrink: 0;
        width: 5vw;
        font-size: 1.2rem;
        display: flex;
        justify-content: space-between;
      }

      & > div:nth-child(2) {
        flex-grow: 1;
        padding-left: 1vw;
        & input {
          width: 40vw;
          font-size: 1rem;
          border: none;
          &:focus {
            outline: none;
          }
        }
      }
    }

    &__sub {
      display: flex;
      padding: 1vh 0;
      border-top: 1px solid Color.$gray;
      align-items: center;
      & > div:nth-child(1) {
        flex-shrink: 0;
        width: 5vw;
        font-size: 1.2rem;
        display: flex;
        justify-content: space-between;
      }

      & > div:nth-child(2) {
        flex-shrink: 0;
        padding-left: 1vw;
        // width: 5vw;
      }
      & > div:nth-child(3) {
        display: flex;
        padding-left: 1vw;
        flex-grow: 1;
        flex-wrap: wrap; /* 뱃지들이 컨테이너 너비를 초과하면 다음 줄로 넘김 */
        gap: 10px;

        & > .filebadge {
          background-color: Color.$gray;
          padding: 3px 5px;
          border-radius: 3px;
        }
        & > .filebadge span:nth-child(2):hover svg {
          cursor: pointer;
          fill: red;
        }
      }

      & button {
        padding-top: 3px;
        padding-bottom: 3px;
        display: flex;
        border: none;

        &:hover {
          background-color: Color.$background_1;
          color: Color.$pointColor_1;
        }
      }

      .form-control {
        width: 30vw;
        height: 4vh;
        margin-top: 0.5%;
        &:focus {
          box-shadow: none;
        }
      }
      &__form {
        display: flex;

        & > label {
          max-width: 15vw;
          font-size: 18px;
          font-weight: 600;
          margin-top: 1%;
          margin-right: 2%;
        }
      }
    }

    &__body {
      width: 100%;
      flex-grow: 1;
      border-top: 1px solid Color.$background_1;
      display: flex;
      max-height: 61vh;
      overflow-y: auto;

      & textarea {
        width: 100%;
        height: 56vh;
        padding: 1vh;
        resize: vertical;
        border: none;
        &:focus {
          outline: none;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    margin: 0.5%;
  }

  & svg {
    &:hover {
      fill: Color.$pointColor_1;
    }
  }

  & button {
    border: none;

    &:hover {
      background-color: Color.$background_1;
    }
  }
}
