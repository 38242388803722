@use '../Color.scss';

.noticeUpdate {
  flex-direction: column;
  margin-top: 5vh;
  height: 82vh;

  & dl {
    width: 50vw;
    height: 5vh;
    display: flex;
  }
  & dt {
    font-size: 1.2rem;
    margin-right: 1vw;
    align-items: center;
  }

  &_header {
    width: 100%;
    height: auto;

    & svg {
      width: 2.5em;
      height: 2.5em;
      fill: Color.$background;

      &:hover {
        fill: Color.$pointColor_1;
      }
    }
  }

  &__main {
    width: 100%;
    min-height: 73vh;
    display: block;
    margin: 0 auto;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;

    &__title {
      height: 5vh;

      & dl {
        display: flex;
        align-items: center;
      }

      & input {
        width: 40vw;
        height: 4vh;
        margin-top: 1%;
        font-size: 1rem;
        border: none;
        &:focus {
          outline: none;
        }
      }
    }

    &__sub {
      min-height: auto;
      display: block;
      border-top: 1px solid Color.$gray;
      padding-top: 1.5vh;

      &__basic {
        display: flex;
        padding-left: 1vw;
        flex-grow: 1;
        flex-wrap: wrap; /* 뱃지들이 컨테이너 너비를 초과하면 다음 줄로 넘김 */
        gap: 10px;
        background-color: lighten(#f4f6fb, 1%);

        & > .filebadge {
          font-size: 0.8rem;
          border-radius: 3px;
          border: 1px solid gray;
        }

        & > .filebadge span {
          padding: 2px 5px;
        }

        & > .filebadge span:nth-child(2) {
          border-left: 1px solid gray;
          border-right: 1px solid gray;
        }

        & > .filebadge span:nth-child(3):hover svg {
          cursor: pointer;
          fill: red;
        }
      }

      &__newFile {
        width: 50vw;
        height: auto;
        padding: 0.5vh 0;

        .form-control {
          width: 30vw;
          height: 4vh;
          margin-top: 0.5%;
          &:focus {
            box-shadow: none;
          }
        }
        &__form {
          display: flex;

          & Label {
            max-width: 15vw;
            font-size: 18px;
            font-weight: 600;
            margin-top: 1%;
            margin-right: 2%;
          }
        }
      }
    }

    &__body {
      width: 100%;
      min-height: 45vh;
      border-top: 2px solid #000;

      & textarea {
        width: 100%;
        // min-height: 45vh;
        padding: 1vh;
        margin-top: 0.5vh;
        resize: none;
        border: none;
        &:focus {
          outline: none;
        }
      }
    }
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;
    margin: 0.5%;
  }

  & button {
    margin-left: 1%;
    border: none;

    &:hover {
      background-color: Color.$background_1;
    }
  }
}
