@use 'Color';

footer {
  width: 100%;
  height: 5vh; /* footer의 높이 */
  background-color: darken(#f4f6fb, 5%);
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-items: center;
  justify-content: center;

  .btn {
    font-size: 1rem;
    // line-height: 5vh;
    // color: Color.$pointColor;
    border: none;

    &:hover {
      color: Color.$pointColor_1;
    }
    &:active {
      color: Color.$pointColor_1;
    }
  }

  span {
    font-size: 1rem;
    line-height: 5vh;
    padding: 0 0.6vw;
  }

  .footer__split {
    padding: 0 1vw;
  }
}
