@use '../Color.scss';

.reference {
  &__list {
    text-align: center;
    margin-top: 2.5vh;

    & thead {
      height: 40px;
      // position: sticky;
      background-color: Color.$background_sub;
      font-weight: bold;
    }

    & tbody {
      tr.reference__linkitem {
        cursor: pointer;
        &:hover {
          color: Color.$background;
          background-color: lighten(Color.$gray, 17%);
        }
      }
      //
    }
  }
}
