@use '../Color.scss';

.positionpage {
  height: 78vh;
  margin: auto;

  &__wrapper {
    margin: 0 auto;
    width: 80%;
  }

  &__spinner {
    text-align: center;
  }
  &__topTitle {
    text-align: center;
    padding-top: 2vh;
    font-size: 1.3rem;
  }
  &__header {
    margin: 0.6vh 0;
    display: flex;
    justify-content: space-between;
    height: 5vh;

    &__input {
      display: flex;
      width: 50%;
      .input-group-text {
        background-color: Color.$background_sub;
      }
      .form-control {
        &:focus {
          box-shadow: none;
          border: 1px solid #ccc;
        }
      }
      & > div:first-child {
        display: flex;
        width: 70%;
        align-items: center;
      }
      & > div:nth-child(2) {
        display: flex;
        width: 20%;
        padding-left: 0.5vw;
        & > button {
          align-self: center;
        }
      }
    }

    &__title {
      margin: 0 auto;
      width: 10vw;
      height: 4.5vh;
      display: flex;
      align-items: center;
      border-radius: 1vh;
      background-color: #f3f3f3;
      & > span {
        margin: 0 auto;

        color: Color.$pointColor_1;
      }
    }

    &__btns {
      display: flex;

      & > button {
        align-self: center;
      }
    }
  }

  &__body {
    max-height: 72vh;
    overflow-y: auto;
    width: 100%;

    & > table > thead {
      background-color: Color.$background_sub;
      position: sticky;
      top: 0px;
    }

    &__table > thead > tr th {
      text-align: center;
    }

    &__table > tbody > tr td {
      padding: 1vh;
    }

    &__table > tbody > tr td:nth-child(3) > span {
      margin: 0 0.5vw;
      & > svg {
        color: Color.$background;
        cursor: pointer;
      }

      & > svg:hover {
        color: Color.$color_1;
      }
    }

    &__table > tbody > tr td:nth-child(4) > span {
      & > svg {
        cursor: pointer;
      }

      & > svg:hover {
        color: red;
      }
    }

    &__item {
      width: 100%;

      .changeBtn {
        height: 5vh;
        cursor: pointer;

        & > input {
          height: 3vh;
          font-size: 1rem;
        }
        & > .btn {
          padding: 0 1vh;
          border: none;
          color: Color.$black;
        }
      }

      .editBtn {
        // display: flex;
        // align-items: center;
        span {
          height: 5vh;
          padding-right: 1vw;
        }

        & > .btn {
          padding: 0;
          border: none;
          color: Color.$color_1;
          font-size: small;
          cursor: pointer;
          &:hover,
          &:active,
          &:focus {
            color: Color.$color_1;
          }
        }
      }
    }

    &__item > span {
      text-align: center;
      display: inline-block;
      line-height: 5vh;
    }
  }

  &__vessel {
    width: 50%;
  }
}
