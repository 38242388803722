@use 'Color';

.SearchBar {
  height: 100%;

  &__input {
    display: block;
    // max-width: 40%;
    max-width: 18vw;
    height: 30px;
    font-size: 0.8em;
    float: inline-end;
    align-self: center;
    justify-self: start end;
    border: 1px solid Color.$gray;
    border-radius: 5px;
    text-align: center;
    &:focus {
      // !important 안 주면 적용 안됨.
      box-shadow: none !important;
      border: 1px solid Color.$black;
    }
  }

  & svg {
    margin-bottom: 11px;
    width: 18px;
    height: 18px;
    border: none;
  }
  &__btn.btn.btn-primary {
    align-self: center;
    height: 30px;
    color: Color.$white;
    --bs-btn-border-color: none;
    &:hover {
      background-color: Color.$background_1;
    }
    &:active {
      background-color: Color.$background_1;
    }
  }
}
