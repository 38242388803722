@use '../Color.scss';
.devdraft {
  width: 100%;
  display: flex;
  height: 79vh;
  // overflow-y: auto;

  &__side {
    width: 35vw;
    padding-right: 1.5vw;
    display: block;

    & > div {
      margin: 1vh 0;
    }

    &__lanradiobtn {
      width: 100%;
    }

    &__btnwrap {
      display: flex;
      justify-content: flex-end;
    }

    &__changebtn {
      width: 100%;
    }

    &__chapterinfos {
      padding-top: 3vh;
      display: flex;
      gap: 1vw;
    }

    &__oldchapters {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 1vh;
      font-size: 1rem;
      font-weight: 700;
      height: 46vh;
      overflow: auto;
    }

    &__chaptercontents {
      width: 50%;
    }
  }

  &__main {
    width: 58vw;
    display: block;

    &__editor {
      min-height: 67vh;
      max-height: 67vh;
      // overflow: auto;
    }

    & .ck-editor__editable {
      min-height: 57vh;
      max-height: 57vh;
    }

    & .ck.ck-editor__editable_inline {
      border: 1px solid var(--ck-color-base-border);
    }

    & > div {
      margin: 1vh 0;
    }

    &__header {
      display: flex;
      gap: 0.5vw;
      & label:nth-child(1) {
        width: 17%;
      }

      & label:nth-child(2) {
        width: 17%;
      }

      & label:nth-child(3) {
        width: 50%;
      }

      & label:nth-child(4) {
        width: 16%;
      }
    }

    &__setheader {
      display: flex;
      gap: 1vw;
      & label:nth-child(1) {
        width: 20%;
      }

      & label:nth-child(2) {
        width: 50%;
      }

      & label:nth-child(3) {
        width: 30%;
      }
    }

    &__footer {
      float: right;
    }
  }
}
