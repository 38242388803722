@use '../Color';

.ruleInfo {
  .lanTabs {
    height: 5vh;
    display: flex;
    position: relative;
    user-select: none;
  }
  .lanTabs > span {
    width: 50%;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.5vh;
    cursor: pointer;
    margin: 0;
    font-size: 1rem;
  }

  .lanTabs > span:after {
    display: block;
    border-bottom: solid 3px Color.$white;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  .lanTabs {
    & .open {
      opacity: 1;
      color: Color.$color_1;
    }
  }

  .lanTab {
    z-index: 2;
    font-weight: 700;
    font-size: 1rem;
    color: #808080;
  }

  .lanTab:hover {
    color: Color.$color_1;
  }

  .lanTabs > span:hover:after {
    opacity: 1;
  }

  .lanTab__glide {
    box-shadow: 0 5px 0px -1px Color.$color_1;
    opacity: 1;
    position: absolute;
    transition: all 200ms linear;
    box-sizing: border-box;
  }
}
.listBox {
  width: 100%;
  max-height: 89vh;
  margin: 0;
  padding: 0.6vh 0;
  text-decoration: none;
  list-style: none;
  overflow-y: scroll;
  // text-overflow: ellipsis;
  display: grid;
  align-items: center;
  & .accordion-body {
    padding: 0;
  }
  &::-webkit-scrollbar {
    display: none;
  }

  // toggle 메뉴
  .togglecollapse {
    width: 100%;
    &__header {
      width: 95%;
      margin: 0 auto;
      display: flex;
      gap: 1vh;
      justify-content: space-between;

      & > span {
        height: 3vh;
        display: flex;
        align-items: center;
        margin-bottom: 0.8vh;
        &:nth-child(1) {
          font-size: 0.9rem;
          width: 90vw;
          overflow: hidden;
          white-space: nowrap;
          // text-overflow: ellipsis;
          cursor: pointer;
          .btn {
            padding: 0;
            border: none;
          }
        }
        &:nth-child(2) {
          // width: 5vw;
          .toggleBtn {
            margin: 0 auto;
            transition: all ease 0.3s;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
              color: Color.$color_1;
            }

            &.open {
              transform: rotate(180deg);
              color: Color.$color_1;
            }
          }
        }
      }
    }

    &__content {
      height: 0px;
      overflow: hidden;
      transition: all ease 0.1s;

      &.open {
        height: auto;
        display: flex;
        flex-direction: column;
        margin: 0 0 2vh 5vw;
      }

      &.open > a {
        display: block;
        max-width: 95%;
        padding: 0.7vh 0 0.7vh 2vw;
        overflow-x: hidden;
      }

      &__sub {
        text-align: left;
        font-size: 0.75rem;
        white-space: nowrap;
        // text-overflow: ellipsis;가 먹힐려면 inline-block 설정
        // display: inline-block;
        // overflow: hidden;
        // text-overflow: ellipsis;

        &:hover {
          color: Color.$color_1;
          cursor: pointer;
        }
        &.open {
          color: Color.$color_1;
        }
      }
    }
  }
}
