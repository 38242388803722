@use '../Color';

.modrule {
  flex-direction: column;
  align-items: center;

  &__header {
    display: flex;
    padding: 1vh 0;

    &__title {
      width: 25%;
      padding: 0 1.5vw;
      font-size: 1.5rem;
    }

    &__settingbtn {
      width: 75%;
      padding: 0 1.5vw;
      text-align: right;

      & .btns__item {
        width: 5vw;
        height: 4vh;
        justify-content: center;
        border: none;

        &:hover {
          color: Color.$color_1;
          background-color: Color.$background;
        }
      }
    }

    &__toggle {
      width: 10vw;
      height: 4vh;
      border-radius: 1vh;
      background-color: Color.$white;
      padding: 0.4vh;
      border: 0.1vh solid Color.$background;
      & > div {
        position: relative;
        display: inline-flex;
        width: 100%;
        height: 3vh;
      }

      & > div > a {
        text-align: center;
        width: 50%;
        border-radius: 1vh;
      }

      & > div > a > label {
        cursor: pointer;
        text-align: center;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        height: 3vh;
        border-radius: 1vh;
        color: white;
        &.black-font {
          color: Color.$background;
        }
      }

      & > div > .switch {
        position: absolute;
        height: 3vh;
        width: 50%;
        background-color: Color.$background;
        border-radius: 1vh;
        transition: transform 0.3s ease;
      }

      & > div > .switch.createform {
        transition: transform 0.3s ease;
        transform: translateX(100%);
      }

      & > div > .switch.editform {
        transition: transform 0.3s ease;
        transform: translateX(100%);
      }
    }

    &__btns {
      display: flex;
      gap: 5px;

      & .btns__item {
        width: 4vw;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        border: none;

        &:hover {
          color: Color.$color_1;
          background-color: Color.$background;
        }
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    width: 80vw;
    margin: 10px auto;
    flex-direction: row;

    & Button {
      align-items: center;
      margin: 15px;
      min-width: 120px;
      height: 40px;
      background: none;
      border-radius: 40px 40px;
      &:hover {
        border: none;
        font-weight: bold;
      }
      &:active {
        border: none;
        font-weight: bold;
      }
    }
  }
}
