@use '../../Color.scss';

.mobileUserLog {
  font-size: 0.8rem;

  &__fixedHeader {
    // position: sticky;
    // top: 7vh;
    padding: 1vh;
    z-index: 200;
    background-color: Color.$white;
  }

  &__header {
    padding-top: 1vh;
    display: flex;

    & a {
      height: 4vh;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1vw;
      font-size: 0.8rem;
      background-color: Color.$background_sub;
      color: Color.$black;
      border: none;
      border-top-right-radius: 1vh;
      border-top-left-radius: 1vh;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        background-color: Color.$background;
      }
      &:active {
        background-color: Color.$background;
      }

      & a.on {
        border-bottom: none;
      }
    }

    .btn-check:checked + .btn,
    :not(.btn-check) + .btn:active,
    .btn:first-child:active,
    .btn.active,
    .btn.show {
      background-color: Color.$background;
      color: Color.$color;
      border: none;
    }
  }
  &__subHeader {
    border-top: solid 2px Color.$background;

    .dateSearch {
      // height: 15vh;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      margin: 1vh 0;
      border-radius: 1vh;
      padding: 1vh;
      box-shadow: 1px 1px 4px 1px #bbb;

      & Button {
        background-color: Color.$white;
        border-color: Color.$black;
        color: Color.$black;
        cursor: pointer;
        margin-right: 0.5vw;
      }

      .btn-check:checked + .btn,
      :not(.btn-check) + .btn:active,
      .btn:first-child:active,
      .btn.active,
      .btn.show {
        color: Color.$white;
        background-color: Color.$background;
      }
      .btn {
        font-size: 0.7rem;
      }
      .periodbtns {
        display: flex;
        justify-content: space-around;
        margin-bottom: 0.5rem;
      }

      .dateSelect {
        display: flex;
        justify-content: space-around;
        align-items: center;

        & span {
          margin-right: 0.5vw;
        }

        & input {
          margin-right: 0.5vw;
          border: 1px solid Color.$background;
          border-radius: 0.5vh;
        }

        &__dateBtn {
          border-color: Color.$black;
          color: Color.$black;
          margin-right: 0.5vw;
          padding: 0.3vh 1.5vh;
        }
        .btn:nth-last-child(1) {
          border-color: Color.$black;
          margin-right: 0.5vw;
          padding: 0.3vh 1.5vh;
        }
      }
    }
  }

  .mobileUserLogBody {
    &__subHeader {
      // position: sticky;
      // top: 27vh;
      z-index: 200;
      background-color: Color.$white;
    }

    .mobilelogSearch {
      width: 100%;
      margin-bottom: 0.5vh;
      display: flex;
      gap: 1.5%;

      #dropdown-basic {
        font-size: 0.8rem;
        margin-right: 1vw;
        line-height: 5vh;
        border: none;
      }
      #dropdown-item:active {
        color: Color.$black;
        background-color: Color.$gray;
      }
      &__searchBox {
        width: 68%;
      }

      & .reset__btn {
        padding: 0.8vh 1.5vh;
        font-size: 0.8rem;
        align-self: center;
      }
    }

    &__table {
      padding: 1vh;
      max-height: 65vh;
      overflow-y: auto;
      overflow-x: hidden;
      &__ruleContents {
        padding: 0.5vh;
        height: 8vh;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #bbb;
        & > div:nth-child(1) {
          width: 88%;
          display: flex;
          flex-direction: column;
          & > div {
            gap: 5%;
            display: flex;
          }
        }
        & > div:nth-child(2) {
          width: 11%;
          text-align: center;
          font-size: 0.7rem;
        }
      }
      &__loginContents {
        padding: 0.5vh;
        display: flex;
        flex-direction: column;
        border-top: 1px solid #bbb;
        & > div:nth-child(1) {
          height: 4vh;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        & > div:nth-child(2) {
          height: 4vh;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
