@use '../Color.scss';
.draftpage {
  display: flex;

  &__catelist {
    position: relative;
    width: 17vw;
    background-color: Color.$background_1;
    text-decoration: none;
    min-height: 87vh;
    text-align: center;
    color: Color.$white;

    & > li {
      text-decoration: none;
    }

    &__collapse {
      height: 82vh;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    .collapseitem {
      font-size: 0.9rem;
      margin-right: 0;
      &__draftinfo {
        padding-left: 1vw;
        text-align: left;
        font-size: 16px;
        margin-bottom: 1vh;
      }

      &__header {
        display: flex;
        align-items: center;
        margin-top: 1.5vh;
        white-space: nowrap;
        overflow: hidden;
        svg {
          margin: 0 0.5vh;
          transition: all ease 0.3s;
        }
        .open {
          transition: all ease 0.3s;
          transform: rotate(90deg);
          color: Color.$color_1;
        }

        & > div > span {
          line-height: 4vh;
        }
      }

      &__rulebox {
        height: 0px;
        overflow: hidden;
        transition: height 0.3s ease-out;

        &.open {
          transition: height 0.3s ease-out;
          height: auto;
          display: flex;
          flex-direction: column;
        }
        &__sub {
          margin-left: 1.5vw;
          text-align: left;
          display: flex;
          justify-content: space-between;
          & > span {
            align-self: center;
            white-space: nowrap;
            overflow: hidden;
          }

          .btn {
            border: none;
            color: #fff;
            // padding: 0.5vh;
          }
          .btn:hover {
            border: none;
            color: orange;
            // padding: 0.5vh;
          }
        }
        &__sub__title {
          white-space: nowrap;
          overflow: hidden;
        }
      }
      &__fileList {
        text-align: left;
        padding: 2vh 1vw;
        max-height: 72vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }

        &__title {
          font-size: 16px;
        }

        & > div {
          display: flex;
          justify-content: space-between;
          margin-bottom: 1vh;
          & > button:nth-child(1) {
            width: 75%;
            text-align: left;
            padding: 0.2vw;
            border: none;
            cursor: pointer;
            background-color: transparent;
          }
          & span {
            // width: 25%;
            padding: 0.1vw;
            // color: green;
            border: none;
            background-color: transparent;
          }
        }
      }
    }
  }

  &__catelist__tabs {
    height: 4vh;
    display: flex;
    position: relative;
    user-select: none;
  }

  &__catelist__tabs > span {
    width: 50%;
    border-radius: 2px;
    align-items: center;
    text-align: center;
    line-height: 3.8vh;
    height: 3.5vh;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    font-size: 1rem;
  }

  &__catelist__tabs > span:after {
    display: block;
    border-bottom: solid 3px Color.$white;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  &__catelist__tabs {
    & .open {
      opacity: 1;
      color: Color.$color_1;
    }
  }

  &__catelist__tab {
    z-index: 2;
    font-weight: 700;
    font-size: 1rem;
    color: #808080;
  }

  &__catelist__tab:hover {
    color: Color.$color_1;
  }

  &__catelist__tabs > span:hover:after {
    opacity: 1;
  }

  &__catelist__tab__glide {
    box-shadow: 0 5px 0px -1px Color.$color_1;
    opacity: 1;
    position: absolute;
    transition: all 200ms linear;
    box-sizing: border-box;
  }

  &__catelist__tab__glideright {
    box-shadow: 0 5px 0px -1px Color.$color_1;
    opacity: 1;
    position: absolute;
    transform: translateX(100%);
    box-sizing: border-box;
  }

  &__addrule {
    z-index: 999;
    position: absolute;
    left: 100%;
    top: 5vh;
    width: 22vw;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 3px 3px 8px 0px;
    border-radius: 10px;
    background-color: Color.$white;
    color: black;

    &__header {
      text-align: center;
      font-size: 1.3rem;
      padding-bottom: 1.2vh;
    }
    &__body {
      padding-top: 1vh;
    }
    &__bottom {
      float: right;
      margin-top: 3vh;
    }
  }
}
