@use '../Color';

.ManagerAuthList {
  width: 100%;
  max-height: 93vh;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
  display: grid;
  align-items: center;

  & .accordion-body {
    padding: 0;
  }

  &__lanradiobtn {
    width: 100%;
    height: 5vh;
    & label {
      padding: 10px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid Color.$gray;
      background-color: Color.$background_sub;
    }
  }

  &__rulelistTitle {
    text-align: center;
    width: 100%;
    height: 6.5vh;
    border-bottom: 1px solid Color.$gray;
    background-color: Color.$background_sub;

    & span {
      line-height: 6vh;
    }
  }
}

.Authcollapse {
  max-height: 68vh;
  overflow-y: auto;
  text-overflow: ellipsis;
  &::-webkit-scrollbar {
    display: none;
  }
  .spinner-border {
    width: 3rem;
    height: 3rem;
    position: absolute;
    top: 30vh;
    left: 9vw;
  }
  &__item {
    height: 5vh;
    display: flex;
    justify-content: space-between;
    padding: 0 5%;
    align-items: center;
    text-align: center;

    &:hover {
      color: lighten(Color.$pointColor, 15%);
      cursor: pointer;
    }

    &.select {
      color: Color.$pointColor_1;
      border: 2px solid Color.$pointColor_1;
    }
  }

  &__title {
    font-size: 16px;
    max-width: 400px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    text-overflow: ellipsis;
  }

  .btn {
    border: none;
    padding: 0;
  }
  & svg {
    width: 22px;
    height: 22px;
    border: none;
  }

  &__content {
    width: 100%;
    height: 0;
    overflow: hidden;
    transition: all ease 0.1s;

    &.open {
      height: auto;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    &__sub {
      padding: 5px 0 10px 30px;
      font-size: 14px;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      &.select {
      }
    }
  }
}
