@use '../Color.scss';

.chart {
  &__tabmenu {
    margin-top: 2.5vh;
    display: flex;
    border-bottom: solid 2px Color.$background;

    & a {
      font-size: 1rem;
      width: 12vw;
      height: 4vh;
      margin-right: 0.3vw;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: Color.$background_sub;
      color: Color.$black;
      border: none;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        background-color: Color.$background;
      }
      &:active {
        background-color: Color.$background;
      }
    }

    .btn-check:checked + .btn,
    :not(.btn-check) + .btn:active,
    .btn:first-child:active,
    .btn.active,
    .btn.show {
      background-color: Color.$background;
      color: Color.$color;
      border: none;
    }
  }
}
