@use '../../Color';

.mobileRevstatus {
  &__main {
    width: 100%;
    padding: 1vh;
    // display: flex;
  }
  &__listBox {
    // font-size: 0.9rem;
    width: 100%;
    display: flex;
    flex-direction: column;

    // DCN 상단
    .listBox {
      &__header {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 15px;
        margin-bottom: 25px;
        height: auto;

        &__lanBtn {
          width: auto;
          display: flex;
          gap: 2%;
          margin-bottom: 1vh;
        }
        &__searchBox {
          width: 95%;
        }
      }

      &__table {
        max-height: 75vh;
        text-align: center;
        overflow-y: auto;
        &::-webkit-scrollbar {
          display: none;
        }

        & .tableBodyBox {
          width: 100%;
          height: 70vh;
          overflow-y: auto;
        }

        & thead {
          background-color: Color.$background_sub;
          position: sticky;
          top: 0;
          font-weight: bold;
        }
        & tbody > tr {
          cursor: pointer;
          &:hover,
          &:active,
          &:focus {
            background-color: #f7f7f7;
          }
        }
      }
    }
  }

  // &__popupBox {
  //   position: absolute;
  //   font-size: 0.7rem;
  //   top: 30vh;
  //   margin: 1vh;
  //   padding: 0.3vh;
  //   box-shadow: 1px 1px 4px 3px #bbb;
  //   border-radius: 1vh;
  //   background-color: Color.$white;
  //   .popupHeader {
  //     padding: 0.7vh;
  //     display: flex;
  //     margin-bottom: 1.5vh;
  //     height: 3vh;
  //     justify-content: space-between;
  //   }

  //   .table {
  //     max-height: 40vh;
  //     overflow-y: auto;
  //     &::-webkit-scrollbar {
  //       display: none;
  //     }
  //     & > thead {
  //       background-color: Color.$background_sub;
  //       position: sticky;
  //       top: 0;

  //       font-weight: bold;
  //     }
  //     & tbody {
  //       font-size: 0.6rem;
  //       & > tr > td {
  //         border-right: 1px solid #ccc;
  //         &:nth-child(1) {
  //         }
  //         // 챕터 & 개정요약 table
  //         &:nth-child(2) {
  //           padding: 0;
  //           margin: 0;
  //           // 챕터 & 개정요약 table > tr
  //           .statusBox {
  //             border-bottom: 1px solid #ccc;
  //             &:nth-last-child(1) {
  //               border-bottom: none;
  //             }
  //           }
  //           // 챕터번호 <td>
  //           .chapterNumberBox {
  //             display: flex;
  //             flex-direction: column;
  //             width: 14.4vw;
  //             padding: 1vh;
  //           }
  //           // 개정요약 <td>
  //           .summaryBox {
  //             width: 100%;
  //             padding: 1vh;
  //             vertical-align: top;
  //             border-left: 1px solid #ccc;
  //           }
  //         }
  //       }
  //       & > tr > td:nth-child(1) {
  //         border-left: 1px solid #ccc;
  //       }
  //     }
  //   }
  // }

  &__dcnNum {
    // 개정이력 제목 부분
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .dcnNumBack {
      margin-left: 10px;
      :hover {
        cursor: pointer;
      }
    }
    .dcnNumTitle {
      padding: 15px 10px 0 15px;
    }

    // 규정번호, 챕터번호, 개정일 표 부분
    &__table {
      .table {
        width: 100%; /* 테이블의 전체 너비를 지정 */
        table-layout: fixed; /* 테이블 레이아웃을 고정으로 설정 */

        &::-webkit-scrollbar {
          display: none;
        }

        & thead {
          background-color: Color.$background_sub;
          position: sticky;
          top: 0;
          font-weight: bold;
        }
        & tbody {
          font-size: 0.6rem;
          & > tr > td {
            border-right: 1px solid #ccc;
            width: 33%;
            word-wrap: break-word;

            // 규정 번호
            &:nth-child(1) {
              width: 30%;
              height: auto;
            }
            // 챕터 & 개정요약 table
            &:nth-child(2) {
              // 챕터번호 <td>
              .chapterNumberBox {
                display: flex;
                flex-direction: column;
                // width: 14.4vw;
                padding: 1vh;
              }
              // 개정요약 <td>
              .summaryBox {
                // width: 100%;
                padding: 1vh;
                vertical-align: top;
                border-left: 1px solid #ccc;
              }
            }
          }
          & > tr > td:nth-child(1) {
            border-left: 1px solid #ccc;
          }
        }
      }
    }
  }

  // &__content {
  //   height: 62vh;
  //   display: block;

  //   &__header {
  //     height: 4.5vh;
  //     &__left {
  //       display: inline-flex;
  //       align-items: center;
  //       .css-b62m3t-container {
  //         width: 15vw;
  //         margin-right: 2vw;
  //       }
  //     }
  //     &__right {
  //       float: right;
  //     }
  //   }

  //   // DCN one
  //   &__enact {
  //     display: flex;
  //     justify-content: space-between;
  //     height: 55vh;

  //     &__chapterBtns {
  //       width: 10vw;
  //       min-height: 55vh;
  //       max-height: 55vh;
  //       overflow-y: auto;

  //       .enactchapter__chapterBtn {
  //         margin-bottom: 0.3vh;
  //       }

  //       .enactchapter__chapterBtn .btn {
  //         width: 100%;
  //         border: 1px solid Color.$black;
  //         color: Color.$black;
  //       }

  //       .enactchapter__chapterBtn .btn.active {
  //         color: Color.$pointColor;
  //         border: 2px solid Color.$pointColor;
  //       }
  //     }

  //     &__chapterContents {
  //       min-width: 18vw;
  //       max-width: 20vw;
  //       min-height: 55vh;
  //       max-height: 55vh;
  //       overflow-y: auto;
  //       border: 1px solid #ddd;
  //       > div:nth-child(1) {
  //         padding: 0.7vh;
  //         font-size: 1.1rem;
  //       }
  //       > div:nth-child(2) {
  //         padding-left: 1.2vw;
  //       }
  //     }

  //     &__content {
  //       min-width: 62vw;
  //       min-height: 55vh;
  //       max-height: 55vh;
  //       overflow-y: auto;
  //       padding: 0.5%;
  //       border: 1px solid Color.$black;
  //     }
  //   }

  //   &__amend {
  //     display: flex;
  //     justify-content: space-between;
  //     height: 55vh;

  //     &__oldRule {
  //       padding: 0.5%;
  //       min-height: 55vh;
  //       max-height: 55vh;
  //       width: 49.5%;
  //       border: 1px solid gray;
  //       overflow-y: auto;
  //       &::-webkit-scrollbar {
  //         display: none;
  //       }
  //     }

  //     &__NewRule {
  //       padding: 0.5%;
  //       min-height: 55vh;
  //       max-height: 55vh;
  //       width: 49.5%;
  //       border: 1px solid gray;
  //       overflow-y: auto;
  //       &::-webkit-scrollbar {
  //         display: none;
  //       }
  //       & span > ins,
  //       & span > ins > p,
  //       & span > ins > h2,
  //       & span > ins > h3,
  //       & span > ins > img {
  //         background-color: green;
  //       }

  //       & span > del,
  //       & span > del > p,
  //       & span > del > h2,
  //       & span > del > h3,
  //       & span > del > img {
  //         background-color: red;
  //       }
  //     }
  //   }
  // }
}
