@use '../Color';

.modetc {
  position: relative;
  display: flex;

  &__sidelist {
    position: fixed;
    z-index: 2;
    height: 100%;
    background-color: aliceblue;

    transition: width 0.5s ease;

    & .cirpopup {
      position: absolute;
      top: 0;
      left: 33vw;
      width: 30vw;
      height: 35vh;
      padding: 1vh;
      color: Color.$black;
      z-index: 999;
      border-radius: 1vh;
      box-shadow: 0.2vh 0.2vh 0.5vh 0.1vh #aaa;
      background-color: Color.$white;
      &__header {
        font-size: 1.3rem;
        height: 15%;
        text-align: center;
        & > svg {
          float: right;
          cursor: pointer;
        }
      }
      &__body {
        height: 65%;
        display: flex;
        flex-direction: column;
        padding: 1vh;
        border-top: 1px solid Color.$gray;
        border-bottom: 1px solid Color.$gray;

        &__cateBox {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          span {
            padding: 0 1vw;
            font-size: 1.1rem;
          }
        }

        &__inputBox {
          height: 33%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .lanBox {
            display: flex;
            width: 20vw;
            .lanRadiobtn {
              height: 3vh;
            }
            .btn {
              display: flex;
              align-items: center;
              font-size: 0.9rem;
            }
          }
          span {
            text-align: left;
            width: 5vw;
          }
          .numBox,
          .nameBox {
            width: 20vw;
            box-shadow: none;
            height: 4vh;
            border-radius: 1vh;
            border: 1px solid #ccc;
          }
        }
      }
      &__bottom {
        // height: 20%;
        padding-top: 1vh;
        display: flex;
        justify-content: flex-end;

        & .btn:hover {
          color: orange;
        }
      }
    }

    &__title {
      height: 5vh;
      display: flex;
      justify-content: space-between;
    }

    &__titlespan {
      line-height: 5vh;
      font-size: 1.2rem;
      padding: 0 1vw;
    }

    & .sidelistbtn-open {
      margin-left: auto;
    }

    & .circollapse {
      &__header {
        padding: 0.5vh 0.5vw;
        display: flex;
        justify-content: space-between;
        margin-bottom: '0.5vh';
        & > .clickarea {
          cursor: pointer;
        }
        & .btn {
          padding: 1px 10px;
          margin-left: 1vw;
        }
      }

      &__toggle {
        transition: all ease 0.3s;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          color: Color.$color_1;
        }

        &.open {
          transform: rotate(180deg);
          color: Color.$color_1;
        }

        &__title {
        }
      }

      &__child {
        height: 0px;
        overflow: hidden;
        padding-left: 2vw;
        transition: all ease 0.1s;

        &.open {
          height: auto;
          display: flex;
          flex-direction: column;
          margin-bottom: 2vh;
        }
      }

      &__child__item {
        align-items: center;
        display: flex;
        justify-content: space-between;
        & > span > .cirbtn-new:hover {
          color: green;
        }
        & > span > .cirbtn-del:hover {
          color: orange;
        }
        & > span > .cirbtn-edit:hover {
          color: blue;
        }
      }
    }
  }

  .sidebar-closed-text {
    /* 사이드바가 닫혔을 때 텍스트 스타일 */
    writing-mode: vertical-rl;
  }

  &__manage {
    margin-left: 4vw;
    height: 100%;
  }
}

.modcirdefault {
  padding-left: 40vw;
}

.modcirnew {
  display: flex;
  width: 100%;

  &__side {
    width: 32vw;

    &__titletype {
      font-size: 1.1rem;
      padding: 5px 10px;
      border-radius: 5px;
      background-color: #5dbb63;
      color: white;
    }

    & > h5 {
      padding: 0 10px;
    }

    &__currentcirlist {
      max-height: 38vh;
      overflow-y: auto;
      padding: 0 10px;

      &__item {
        border-bottom: 1px solid gray;
        padding: 3px 10px;
      }
    }
  }

  &__previewer {
    width: 57vw;
    display: flex;

    border: 1px solid navy;
    height: 75vh;
    padding: 0.5vh;
    & > .hysiap {
      overflow: hidden;
    }
  }
}

.modcirdel {
  display: flex;
  width: 100%;

  &__side {
    width: 32vw;
    padding-right: 1vh;

    &__titletype {
      font-size: 1.1rem;
      padding: 5px 10px;
      border-radius: 5px;
      background-color: #dc143c;
      color: white;
    }

    & > h5 {
      padding: 0 10px;
    }

    &__currentcirlist {
      max-height: 73vh;
      overflow-y: auto;
      padding: 0 10px;

      &__item {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid gray;
        text-align: center;
        padding: 3px 10px;

        & > div:nth-child(1) {
          display: flex;
          max-width: 90%;
          height: auto;
          align-items: center;
          cursor: pointer;
          text-align: start;
        }
        & > div:nth-child(2) {
          font-size: 0.8rem;
        }
        & .btn:hover {
          color: #dc143c;
        }
      }
    }
  }

  &__previewer {
    width: 57vw;
    display: flex;

    border: 1px solid navy;
    height: 75vh;
    padding: 0.5vh;
    & > .hysiap {
      overflow: hidden;
    }
  }
}

.modciredit {
  display: flex;
  width: 100%;

  &__side {
    width: 32vw;

    &__titletype {
      font-size: 1.1rem;
      padding: 5px 10px;
      border-radius: 5px;
      background-color: navy;
      color: white;
    }

    & > h5 {
      padding: 0 10px;
    }

    &__currentcirlist {
      max-height: 37vh;
      overflow-y: auto;
      padding: 0 10px;

      &__item {
        border-bottom: 1px solid gray;
        padding: 5px 10px;
      }
    }
  }

  &__previewer {
    width: 57vw;
    display: flex;

    border: 1px solid navy;
    height: 75vh;
    padding: 0.5vh;
    & > .hysiap {
      overflow: hidden;
    }
  }
}
