@use '../../Color.scss';

.filiterSelect {
  color: #f18546;
}

.mobileRuleSearch {
  &__filterTabs {
    padding: 1vh;
    font-size: 1rem;

    & > div {
      padding: 10px;
      cursor: pointer;

      & > span {
        background-color: green;
        color: #fff;
        padding: 4px 8px;
        border-radius: 7px;
        margin-left: 3px;
        font-size: 0.9rem;
      }
    }
  }
}

.mobileRuleSearch__main {
  display: block;
  margin-top: 1vh;
  overflow-x: auto;
  // max-height: 85vh;

  &__header {
    display: flex;
    justify-content: space-between;
    &__btns {
      display: flex;

      & .btns__item {
        text-align: center;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        border: none;

        svg {
          display: inline-block;
          display: flex;
          align-items: center;
          vertical-align: middle;
        }

        &:hover {
          border: none;
        }
      }
    }
    &__search {
    }
  }

  &__tablecontainer {
  }

  &__loading {
    width: 100%;
    & th {
      height: 100%;
      text-align: center;
      border-bottom: 1px solid Color.$gray;
    }

    &__spinner {
      height: 69vh;
      width: 100%;
      text-align: center;
      display: flex;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__table {
    width: 100%;
    position: relative;

    &__header {
      position: sticky;
      top: 0;
      background-color: Color.$background_sub;

      & > tr > th {
        text-align: center;
        font-size: 0.8rem;
        border-bottom: 1px solid Color.$gray;
      }

      & .depSelect {
        text-align: center;
        font-weight: bold;
        background-color: Color.$color;
        border: none;

        &:focus {
          box-shadow: none;

          & option {
            position: relative;
            text-align: center;
            background-color: Color.$white;
          }
        }
      }
    }

    &__body {
      // text-align: center;
      // overflow: hidden;

      & > tr > td {
        font-size: 0.6rem;
        padding: 1vh;
        border-left: 1px solid Color.$gray;
        border-bottom: 1px solid Color.$gray;
        &:nth-child(1) {
          border-left: none;
        }

        & strong {
          background-color: Color.$pointColor;
        }
        & input {
          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}
