@use '../Color';

.joinUp {
  .requiredValue {
    color: orangered;
  }
  & h4 {
    margin-bottom: 3vh;
  }
  &__header {
    & svg {
      width: 2.5em;
      height: 2.5em;
      fill: Color.$background;
      &:hover {
        fill: Color.$pointColor_1;
      }
    }
  }
  &__content {
    width: 100%;
    height: 78vh;
    &__mark {
      padding-left: 9vw;
      margin-bottom: 1vh;
      font-size: 0.8rem;
      color: gray;
    }

    .joinUpBox {
      padding: 3vh 0 3vh 1vw;
      margin-bottom: 5vh;
      display: flex;
      align-items: center;
      border-radius: 1vh;
      box-shadow: 0.2vh 0.2vh 0.4vh 0.2vh Color.$gray;

      &__label {
        width: 10vw;
      }

      &__input {
        max-width: 15vw;
        box-shadow: none;
        &:active {
          border-color: Color.$black;

          box-shadow: none;
        }
        &:focus {
          border-color: Color.$black;
          box-shadow: none;
        }
      }

      .dateInput {
        width: 10vw;
      }

      &__gradeSelect {
        display: block;

        span {
          font-size: 0.8rem;
          color: darkblue;
        }
      }
    }

    &__inputBox {
      display: flex;
      justify-content: space-around;
      &__leftBox {
        width: 45vw;
        & > div {
          width: 30vw;
          margin: 0 auto;
        }
      }
      &__rightBox {
        width: 45vw;
        & > div {
          width: 30vw;
          margin: 0 auto;
        }

        & .joinUpBox:nth-child(2) {
          .depSelect {
            width: 15vw;
          }
        }
        & .joinUpBox:nth-child(3) {
          .joinUpBox__select {
            width: 12vw;
          }
        }
        & .joinUpBox:nth-child(4) {
          .joinUpBox__select {
            width: 12vw;
          }
        }
        .btn {
          float: right;
          margin: 8vh 7.5vw 0 0;
        }
      }
    }
  }
}
