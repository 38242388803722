@use '../Color.scss';

.companyOrg {
  height: 80vh;
  overflow-y: auto;
  padding: 1vh;
  // 스크롤바 커스텀
  &::-webkit-scrollbar {
    width: 0.8vw;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgb(117, 117, 117);
    border-radius: 0.5vh;
    background-clip: padding-box;
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: #dcdcdc;
    border-radius: 0.5vh;
    box-shadow: inset 0px 0px 5px white;
  }
}

.OrgChart {
  margin: 0 1vw 1.5vh 0;
  &__orgBox {
    width: 17vw;
    border: 1px solid #ccc;
    border-radius: 1vh;

    &__mainCon {
      padding: 0.5vh;
      display: flex;
      justify-content: center;

      input {
        font-size: 1rem;
        min-width: 11vw;
        width: 12vw;
        text-align: center;
        border: none;
        padding: 0.5vh;
        border: 1px solid #ccc;
      }

      & > span {
        width: 2vw;
        padding: 0.7vh;
        font-size: 0.9rem;
        text-align: center;
        border-right: 1px solid #ccc;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
      }
      & > .btn {
        padding: 0.6vh;
        margin: 0;
        font-size: 0.7rem;
        border: none;
      }
      &__toggle {
        display: flex;
        align-items: center;
        // padding: 0.5vh;
        .toggleBox {
          transition: all ease 0.3s;
          &.open {
            transform: rotate(180deg);
            color: Color.$color_1;
          }
        }
      }
    }
    &__subCon {
      display: flex;
      justify-content: center;
      gap: 3%;
      padding: 0.5vh;

      .btn {
        margin: 0;
        font-size: 0.8rem;
        border: none;
        &:hover {
          color: Color.$pointColor;
        }
        &:focus {
          color: Color.$pointColor;
        }
        &:active {
          color: Color.$pointColor;
        }
      }
    }
  }
}
.modal {
  &__body {
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 1.1rem;
    margin-top: 1vw;

    & form {
      padding-bottom: 40px;
    }
    &__main {
      width: 20vw;
      height: 8vh;
      gap: 3%;
      display: flex;
      align-items: center;

      .form-control {
        width: 18vw;
        height: 4vh;
        text-align: center;
        border: none;
        border: 1px solid #ccc;
        &:focus {
          border: 1px solid Color.$background;
          box-shadow: none;
          // box-shadow: 0px 0px 3px 1px #000;
          // border: none;
        }
      }
      .btn {
        width: 3vw;
        height: 4vh;
        display: flex;
        text-align: center;
        align-items: center;
        border: none;
      }
    }
  }
}
