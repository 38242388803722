@use '../Color.scss';

.formdual {
  &__contents {
    display: flex;
    gap: 2%;
  }

  &__left {
    width: 49%;
    height: 10vh;

    .leftHeader {
      display: flex;
      justify-content: space-around;
      height: 100%;

      &__lanBtn {
        float: left;
        display: block;
        width: 20%;
        height: 100%;

        & > label {
          width: 100%;
          & > div {
            width: 100%;
            float: left;
            & > button {
              width: 100%;
              margin: 1% 0;
              padding: 0.8vh 1vh;
              background-color: Color.$white;
              border: 1px solid #ddd;
              box-shadow: 2px 2px 2px 1px #ddd;
              color: #ccc;
              border-radius: 1vh;
            }
            & > .active {
              border: 2px solid Color.$color_1;
              color: Color.$color_1;
              background-color: rgba(255, 222, 160, 0.205);
              z-index: 999;
            }
          }
        }
      }
      &__ruleCateBtn {
        width: 75%;
        height: 100%;
        display: block;
        & > label {
          padding: 0.6%;
          width: 100%;
          gap: 1%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          & > span {
            width: 4vw;
            text-align: right;
          }
          & > div {
            min-width: 29vw;
          }
        }
      }
    }
  }

  &__right {
    width: 49%;

    .rightHeader {
      display: flex;
      justify-content: space-around;
      height: 100%;
      height: 10vh;

      &__lanBtn {
        float: left;
        display: block;
        width: 20%;
        height: 100%;

        & > label {
          width: 100%;
          & > div {
            width: 100%;
            float: left;
            & > button {
              width: 100%;
              margin: 1% 0;
              padding: 0.8vh 1vh;
              background-color: Color.$white;
              border: 1px solid #ddd;
              box-shadow: 2px 2px 2px 1px #ddd;
              color: #ccc;
              border-radius: 1vh;
            }
            & > .active {
              border: 2px solid Color.$color_1;
              color: Color.$color_1;
              background-color: rgba(255, 222, 160, 0.205);
              z-index: 999;
            }
          }
        }
      }
      &__ruleCateBtn {
        width: 75%;
        height: 100%;
        display: block;
        & > label {
          padding: 0.6%;
          width: 100%;
          gap: 1%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;

          & > span {
            width: 4vw;
            text-align: right;
          }
          & > div {
            min-width: 29vw;
          }
        }
      }
    }

    &__selectops {
      display: flex;
      & > div:nth-child(1) {
        width: 30%;
      }
      & > div:nth-child(2) {
        width: 70%;
      }

      &__lanradiobtn {
        margin: 2vh 3vw;
      }
    }
  }

  &__left__select {
    margin: 2vh 0 1vh 15vh;
    max-width: 68%;
  }

  &__left__chapterBox {
    max-width: 68%;
    margin: 0 0 0 15vh;
    z-index: 100;
  }

  &__right__select {
    margin: 2vh 0 1vh 5vh;
  }

  &__right__chapterBox {
    margin: 0 0 0 5vh;
    z-index: 100;
  }

  &__text__container {
    margin-top: 1vh;
    height: 74vh;
    box-shadow: 2px 2px 5px 1px #ccc;
    // border: 1px solid Color.$gray;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    & div {
      width: 100%;
      padding: 1em;
      border-bottom: 1px solid Color.$gray;
    }
  }

  &__viewer {
    display: flex;
    flex-direction: column;
    height: 75vh;
    overflow: hidden;
    &__select {
      margin-left: 25%;
      margin-right: 1.5vh;
      height: 5vh;
      & > label {
        padding: 0.6%;
        width: 100%;
        gap: 1%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        & > span {
          width: 4vw;
          text-align: right;
        }
        & > div {
          min-width: 29vw;
        }
      }
    }
    &__chapselect {
      width: 80%;
    }
    &__body {
      width: 100%;
      padding: 1vh 1vw;
      & .hysiap {
        overflow-y: hidden;
      }
    }
    #react-doc-viewer {
      height: 71.5vh;
      &:active {
        border: none;
        box-shadow: none;
      }
    }
  }
}

#overide-css-dualtext {
  //ruledual
}
