@use '../Color';

#pdf-controls {
  display: none;
}

#WACStatusBarContainer {
  display: none;
  height: 0;
}

.ms-CommandBarItem {
  display: none;
}

.form {
  width: 100%;
  height: 86vh;

  &__header {
    border-top: 2px solid Color.$black;
    border-bottom: 2px solid Color.$black;
    display: flex;
    justify-content: space-between;
    padding: 0 10px 0 10px;

    &__info {
      display: flex;
      gap: 1vw;
      padding: 0 10px 0 10px;
      align-items: center;
      justify-content: space-between;
      & > .form__header__title {
        font-size: larger;
      }

      & > .form__header__select {
        width: 12vw;
      }
    }

    &__btns {
      padding: 1vh 1vw;
      display: flex;
      gap: 0.9%;

      & > .btns__item {
        font-size: 0.8vw;
        margin: 0.1em;
        padding: 6px 0;
        width: 6vw;
        // min-height: 16px;
        text-align: center;
        display: flex;
        gap: 5%;
        align-items: center;
        justify-content: center;
        border: none;
        // white-space: nowrap;

        svg {
          width: 16px;
          height: 16px;
          display: inline-block;
          display: flex;
          align-items: center;
          vertical-align: middle;
        }

        &:hover {
          background-color: Color.$background_1;
          border: none;
        }
      }
    }
  }

  &__body {
    display: flex;
    margin: 0 auto;
    height: 84vh;
    justify-content: center;

    &__sidetabs {
      width: 20vw;

      &__partinfo {
        height: 4vh;
        padding: 0.5vh 0.5vw;
        color: black;
      }
      &__item {
        padding: 8px 20px;
      }
      &__btns {
        overflow-y: auto;
        max-height: 83vh;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      &__btns__wrapper {
        padding: 1vh 0.5vw;
        display: flex;
        flex-direction: column;

        & > Button {
          background-color: Color.$white;
          font-size: 0.9rem;
          border: 1px solid Color.$black;
          color: Color.$black;
          text-align: left;
          margin-bottom: 0.5vh;
          overflow-x: hidden;
          // & > span {
          //   white-space: nowrap;
          //   word-wrap: normal;
          // }
          & > span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // transition: transform 1s ease-in-out;
          }
          &:hover span {
            // transform: translateX(-50%);
          }
        }
      }
    }

    &__contents {
      width: 58vw;
      height: 81vh;
      // 하단 toolbar hidden
      overflow: hidden;
      position: relative;
      // overflow: auto;

      &__title {
        height: 3vh;
        padding: 0 0.5vw;
        display: flex;
        justify-content: space-between;

        & span {
          line-height: 3vh;
        }
        & span:first-child {
          width: 82%;
          overflow-x: hidden;
        }
        & span:nth-child(2) {
          width: 18%;
        }
      }

      &__viewer {
        height: 81vh;
        padding: 0.5vh 0.5vw;
      }

      &__text {
        max-height: 81vh;
        padding: 0.5vh 0.5vw;
        overflow-y: auto;
        overflow-x: hidden;
      }

      &::-webkit-scrollbar {
        display: none;
      }

      & .hysiap {
        overflow: hidden;
      }
      &__chapterBox {
        // background-color: #fff;
        margin: 2vh 1vh 1vh 0;
        width: 100%;
        top: 15vh;
        height: 100%;
        overflow-y: auto;
        z-index: 200;
        &::-webkit-scrollbar {
          display: none;
        }
        .table {
          text-align: center;
          background-color: #fff;
          & > thead {
            height: 4vh;
            // position: sticky;
            // top: 0;
            border: 1px solid #ccc;
            background-color: Color.$background_sub;
          }
          tr > td {
            border: 1px solid #ccc;
          }
        }
      }
    }

    &__pagebtn {
      display: flex;
      align-items: center;
      // margin: 10vw;
      & Button {
        background-color: transparent;
      }

      & svg {
        color: Color.$button;
        font-size: 3rem;
        cursor: pointer;
      }
    }
  }

  &__main__formpartbtns {
    width: 35%;
  }
}
