@use 'Color';

.setting {
  &__title {
  }

  &__main {
    padding-top: 4vh;
    display: flex;
    justify-content: space-around;

    .leftContents {
      width: 45%;
      & > div {
        margin-top: 3vh;
      }

      & h5 {
        padding-bottom: 2vh;
      }
      &__contentsBox {
        display: grid;
        & > div {
          display: flex;
          ul {
            background-color: lighten(Color.$gray, 17%);
            list-style: none;
            width: 26vw;
            height: 11vh;
            padding: 1vh;
            margin: 0;
            display: grid;
            align-items: center;
            font-size: 0.95rem;
          }
        }
      }
      .btn {
        width: 6.2vw;
        height: 11vh;
        margin: 0 2vw 3vh 0;
        border: 4px solid darken(Color.$background_sub_1, 10%);
        &:hover {
          border: 4px solid Color.$background;
          & svg {
            width: 3.2rem;
            height: 3.2rem;
            color: Color.$background;
          }
          & span {
            font-size: 1rem;
            color: Color.$background;
          }
        }
        & svg {
          width: 3rem;
          height: 3rem;
          margin-bottom: 1vh;
          color: darken(Color.$background_sub_1, 10%);
        }
        & span {
          font-size: 0.9rem;
          color: darken(Color.$background_sub_1, 30%);
        }
      }
    }
    .rightContents {
      width: 45%;
      & > div {
        margin-top: 3vh;
      }

      & h5 {
        padding-bottom: 2vh;
      }
      &__contentsBox {
        display: grid;

        & > div {
          display: flex;
          .btn {
            width: 6.2vw;
            height: 11vh;
            margin: 0 2vw 3vh 0;
            border: 4px solid darken(Color.$background_sub_1, 10%);
            &:hover {
              border: 4px solid Color.$background;
              & svg {
                width: 3.2rem;
                height: 3.2rem;
                color: Color.$background;
              }
              & span {
                font-size: 1rem;
                color: Color.$background;
              }
            }
            & svg {
              width: 3rem;
              height: 3rem;
              margin-bottom: 1vh;
              color: darken(Color.$background_sub_1, 10%);
            }
            & span {
              font-size: 0.9rem;
              color: darken(Color.$background_sub_1, 30%);
            }
          }
          ul {
            background-color: lighten(Color.$gray, 17%);
            list-style: none;
            width: 26vw;
            height: 11vh;
            padding: 1vh;
            margin: 0;
            display: grid;
            align-items: center;
            font-size: 0.95rem;
          }
        }
        // .serviceCenter {
        //   .btn {
        //     border: 4px solid Color.$background;
        //     &:hover {
        //       border: 4px solid Color.$background;
        //       & svg {
        //         width: 3rem;
        //         height: 3rem;
        //         color: none;
        //       }
        //       & span {
        //         font-size: 0.9rem;
        //         color: none;
        //       }
        //     }
        //     & svg {
        //       width: 3rem;
        //       height: 3rem;
        //       margin-bottom: 1vh;
        //       color: Color.$background;
        //     }
        //     & span {
        //       font-size: 0.9rem;
        //       color: Color.$background;
        //     }
        //   }
        // }
      }
    }
  }
}
