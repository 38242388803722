@use '../Color.scss';

.rulebreadcrumb {
  line-height: 3vh;
  --bs-breadcrumb-divider: '>';

  .breadcrumb-item > a,
  .breadcrumb-item > li {
    // breadcrumb 링크가능 아이템 색상
    font-size: 0.9rem;
    color: gray;
    &:hover {
      font-weight: 700;
      color: Color.$background;
    }
  }
  & li {
  }

  & a {
    text-decoration: none;
  }

  .breadcrumb-item.active > a,
  .breadcrumb-item.active > li {
    // breadcrumb 링크 불가능 아이템 색상
    pointer-events: none;
    text-decoration: none;
    color: Color.$background;
  }
}

.breadcrumbs {
  --bs-breadcrumb-divider: '>';

  .breadcrumb {
    margin-bottom: 0.5vh;
  }

  .breadcrumb-item > a,
  .breadcrumb-item > li {
    // breadcrumb 링크가능 아이템 색상
    font-size: 1rem;
    color: gray;
    &:hover {
      font-weight: 700;
      color: Color.$background;
    }
  }
  & li {
  }

  & a {
    text-decoration: none;
  }

  .breadcrumb-item.active > a,
  .breadcrumb-item.active > li {
    // breadcrumb 링크 불가능 아이템 색상
    pointer-events: none;
    text-decoration: none;
    color: Color.$background;
  }
}
