@use '../Color.scss';

.userauth {
  // max-width: 100vw;
  display: block;

  &__header {
    margin-top: 2.5vh;
    display: flex;
    position: relative;
    border-bottom: solid 2px Color.$background;
    width: 100%;

    &__tabitem {
      z-index: 10;
      width: 12vw;
      height: 4vh;
      margin-right: 0.3vw;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .btn-primary {
      background-color: Color.$background_sub;
      color: Color.$black;
      opacity: 0.8;
      border: none;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        opacity: 1;
        background-color: Color.$background;
      }

      &:active {
        opacity: 1;
        background-color: Color.$background;
      }
    }
    .btn.active {
      opacity: 1;
      background-color: Color.$background;
      color: Color.$color;
      border: none;
    }
  }

  &__section {
    margin-top: 1vh;
  }

  &__topHeader {
    display: flex;
    justify-content: space-between;

    &__leftBox {
      margin-top: 1vh;
      display: flex;
      float: left;
      .groupbar {
        width: 23vw;
      }

      .rulenum,
      .teamName {
        font-size: large;
        color: Color.$background_1;
      }
    }
    &__rightBox {
      margin-top: 0.2vh;
      float: right;
      .btn {
        margin-left: 0.5vw;
      }
    }
  }

  &__table {
    height: 75vh;
    display: flex;
    margin-top: 1vh;
    border: 1px solid Color.$gray;

    & .auths__item {
      height: 5vh;
      padding: 0 5%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .authsChangeBtn {
      .btn {
        font-size: 0.8rem;
        color: lighten(Color.$pointColor, 15%);
        border: none;
        &:hover {
          color: Color.$pointColor;
        }
        &:active {
          color: Color.$pointColor;
        }
      }
    }

    & .border__bottom {
      border-bottom: 1px solid Color.$gray;
    }

    & .auths__submit {
      padding: 5% 4%;
      float: right;

      .btn {
        font-size: 0.8rem;
        padding: 1vh;
        height: 3vh;
        display: flex;
        align-items: center;
        text-align: center;
        border: none;
      }
    }

    &__list {
      position: sticky;
      top: 0;
      width: 22%;
      overflow: auto;
      border-right: 1px solid Color.$gray;
    }
    & > .spinner-border {
      width: 4rem;
      height: 4rem;
      position: absolute;
      top: 53vh;
      left: 60vw;
    }

    &__authlist {
      position: sticky;
      top: 0;
      width: 18%;
      // overflow: auto;
      border-right: 1px solid Color.$gray;

      &__title {
        text-align: center;
        width: 100%;
        height: 6.5vh;
        line-height: 6.5vh;
        border-bottom: 1px solid Color.$gray;
        background-color: Color.$background_sub;
      }
      .depSection {
        padding: 10px;
        width: 22vw;
        position: absolute;
        z-index: 9999;
        border: 1px solid #ccc;
        box-shadow: 3px 3px 8px 0px;
        border-radius: 10px;
        background-color: Color.$white;

        &__header {
          text-align: center;
          font-size: 1.3rem;
          padding-bottom: 1.2vh;
        }
        &__body {
          padding-top: 1vh;
          height: 11vh;
        }
        &__bottom {
          float: right;
          margin-top: 3vh;
          .btn {
            border: none;
          }
        }
      }

      .manualSection {
        padding: 10px;
        // height: 45.5vh;
        width: 22vw;
        position: absolute;
        z-index: 9999;
        border: 1px solid #ccc;
        box-shadow: 3px 3px 8px 0px;
        border-radius: 10px;
        background-color: Color.$white;

        &__header {
          text-align: center;
          font-size: 1.3rem;
          padding-bottom: 1.2vh;
        }
        &__body {
          display: flex;
          justify-content: space-between;
          padding-top: 1vh;
          height: 33vh;
          .manualListItem {
            display: flex;
            flex-direction: column;
            width: 48%;
            // height: 30vh;
            &__title {
              text-align: center;
              margin: 1vh 0;
            }
            &__body {
              height: 25vh;
              overflow: auto;
              padding: 1vh;
              border: 1px solid #ccc;
              &::-webkit-scrollbar {
                display: none;
              }
              & > div {
                display: flex;
                margin-bottom: 0.7vh;
                justify-content: space-between;
                .removeSvg {
                  cursor: pointer;
                  &:hover {
                    fill: orangered;
                  }
                }
                .addSvg {
                  cursor: pointer;
                  &:hover {
                    fill: green;
                  }
                }
              }
            }
          }
        }
        &__bottom {
          float: right;
          margin-top: 3vh;
          .btn {
            border: none;
          }
        }
      }

      .PosiSection {
        padding: 10px;
        width: 22vw;
        position: absolute;
        z-index: 9999;
        border: 1px solid #ccc;
        box-shadow: 3px 3px 8px 0px;

        border-radius: 10px;
        background-color: Color.$white;

        &__header {
          text-align: center;
          font-size: 1.3rem;
          padding-bottom: 1.2vh;
        }
        &__body {
          padding-top: 1vh;
          height: 10vh;
          background-color: aliceblue;
        }
        &__bottom {
          float: right;
          margin-top: 3vh;
          .btn {
            border: none;
          }
        }
      }

      .PWSection {
        padding: 10px;
        width: 22vw;
        position: absolute;
        z-index: 9999;
        border: 1px solid #ccc;
        box-shadow: 3px 3px 8px 0px;

        border-radius: 10px;
        background-color: Color.$white;

        &__header {
          text-align: center;
          font-size: 1.3rem;
          padding-bottom: 1.2vh;
        }
        &__body {
          padding-top: 1vh;
          height: 6vh;
        }
        &__bottom {
          float: right;
          margin-top: 3vh;
          .btn {
            border: none;
          }
        }
      }
    }

    & .views__item {
      height: 5vh;
      padding: 0 5% 0 2%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div:nth-child(1) {
        padding-left: 1%;
        width: 89%;
        & span {
          padding-left: 0.8vw;
        }
      }
      & > div:nth-child(2) {
        width: 11%;
        display: flex;
        gap: 2.2vw;
      }
      &__delbtn {
        cursor: pointer;
      }
      &__delbtn:hover {
        color: red;
      }
      &__switchbtn {
        cursor: pointer;
      }
    }

    & .cates__item {
      height: 5vh;
      padding: 0 4%;
      display: flex;
      align-items: center;
      &__addbtn {
        cursor: pointer;
      }
      &__addbtn:hover {
        color: green;
      }
    }

    &__viewlist {
      // position: sticky;
      top: 0;
      width: 35%;
      border-right: 1px solid Color.$gray;

      &__title {
        text-align: center;
        padding-right: 0.3vw;
        width: 100%;
        height: 6.5vh;
        border-bottom: 1px solid Color.$gray;
        background-color: Color.$background_sub;
        display: flex;
        // justify-content: space-between;
        & > div > span {
          line-height: 6.5vh;
        }
        & > div:nth-child(1) {
          width: 82%;
        }
        & > div:nth-child(n + 2) {
          width: 9%;
          & > div {
            height: 50%;
            line-height: 3.25vh;
          }
          & > div > svg {
            margin: 0 5px;
            cursor: pointer;
          }
        }
      }
      &__body {
        height: 68vh;
        max-height: 68vh;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &__catelist {
      position: sticky;
      top: 0;
      width: 25%;
      &__title {
        text-align: center;
        width: 100%;
        height: 6.5vh;
        border-bottom: 1px solid Color.$gray;
        background-color: Color.$background_sub;

        & span {
          line-height: 6.5vh;
        }
      }
      &__body {
        height: 68vh;
        max-height: 68vh;
        overflow: auto;
        .cates__item {
          gap: 0.8vw;
        }
      }
    }

    &__userlist {
      width: 77vw;
      height: 75vh;
      max-height: 75vh;
      position: relative;
      overflow-y: auto;
      // 스크롤바 커스텀
      &::-webkit-scrollbar {
        display: none;
      }
    }
    & .tableBox {
      width: 100%;
      &__header {
        width: 100%;
        position: sticky;
        top: 0;
        height: 5vh;
        background-color: Color.$background_sub;
        border-bottom: 1px solid Color.$gray;
        & tr {
          & th {
            text-align: center;
            .btn {
              border: none;
            }
          }
        }

        &__first {
          & > th:nth-child(n + 3) {
            padding-top: 1vh;
          }
        }

        &__firstorg {
          & > th:nth-child(n + 4) {
            padding-top: 1vh;
          }
        }

        &__second {
          & > th:nth-child(n + 2) {
            & > span > svg {
              margin: 0 0.3vw;
              cursor: pointer;
            }
            & > svg {
              margin: 0 0.3vw;
              cursor: pointer;
            }
          }
        }

        &__secondorg {
          & > th {
            & > span > svg {
              margin: 0 0.3vw;
              cursor: pointer;
            }
            & > svg {
              margin: 0 0.3vw;
              cursor: pointer;
            }
          }
        }

        &__btnAll {
          font-size: 0.7rem;
          padding: 1px 10px;
        }

        &__thrulemode {
          & > span {
            margin: 0 1.5vw;
          }
        }

        & .depSelect {
          .css-b62m3t-container {
            // display: flex;
            // justify-content: center;
          }
          .css-1u9des2-indicatorSeparator {
            display: none;
          }
        }

        &__popuphandleall {
          position: absolute;
          margin-top: 1vh;
          padding: 2vh 1vw;
          width: 15vw;
          height: 8vh;
          background-color: Color.$white;
          box-shadow: 3px 3px 8px 0px;
          border-radius: 10px;

          & Button:nth-child(1),
          & Button:nth-child(2) {
            margin-right: 1vw;
          }
          & Button:nth-child(3) {
            border: none;
            float: right;
            &:hover {
              background-color: Color.$white;
              color: orangered;
            }
            &:active {
              background-color: Color.$white;
              color: orangered;
            }
          }
        }
      }

      &__body {
        text-align: center;

        &__spinner {
          & > tr > td {
            width: 100%;
            height: 60vh;
            text-align: center;
          }
        }

        &__noselected {
          & div {
            height: 70vh;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        & .tableBox__body__tr {
          & > .td__username {
            text-align: left;
            padding-left: 2vw;
          }

          & > .td__userchange {
            & > svg:hover {
              cursor: pointer;
              background-color: orange;
              // border: 1px gray solid;
              border-radius: 5px;
            }
          }

          height: 6vh;

          & td {
            border-bottom: 1px solid Color.$gray;

            & input {
              &:focus {
                box-shadow: none;
              }
            }
          }
        }

        & .tableBox__body__toggletr {
          height: 15vh;
          border: 2px solid Color.$color;
          & > td:nth-child(2) {
            text-align: left;
          }

          .viewbadge {
            min-width: 5vw;
            max-width: 10vw;
            height: 3.2vh;
            display: inline-flex;
            justify-content: space-around;
            margin: 0 0.5vw 0.8vh 0;
            padding: 1vh;
            flex-grow: 1;
            border-radius: 0.8vh;
            color: white;
            font-size: 1.1rem;
            border: 1px solid Color.$gray;
            text-align: center;

            &__roleBtn {
              padding-left: 5px;
            }

            &__svgBtn {
              padding-left: 7px;
            }

            #viewbadge__closeBtn {
              :hover {
                fill: rgb(212, 19, 19);
              }
            }

            & span {
              display: inline-flex;
              align-items: center;
              text-align: center;
              justify-content: center;
              :hover {
                fill: gray;
              }
            }
            & svg {
              cursor: pointer;
              user-select: none;
            }

            &.m {
              color: rgb(255, 106, 106);
            }
            &.sp {
              color: orange;
            }
            &.p {
              color: #ffdd1c;
            }
            &.i {
              color: rgb(29, 134, 29);
            }
            &.d {
              color: rgb(38, 38, 253);
            }
            &.ocd {
              color: rgb(128, 20, 128);
            }
            &.e {
              color: purple;
            }
            &.etc {
              color: skyblue;
            }
          }

          & .viewrolesTd {
            width: 100%;
            padding-left: 2vw;
            // display: table-cell;

            &__head {
              // float: left;
              text-align: left;
              // width: 7.5%;
              height: 2vw;
            }

            &__badges {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
            }
            & .btn {
              margin: 0;
            }
          }
          & .viewrolesBtns {
            position: relative;
            & > div {
              padding: 2vw;
            }

            & > Button {
              margin-top: 5.6vw;
              background-color: gray;
              border-color: gray;
            }

            &__popup {
              // padding: 10px;
              height: 12vw;
              max-height: 11vw;
              overflow-y: scroll;
              width: 30vw;
              bottom: 5vh;
              right: 7vw;
              position: absolute;
              z-index: 2;
              border: 1px solid #ccc;
              box-shadow: 3px 3px 8px 0px;

              border-radius: 10px;
              background-color: Color.$white;
            }
          }
        }

        &__button > label {
          margin: 0 0.5vw;
        }

        &__button > label > input {
          margin-right: 0.5vw;
        }

        & > Button {
          background-color: Color.$background;
          border: 0;
          height: 4vh;
          width: 3vw;
        }
      }
    }
  }
}
