@use '../Color.scss';

.compare {
  display: block;

  & h2 {
    text-align: center;
    padding-top: 2vh;
  }

  &__header {
    margin: 1.5vh 0 0 0.5vw;
    height: 3vh;
  }

  &__mainbody {
    &__selectBox {
      display: flex;
      gap: 1vw;
    }

    &__ruleselect {
      display: flex;
      margin-bottom: 1vh;
    }

    &__leftSelect {
      height: 5vh;
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      background-color: Color.$background_sub;
      border: 1px solid Color.$background_sub;
      &__title > span {
        margin-left: 2vw;
        font-size: 1.1rem;
      }
    }

    &__rightSelect {
      height: 5vh;
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      background-color: Color.$background_sub;
      border: 1px solid Color.$background_sub;
      &__title > span {
        margin-left: 2vw;
        font-size: 1.1rem;
      }
    }

    &__diffcontents {
      display: flex;
      gap: 1vw;

      &__item {
        width: 50%;
      }

      & .diffcontents__item {
        &__title {
          color: white;
          width: 40%;
          padding: 3px 10px;
          font-size: 1.2rem;
          background-color: #20265b;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;

          &__icon {
            margin-left: 5px;
            padding: 0 5px;
            border-radius: 5px;
            background-color: #f1b647;
            color: white;
            font-weight: 900;
          }
        }

        &__new {
          height: 74vh;
          max-height: 74vh;
          overflow-y: auto;
          // 스크롤 기능은 살리고 스크롤 바 숨기기
          &::-webkit-scrollbar {
            display: none;
          }
          border: 1px solid Color.$gray;
          padding: 10px;

          & ins {
            background-color: #a8e6cf;
          }

          & del {
            background-color: #ffaaa5;
          }
        }

        &__old {
          height: 74vh;
          max-height: 74vh;
          overflow-y: auto;
          // 스크롤 기능은 살리고 스크롤 바 숨기기
          &::-webkit-scrollbar {
            display: none;
          }
          border: 1px solid Color.$gray;
          padding: 10px;

          &__nodata {
            text-align: center;
            font-size: 1.2rem;
            padding: 2vh 0;
          }
        }
      }
    }
  }

  &__nochapters {
    font-size: 1.2rem;
    margin-top: 4vh;
    text-align: center;
    height: 20vh;
    line-height: 20vh;
    border-radius: 10px;
    border: 1px solid Color.$gray;
    background-color: Color.$background_sub;
  }

  .compareLeft {
    width: 50%;
    height: auto;

    &__contents {
      width: 100%;
      height: 80vh;

      #compareImg {
        & img {
          width: 100%;
        }
      }
      &__textBox {
        display: block;
        height: 80vh;
        border-radius: 10px;
        border: 1px solid Color.$gray;

        &__subTitle {
          height: 5vh;
          font-size: 1rem;
          display: flex;
          align-items: center;
          border-bottom: 1px solid Color.$gray;
          font-weight: 600;

          & > div:nth-child(1) {
            width: 34%;
            padding-left: 0.5vw;
          }

          & > div:nth-child(2) {
            width: 34%;
            text-align: center;
          }
          & > div:nth-child(3) {
            width: 32%;
            text-align: right;
            padding-right: 0.5vw;
          }
        }

        &__mainText {
          height: 75vh;
          width: 100%;
          padding: 1%;
          overflow: auto;

          // 스크롤 기능은 살리고 스크롤 바 숨기기
          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }

  .compareright {
    width: 50%;
    height: auto;

    #compareImg {
      & img {
        position: relative;
        width: 100%;
      }
    }
    &__contents {
      width: 100%;
      height: 80vh;
      border: 1px solid Color.$gray;
      border-radius: 10px;

      &__subTitle {
        height: 5vh;
        width: 100%;
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0.5vh 0 0.5vh 0;
        border-bottom: 1px solid Color.$gray;
        gap: 30%;
        font-weight: 600;

        & span {
          font-size: 1rem;
        }

        .css-b62m3t-container {
          margin-right: 0.1vw;
        }
      }

      &__textBox {
        float: left;
        width: 100%;
        height: 75vh;
        overflow: auto;
        padding: 1%;

        // 스크롤 기능은 살리고 스크롤 바 숨기기
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  &__formmainBody {
    padding: 0 0.5vw;
    display: flex;
    gap: 1vw;
    &__left {
      width: 50%;
      &__Select {
        height: 5vh;

        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        background-color: Color.$background_sub;
        border: 1px solid Color.$background_sub;
        &__title > span {
          margin-left: 2vw;
          font-size: 1.1rem;
        }
      }
    }

    &__right {
      width: 50%;
      &__Select {
        height: 5vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        background-color: Color.$background_sub;
        border: 1px solid Color.$background_sub;
        &__title > span {
          margin-left: 2vw;
          font-size: 1.1rem;
        }
      }
    }

    &__diffcontents__new {
      width: 100%;
      height: 74vh;
      max-height: 74vh;
      &::-webkit-scrollbar {
        display: none;
      }
      & .hysiap {
        overflow-y: hidden;
      }
      border: 1px solid Color.$gray;
      // padding: 10px;
    }

    &__diffcontents__old {
      width: 100%;
      height: 74vh;
      max-height: 74vh;
      &::-webkit-scrollbar {
        display: none;
      }
      & .hysiap {
        overflow-y: hidden;
      }
      border: 1px solid Color.$gray;
      // padding: 10px;
    }
  }

  &__nochapters {
    font-size: 1.2rem;
    margin-top: 4vh;
    text-align: center;
    height: 20vh;
    line-height: 20vh;
    border-radius: 10px;
    border: 1px solid Color.$gray;
    background-color: Color.$background_sub;
  }
}

.compare__mainbody {
  &__noContents {
    margin-top: 20px;
    font-size: 20px;
  }
}
