@use '../Color.scss';

.teammanualadmin {
  &__sortlist {
    max-height: 79vh;
    padding: 0 1vw;
    overflow-y: auto;
    overflow-x: hidden;
    & .hasmanual {
      height: 4vh;
      margin-bottom: 0.5vh;
      & > div {
        line-height: 4vh;
      }
      &__name > svg {
        color: red;
      }

      & svg:hover {
        cursor: pointer;
        color: orange;
      }
    }
  }

  &__orglist {
    max-height: 79vh;
    padding: 0 1vw;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0.8vw;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(117, 117, 117);
      border-radius: 0.5vh;
      background-clip: padding-box;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: #dcdcdc;
      border-radius: 0.5vh;
      box-shadow: inset 0px 0px 5px white;
    }

    & > .list__orgs__item > .list__orgs__item__title {
      margin-bottom: 0.5vh;
      border-bottom: 1px solid Color.$gray;
      height: 3.5vh;
      & > span {
        line-height: 3.5vh;
      }
    }

    & > .list__orgs__item {
      &__title {
        cursor: pointer;
        &:hover {
          color: Color.$pointColor_1;
        }
        &.active {
          background-color: lighten(Color.$pointColor, 30%);
        }
      }

      &__title > span:first-child {
        padding-right: 0.1vw;
      }

      & .orgchild > .list__orgs__item {
        padding-left: 0.8vw;

        & > .list__orgs__item__title > span {
          line-height: 3vh;
          padding-right: 0.2vw;
        }
      }
    }
  }
}
