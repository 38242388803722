@use 'Color';

.unauthorized {
  width: 95vw;
  height: 75vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__icon {
  }
  &__text {
    font-size: 1.2rem;
    margin-bottom: 2vh;
  }
  &__backBtn {
    button {
      padding: 1vh 2vh;
      border-radius: 1vh;
      background-color: Color.$white;
      border: 1px solid #ccc;
      &:hover {
        background-color: lighten(Color.$gray, 15%);
      }
    }
  }
}
