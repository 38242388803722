@use '../Color.scss';

.OrgMembers {
  width: 100%;
  & h4 {
    margin-bottom: 2.5vh;
  }
  & > div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1vh;
    & > span {
      font-size: 1.2rem;
      // border-bottom: 2px solid Color.$background;
    }
    & > svg {
      width: 2.5em;
      height: 2.5em;
      fill: Color.$background;
      &:hover {
        fill: Color.$pointColor_1;
      }
    }
  }

  &__tablewrap {
    max-height: 79vh;
    overflow-y: scroll;
  }

  & .table {
    text-align: center;

    & thead {
      position: sticky;
      top: 0;
      background-color: Color.$background_sub;
    }
    & > tbody > tr > td:nth-child(1) {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

// 구성원 추가 모달창
.OrgMembers__modal {
  &__title {
    text-align: center;
  }
  &__selectBox {
    display: flex;
    justify-content: space-around;
    margin: 2vh 0 4vh 0;
    .selectBox__users {
      width: 19vw;
      display: block;
      & > div {
        padding-top: 2vh;
      }
    }
    .selectBox__grades {
      width: 19vw;
      & > div {
        padding-top: 2vh;
      }
    }
  }
}
