@use '../Color.scss';

.enactrule {
  display: flex;

  &__main {
    width: 79vw;
    padding: 0.5vh 1.5vw;
    // border-top: 1px solid Color.$gray;
    // max-height: 800px;
    // overflow-y: scroll;

    &__header {
      display: flex;
      justify-content: space-between;

      &__dcninfo {
        padding-top: 6px;
      }

      .btn {
        font-size: 1.1rem;
        border: none;

        &:hover {
          color: Color.$pointColor_1;
        }
        &:active {
          color: Color.$pointColor_1;
        }
      }

      &__btns {
        text-align: center;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__addchapter {
      & > .addchapter__header {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 2px solid Color.$gray;
        margin-bottom: 10px;
      }

      & .addchapter__header__tabs {
        width: 12vw;
      }

      & .addchapter__header__modtypeselect {
        width: 8vw;
      }

      & .addchapter__header__numberinput {
        width: 19vw;
        padding-left: 1vw;
      }

      & .addchapter__header__nameinput {
        width: 36vw;
        padding-left: 1vw;
      }

      & .addchapter__tabs__item {
        border-radius: 10px;
        cursor: pointer;
        text-transform: uppercase;
        min-width: 5vw;
        max-width: 50%;
        padding: 5px 0;
        margin-right: 5px;
        background-color: Color.$white;
        border: 2px solid Color.$background;

        &:hover {
          color: Color.$color;
        }

        &:disabled {
          background-color: Color.$gray;
        }

        &:disabled:hover {
          background-color: Color.$gray;
        }

        &.active {
          background-color: Color.$background;
          color: Color.$white;
        }
      }

      & > .addchapter__input {
        max-height: 70vh;
      }
    }
  }

  &__viewerold {
    width: 50%;
    padding: 1vh 0.5vh;
    border: 1px solid #ccc;
    &__header {
      font-size: 0.9rem;
      display: flex;
      height: 4vh;
      justify-content: space-between;
      & > div:nth-child(1) {
      }
      & > div:nth-child(2) {
        .btn {
          font-size: 0.9rem;
          padding: 0.6vh;
          border: none;
          &:hover,
          &:active,
          &:focus {
            color: Color.$pointColor_1;
          }
        }
      }
    }
    &__mainCon {
      height: 78vh;
    }
    .infoPopup {
      position: absolute;
      top: 22vh;
      left: 25.5vw;
      z-index: 200;
      padding: 1vh;
      width: 35vw;
      height: 30vh;
      background-color: Color.$white;
      box-shadow: 0.4vh 0.4vh 1vh 0.1vh #aaa;
      & > div:nth-child(1) {
        height: 20vh;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 1vh;
        flex-direction: column;
        & > div {
          width: 30vw;
        }
      }
      & > div:nth-last-child(1) {
        display: flex;
        justify-content: flex-end;
        gap: 1%;
      }
    }
  }

  &__viewernew {
    width: 50%;
    padding: 1vh 0.5vh;
    border: 1px solid #ccc;
    &__header {
      font-size: 0.9rem;
      display: flex;
      justify-content: space-between;
      & > div:nth-child(1) {
        width: 20vw;
        height: 4vh;
        display: flex;
        justify-content: space-between;
      }
      & > div:nth-child(2) {
        .btn {
          font-size: 0.9rem;
          padding: 0.6vh;
          &:hover,
          &:active,
          &:focus {
            color: Color.$pointColor_1;
          }
        }
      }
    }
    &__mainCon {
      height: 78vh;
    }
  }
}
