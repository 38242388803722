@use '../../Color.scss';

.teammanual {
  &__header {
    height: 6vh;
    font-size: 1rem;
    position: sticky;
    top: 7vh;
    & > select {
      width: 100%;
      height: 100%;
      padding: 1vh 1vw;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 2px solid Color.$background;
      & > option {
        margin: 10px 0px;
      }
    }
  }

  &__manuallist {
    font-size: 0.9rem;
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
    // overflow-x: hidden;

    &__item {
      display: flex;
      align-items: center;
      gap: 1%;
      padding: 1vh 1vw;
      margin-top: 0.2vh;
      border: 1px solid Color.$button;
      & > div:nth-child(1) {
        width: 6vw;
        svg {
          font-size: 1.5rem;
        }
      }
      & > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        & > div > span {
          padding-right: 0.5vw;
        }
        & > span {
          font-size: 0.7rem;
        }
      }
    }
  }

  // 매뉴얼 상세페이지
  &__oneManual {
    display: flex;
    flex-direction: column;
    .topheader {
      position: sticky;
      top: 7vh;
      z-index: 200;
      background-color: Color.$white;
      width: 100%;
      height: 6vh;
      display: flex;
      align-items: center;
      text-align: center;
      .btn {
        position: absolute;
        border: none;
        padding: 0;
        padding: 0 5%;
      }
      span {
        width: 100%;
        text-align: center;
      }
    }
    .fakeheader {
      position: sticky;
      top: 13vh;
      z-index: 200;
      background-color: Color.$white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1.5vh;

      & > select {
        width: 90%;
        height: 100%;
        padding: 1vh 1vw;
        border: none;
        border: 1px solid #ccc;
      }
    }
    .contents {
      width: 100%;
      height: 100vh;
      .infoBox {
        position: absolute;
        top: 20vh;
        right: 1%;
        z-index: 200;
      }

      &__info {
        font-size: 0.8rem;
        padding: 1.5vh;
        position: fixed;
        top: 20vh;
        right: 1%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 95%;
        height: auto;
        z-index: 200;
        box-shadow: 3px 3px 5px 1px #ccc;
        background-color: Color.$white;
        &__top {
          border-bottom: 2px solid #000;
          & > dl {
            display: flex;
            margin-bottom: 0;
            max-height: 15vh;
            border-top: 1px solid #eee;
            padding: 0.5vh;
            dt {
              width: 20%;
            }
            dd {
              width: 80%;
              display: flex;
              justify-content: center;
              white-space: pre-line;
            }
          }
        }
        &__bottom {
          & > div {
            height: 20vh;
            overflow-y: auto;
            padding: 0.5vh;
            border-top: 1px solid #ccc;
            &::-webkit-scrollbar {
              display: none;
            }
            .oldManualBox {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 0.9rem;
              border-bottom: 1px solid #ccc;
              & > dl {
                height: 13vh;
                margin: 0;
                margin-bottom: 0.5vh;
                display: flex;
              }

              & dl:nth-child(1) {
                width: 70%;
                display: flex;
                flex-direction: column;
                & > dt {
                }
                & > dd {
                  padding: 0.5vh;
                  height: 10.5vh;
                  margin-bottom: 0;
                  white-space: pre-line; // 줄바꿈 적용
                  overflow-y: scroll;
                  &::-webkit-scrollbar {
                    display: none;
                  }
                }
              }
              & dl:nth-child(2) {
                width: 30%;
                display: flex;
                flex-direction: column;
              }
            }
          }
        }
      }
      &__file {
        position: sticky;
        margin: 3vh 0;
        left: 0;
        height: 100%;
        width: 100%;
        transform-origin: center;
      }

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}
