$white: white;
$gray: #ccc;
$black: #000;

$background: #20265b;
$background_1: darken($background, 8%);

$background_sub: #f4f6fb;
$background_sub_1: darken($background_sub, 10%);

$color: rgb(241, 182, 71); // #f1b647
$color_1: darken($color, 5%);

$pointColor: rgb(241, 133, 70); // #f18546
$pointColor_1: darken($pointColor, 5%);

$button: rgba(187, 190, 176, 0.993);
$button_1: darken($button, 20%);
