@use '../../Color.scss';

.mobileMyPage {
  padding: 1vh;
  &__header {
    text-align: center;
    margin-bottom: 3vh;
  }
  &__contents {
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
    &__profileBox {
      width: 85%;
      height: 15vh;
      margin: 0 auto;
      margin-bottom: 3vh;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      box-shadow: 1px 1px 3px 1px #ccc;
      border-radius: 1vh;
      .profileImg {
        width: 20vw;
        height: 20vw;
        display: flex;
        align-items: center;
        img {
          border-radius: 50%;
          width: 80%;
        }
      }
    }
    &__signBox {
      width: 85%;
      height: 10vh;
      margin: 0 auto;
      margin-bottom: 1vh;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      box-shadow: 1px 1px 3px 1px #ddd;
      border-radius: 1vh;

      .signatureimg {
        width: 20vw;
        height: 20vw;
        display: flex;
        align-items: center;


        &__header {
          width: 50%;
          padding-left: 8%;
        }

        img {
          width: 70%;
          // margin-left: 65%
        }
      }
    }
    &__item {
      width: 85%;
      height: 8vh;
      margin: 0 auto;
      margin-bottom: 1vh;
      display: flex;
      align-items: center;
      box-shadow: 1px 1px 3px 1px #ddd;
      border-radius: 1vh;

      .title {
        width: 50%;
        padding-left: 8%;
      }
    }
  }
}
