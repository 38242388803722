@use 'Color';

.mainpage {
  width: 100%;
}

.mainsearch {
  height: 35vh;
  background: lighten(Color.$button, $amount: 24%);
  width: 100%;
  padding: 16px;
  font-size: 1.2rem;
  align-items: center;
  display: flex;
  flex-direction: column;

  &__section {
    display: flex;
    padding-top: 12vh;
    gap: 4%;

    &__searchbar {
      text-align: center;
      width: 20vw;
      height: 4vh;
      border-radius: 5px;
      border: 1px solid Color.$background_sub;
    }
    &__button.btn-primary {
      width: 6vw;
      height: 4vh;
      font-size: 1.2rem;
      border: none;
      color: Color.$white;

      &:hover {
        background-color: Color.$background_1;
      }
      &.btn {
        &:active {
          background-color: Color.$background_1 !important;
        }
      }
    }
  }
}

.mainicon {
  width: 45vw;
  display: grid;
  flex-direction: column;
  margin: 0 auto;
  padding: 6vh;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  text-align: center;

  & a {
    text-decoration: none;
    color: Color.$background;
    &:hover {
      color: Color.$background;
    }
    .badge {
      position: absolute;
      margin-top: 0.8vw;
      margin-left: 2.8vw;

      color: Color.$white;
      width: 1.5vw;
      height: 3vh;
      font-size: 1.1rem;
      padding: 0.6vh 0 0 0.1vw;
      z-index: 200;
      background-color: orangered;
    }
  }
  &__zeroIcon {
    position: absolute;
    margin-top: 0.8vw;
    margin-left: 2.8vw;
    width: 1.5vw;
    height: 3vh;
    z-index: 200;
    border-radius: 50%;
    border: 2px solid Color.$background;
    background-color: Color.$white;
  }

  &__style {
    border: 4px solid Color.$background;
    border-radius: 10%;
    padding: 5px;
    width: 100px;
    height: 100px;
    align-items: center;

    &:hover {
      border: 4px solid Color.$background_1;
      color: Color.$background_1;
      font-weight: bold;
    }

    &:active {
      color: Color.$background_1;
    }
  }

  &__subtitle {
    padding-top: 1.5vh;
    font-weight: 600;
    font-size: 1.1rem;
    color: Color.$background;
    cursor: pointer;
  }
}
