@use '../Color.scss';

.noticeMain {
  width: 100%;

  & h4 {
    margin-bottom: 2.5vh;
  }

  &__search {
    margin-bottom: 1vh;
    display: flex;
    justify-content: flex-end;
  }

  &__list {
    min-height: 70vh;
    max-height: 75vh;
    text-align: center;
    // background-color: antiquewhite;

    & thead {
      height: 40px;
      // position: sticky;
      background-color: Color.$background_sub;
      font-weight: bold;
    }

    & tbody {
      a {
        color: Color.$black;
        text-decoration: none;
        float: left;
      }
    }
  }

  &__page {
    margin-top: 3vh;
    display: flex;
    flex-direction: row;
    justify-content: center;

    .pagination {
      margin: 0 auto;
      --bs-pagination-bg: none;
      --bs-pagination-active-color: none;
      --bs-pagination-disabled-bg: none;
    }

    .page-link {
      color: Color.$button;
    }

    .page-item.active .page-link {
      color: Color.$background;
      background-color: Color.$background_sub;
      border: 1px solid Color.$gray;
    }
    .page-link:focus,
    .page-link:hover {
      color: Color.$background;
      background-color: Color.$background_sub;
      border-color: Color.$gray;
      box-shadow: none;
    }
    .page-link.active {
      color: Color.$gray;
      background-color: Color.$background_sub;
      border-color: Color.$gray;
      box-shadow: none;
    }
  }
}
