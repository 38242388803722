@use '../Color';

.teamPage {
  width: 100%;
  // margin-top: 2vh;
  display: flex;
  flex-direction: column;

  &__content {
    height: 75vh;
    display: flex;
    margin-top: 1vh;
    border: 1px solid Color.$gray;
    .authTeamListBox {
      width: 20vw;
      border-right: 1px solid Color.$gray;
      &__title {
        height: 6.5vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: Color.$background_sub;
        border-bottom: 1px solid Color.$gray;
      }
    }
    .authTableScrollBox {
      width: 55%;
      max-height: 75vh;
      overflow-y: scroll;
      border-right: 1px solid Color.$gray;
      &::-webkit-scrollbar {
        display: none;
      }
      .tableBox {
        &__header {
          height: 6.4vh;
          position: sticky;
          top: 0;
          background-color: Color.$background_sub;
          border-bottom: 1px solid Color.$gray;
          tr > th {
            text-align: center;
          }
        }
        &__body {
          &__selected {
            height: 6vh;
            border-bottom: 1px solid Color.$gray;
            & td:nth-child(1):hover {
              cursor: pointer;
              color: orangered;
            }
          }
        }
      }
    }
    .authUnuserListBox {
      width: 25%;
      height: 75vh;
      &__title {
        position: sticky;
        top: 0;
        width: 100%;
        height: 6.5vh;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid Color.$gray;
        background-color: Color.$background_sub;
      }
      &__List {
        height: 68.3vh;
        overflow-y: auto;
        &__selected {
          cursor: pointer;
          padding: 1vh;
          height: 5vh;
          display: flex;
          align-items: center;
          // justify-content: space-between;
          margin-bottom: 1vh;
          svg {
            margin-right: 1vh;
            &:hover {
              color: green;
            }
          }
        }
        &__noselected {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
