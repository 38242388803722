@use 'Color';

.MainNavSearchbar {
  margin-right: 30px;
  grid-template-columns: 400px auto;
  justify-content: center;
  z-index: 999;

  &__input {
    display: block;
    max-width: 300px;
    height: 40px;
    font-size: 0.7em;
    float: inline-end;
    align-self: center;
    justify-self: start end;
    border: 1px solid Color.$gray;
    border-radius: 5px;
    text-align: center;

    &:focus {
      // !important 안 주면 적용 안됨.
      box-shadow: none !important;
    }
  }
  & Button {
    height: 40px;
    color: Color.$white;
    --bs-btn-border-color: none;

    &:hover {
      background-color: Color.$background_1;
    }
    &:active {
      background-color: Color.$background_1;
    }
  }
}
