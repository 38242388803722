.pagination {
  display: flex;
  justify-content: space-between;
  width: 600px;
  margin: 0 auto;
  margin-top: 2rem;

  > .previous > a {
    text-decoration: none;
    color: black;
  }

  > .next > a {
    text-decoration: none;
    color: black;
  }

  > * {
    cursor: pointer;
  }
}

.pagination-mobile {
  margin-top: 3px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  list-style: none;

  > .previous > a {
    text-decoration: none;
    color: black;
  }

  > .next > a {
    text-decoration: none;
    color: black;
  }

  > * {
    cursor: pointer;
  }
}

.pagination__link {
  flex: 0 0 auto; /* 요소가 줄어들거나 늘어나지 않도록 설정 */
  text-align: center;
  transition: all 0.25s;
  padding: 0.2rem 0.6rem;
  border-radius: 0.3rem;
  color: gray;
  text-decoration: none;
}

.pagination__link__active {
  background-color: #20265b;
  color: #fefefe;
}
