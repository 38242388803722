@use '../Color.scss';

.rulesearch {
}
.rulesearch__header {
  & > div:nth-child(2) {
    margin-top: 2vh;
  }

  &__tabs {
    position: relative;
    width: 93vw;
    display: flex;
    user-select: none;
    height: 5vh;
    border-bottom: solid 0.5vh Color.$background_sub_1;
  }

  &__tabs > span {
    width: 23.25vw;
    line-height: 5vh;
    align-items: center;
    text-align: center;
    height: 5vh;
    cursor: pointer;
    display: inline-block;
    margin: 0;
    font-size: large;

    & > span {
      margin-left: 1vh;
      background-color: green;
    }
  }

  &__tabs > span:after {
    display: block;
    border-bottom: solid 3px #fff;
    transform: translateX(0);
    transition: transform 100ms ease-in-out;
  }

  &__tabs .open {
    opacity: 1;
    color: Color.$pointColor;
    font-weight: 700;
    // color: #fff;
  }
}

.rulesearch__tabitem {
  z-index: 10;
  /* opacity: 0.7; */
  color: #808080;

  &__glide {
    z-index: 11;
    /* background-color: #404040; */
    /* offset-x | offset-y | blur-radius | spread-radius | color */
    // box-shadow: 0px 4px 0px -1px Color.$pointColor;
    border-bottom: solid 0.5vh Color.$pointColor;
    opacity: 1;
    position: absolute;
    transition: all 200ms linear;
    box-sizing: border-box;
  }
}

.rulesearch__main {
  margin-top: 1vh;
  display: block;
  &__header {
    // height: 5vh;
    display: flex;
    justify-content: space-between;
    &__btns {
      padding: 10px 5px;
      display: flex;
      gap: 5px;

      & .btns__item {
        // font-size: 14px;
        // margin: 2px;
        padding: 2px 1px;
        width: 5vw;
        max-height: 5vh;
        min-width: 4vh;
        min-height: 4vh;
        text-align: center;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        // background-color: Color.$color_1;
        border: none;

        svg {
          width: 16px;
          height: 16px;
          display: inline-block;
          display: flex;
          align-items: center;
          vertical-align: middle;
        }

        &:hover {
          // background-color: Color.$pointColor_1;
          border: none;
        }
      }
    }
    &__search {
      width: 18vw;
    }
  }

  &__tablecontainer {
    max-height: 83vh;
    overflow-y: scroll;
  }

  &__loading {
    width: 100%;
    & th {
      height: 100%;
      text-align: center;
      border-bottom: 1px solid Color.$gray;
    }

    &__spinner {
      height: 69vh;
      width: 100%;
      text-align: center;
      display: flex;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__table {
    width: 100%;
    position: relative;

    &__header {
      position: sticky;
      top: 0;
      background-color: Color.$background_sub;
      font-weight: bold;
      border-bottom: 1px solid Color.$color_1;

      & th {
        height: 40px;
        text-align: center;
        border-bottom: 1px solid Color.$gray;
      }

      & .depSelect {
        text-align: center;
        font-weight: bold;
        background-color: Color.$color;
        border: none;

        &:focus {
          box-shadow: none;

          & option {
            position: relative;
            text-align: center;
            background-color: Color.$white;
          }
        }
      }
    }

    &__body {
      text-align: center;
      overflow: hidden;
      & .tdrulename {
        padding-top: 23px;
        // & span:nth-child(1) {
        //   display: block;
        // }
        & div:nth-child(2) {
          display: flex;
          vertical-align: center;
          margin-left: 10px;
          font-size: 10.5pt;
        }
      }

      .tdrulecontent {
        // padding-top: 23px;
        & span {
          max-width: 46vw;
          padding-left: 1vw;

          overflow: hidden;
          text-overflow: ellipsis;
          // white-space: nowrap;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }

      & td {
        height: 7vh;
        border-bottom: 1px solid Color.$gray;
        & strong {
          background-color: Color.$pointColor;
        }
        & input {
          &:focus {
            box-shadow: none;
          }
        }
      }

      &__button {
        & Button {
          color: Color.$black;
          border: none;
          font-size: 14px;
          margin-left: 20px;

          &:hover {
            background-color: Color.$background_1;
          }
          &:focus {
            color: Color.$white;
            background-color: Color.$background_1;
          }
        }
      }
    }
  }
}
