@use '../Color';

.MobileSetting {
  padding-top: 4vh;

  &__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 80%;
    margin: 0 auto;

    & > div {
      text-align: center;
    }

    .btn {
      width: 32vw;
      height: 16vh;
      margin-bottom: 3vh;
      border: 4px solid darken(Color.$background_sub_1, 10%);
      &:hover {
        border: 4px solid Color.$background;
        & svg {
          width: 3.2rem;
          height: 3.2rem;
          color: Color.$background;
        }
        & span {
          font-size: 1rem;
          color: Color.$background;
        }
      }
      & svg {
        width: 3rem;
        height: 3rem;
        margin-bottom: 1vh;
        color: darken(Color.$background_sub_1, 10%);
      }
      & span {
        font-size: 0.9rem;
        color: darken(Color.$background_sub_1, 30%);
      }
    }
  }
}

// .MobileSetting {
//   padding-top: 4vh;
//   &__main {
//     // display: flex;
//     // flex-direction: column;

//     & > div {
//       width: 80%;
//       display: flex;
//       margin: 0 auto;
//       gap: 5%;
//       // justify-content: space-around;
//     }

//     .btn {
//       width: 32vw;
//       height: 16vh;
//       margin: 0 auto;
//       margin-bottom: 3vh;
//       border: 4px solid darken(Color.$background_sub_1, 10%);
//       &:hover {
//         border: 4px solid Color.$background;
//         & svg {
//           width: 3.2rem;
//           height: 3.2rem;
//           color: Color.$background;
//         }
//         & span {
//           font-size: 1rem;
//           color: Color.$background;
//         }
//       }
//       & svg {
//         width: 3rem;
//         height: 3rem;
//         margin-bottom: 1vh;
//         color: darken(Color.$background_sub_1, 10%);
//       }
//       & span {
//         font-size: 0.9rem;
//         color: darken(Color.$background_sub_1, 30%);
//       }
//     }
//     & > div {
//       margin-top: 3vh;
//     }
//   }
// }
