@use '../Color.scss';

.addcontent {
  display: flex;
  height: 70vh;
  &__side {
    width: 18vw;
    padding-right: 1vw;
    & div {
      margin-bottom: 1vh;
    }
    &__chapcontentstitle {
      display: flex;
      justify-content: space-between;
    }
    &__chapcontents {
      max-height: 60vh;
    }
    &__submitbtn {
      & > button {
        margin-bottom: 0.5vh;
      }
      & .chapBtnSvg {
        padding: 0 10px;
      }
    }
  }

  &__editor {
    width: 58vw;

    display: block;
    & .ck-editor__editable {
      min-height: 64vh;
      max-height: 64vh;
    }

    & .ck.ck-editor__editable_inline {
      border: 1px solid var(--ck-color-base-border);
    }
  }
}

.addfile {
  display: flex;
  height: 70vh;

  &__previewer {
    border: 1px solid navy;
    height: 65vh;
    padding: 0.5vh;
    & > .hysiap {
      overflow: hidden;
    }
  }

  &__side {
    width: 18vw;
    padding-right: 1vw;
    & div {
      margin-bottom: 1vh;
    }
    &__chapcontentstitle {
      display: flex;
      justify-content: space-between;
    }
    &__chapcontents {
      max-height: 60vh;
    }

    &__submitbtn {
      & > button {
        margin-bottom: 0.5vh;
      }
      & .chapBtnSvg {
        padding: 0 10px;
      }
    }
  }

  &__fileinput {
    width: 58vw;

    display: block;
  }
}
