@use '../Color.scss';

.notice {
  margin-top: 2.5vh;
  // height: 84.5vh;

  .noticeTable {
    &__head {
      height: auto;
      border-bottom-color: Color.$background_1;
      p {
        font-size: 1.5rem;
      }
      &__sub {
        .subHeader {
          display: flex;

          & span {
            margin-right: 0.5vw;
          }
          &__left {
            width: 50%;
          }
          &__right {
            width: 50%;
          }
        }
      }

      &__fileBox {
        .attach {
          display: flex;

          &__content {
            display: flex;
            flex-flow: column wrap;
            width: auto;
            gap: 0.2vh;
            max-height: 8vh;
            font-size: 0.8rem;
            margin-left: 0.5vw;
            padding: 0.1vh 0.5vw 0 0.5vw;
            & > div {
            }

            & > div > span:nth-child(1) {
              padding: 0 0.5vh;
            }

            & svg {
              margin: 0 0.5vh;
              font-size: 1rem;
              cursor: pointer;
            }
          }
        }
      }
    }

    &__body {
      width: 100%;
      height: 60vh;
      border-bottom-color: Color.$background_1;

      & tr {
        & td {
          overflow: auto;
          &::-webkit-scrollbar {
            display: none;
          }

          & div {
            white-space: pre-wrap;
            height: 55vh;
          }
        }
      }
    }
  }

  &__btnList {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__backbtn {
      float: left;
    }
    &__btns {
      float: right;
      display: flex;
      width: 130px;
      flex-direction: row;
      justify-content: space-between;
    }
  }
  &.btn {
    font-size: 0.9rem;
    margin: 0;
  }
}
