@use 'Color';

.sidenav {
  // margin: 0;
  width: 4vw;
  // height: auto;
  height: 93vh;
  margin-top: 0;
  background: Color.$background;
  display: flex;
  align-content: space-between;
  position: relative;

  & a {
    text-decoration: none;
    border-inline-start: none;
    &:active {
      text-decoration: none;
      border-inline-start: none;
    }
  }

  &__Icon {
    margin: 0 auto;
    float: left;
    width: 4vw;
    place-items: start;
    display: block;

    &__link {
      &__block {
        width: 100%;
        height: 7.5vh;
        margin: 0 auto;
        display: grid;
        text-align: center;
        z-index: 10;
        color: Color.$white;
        padding: 8px;

        .badge {
          position: absolute;
          margin-top: 0.2vh;
          margin-left: 1.5vw;
          color: Color.$white;
          width: 1.1vw;
          height: 2vh;
          font-size: 0.8rem;
          padding: 0.4vh 0 0 0;
          z-index: 9999;
          background-color: orangered;
        }
        .zeroIcon {
          position: absolute;
          margin-top: 0.2vh;
          margin-left: 1.7vw;
          width: 0.8vw;
          height: 1.6vh;
          z-index: 200;
          border-radius: 50%;
          border: 1px solid Color.$background;
          fill: Color.$background;
          background-color: Color.$white;
        }

        &.openmenu {
          border-inline-start: 4px solid Color.$color_1;
          background-color: Color.$background_1;
          & span {
            color: Color.$color_1;
            font-weight: bold;
          }
          .badge {
            color: Color.$white;
            z-index: 9999;
          }
          .zeroIcon {
            z-index: 9999;
            border-radius: 50%;
            border: 1px solid Color.$background;
            fill: Color.$background;
            background-color: Color.$white;
          }
        }
        &:hover {
          border-inline-start: 4px solid Color.$color_1;
          background-color: Color.$background_1;
          & span {
            color: Color.$color_1;
            font-weight: bold;
          }
          .badge {
            color: Color.$white;
            z-index: 9999;
          }
          .zeroIcon {
            z-index: 9999;
            border-radius: 50%;
            border: 1px solid Color.$background;
            fill: Color.$background;
            background-color: Color.$white;
          }
        }
        &__style {
          width: 100%;
          height: 100%;
          z-index: 10;
          color: Color.$white;
          margin: 0 auto;
          display: block;
          transition: 0.5s ease-in-out;
          // background-color: black;
          &:active {
            // border-color: Color.$gray;
          }
        }
        &__block {
        }
      }
    }
    & span {
      z-index: 10;
      color: Color.$white;
      font-size: 0.7rem;
      justify-self: center;
      display: block;
      margin: 0 auto;
    }
  }
  &__setting {
    width: 4vw;
    max-height: 80px;
    clear: both;
    align-self: end;
    display: grid;
    row-gap: 10px;

    &__block {
      text-align: center;
      width: 4vw;
      padding: 4px;
      z-index: 10;
      color: Color.$white;
      height: 80px;
      margin: 0 auto;
      display: grid;
      place-items: center;

      &:hover {
        & svg {
          color: Color.$pointColor;
        }
      }

      &:active {
        & svg {
          color: Color.$pointColor;
        }
      }
      &__Icon {
        width: 60px;
        z-index: 10;
        color: Color.$white;
        height: 40px;
        margin: 0 auto;
        display: block;
      }
    }
  }
}

.toggle {
  background-color: Color.$background_1;
  position: absolute;
  left: 4vw;
  top: 0;
  place-items: start;
  text-decoration: none;
  z-index: 999;
  text-decoration: none;

  & a {
    text-decoration: none;
  }
  &__menu {
    text-decoration: none;
    width: 15vw;
    min-height: 93vh;
    text-align: center;
    color: Color.$white;
    margin: 0 auto;
    & > li {
      text-decoration: none;
    }
  }
}
.toggle__menu__header {
  // height: 10vh;
}
.toggle__menu__tabs {
  height: 4vh;

  display: flex;
  position: relative;
  user-select: none;
}
.toggle__menu__folderSetting {
  height: 4.5vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn {
    border: none;
    span {
      font-size: 0.8rem;
      color: Color.$white;
      padding-right: 0.2vw;
    }
    &:hover {
      span {
        color: Color.$color_1;
      }
      svg {
        fill: Color.$color_1;
      }
    }
  }
}
.categoryPlus {
  position: absolute;
  width: 30vw;
  height: 35vh;
  padding: 1vh;
  color: Color.$black;
  z-index: 999;
  border-radius: 1vh;
  box-shadow: 0.2vh 0.2vh 0.5vh 0.1vh #aaa;
  background-color: Color.$white;
  &__header {
    font-size: 1.3rem;
    height: 15%;
    text-align: center;
    & > svg {
      float: right;
      cursor: pointer;
    }
  }
  &__body {
    height: 65%;
    display: flex;
    flex-direction: column;
    padding: 1vh;
    border-top: 1px solid Color.$gray;
    border-bottom: 1px solid Color.$gray;
    &__inputBox {
      height: 33%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .lanBox {
        display: flex;
        width: 20vw;
        .lanRadiobtn {
          height: 3vh;
        }
        .btn {
          display: flex;
          align-items: center;
          font-size: 0.9rem;
        }
      }
      span {
        text-align: left;
        width: 5vw;
      }
      .numBox,
      .nameBox {
        width: 20vw;
        box-shadow: none;
        height: 4vh;
        border-radius: 1vh;
        border: 1px solid #ccc;
      }
    }
  }
  &__bottom {
    // height: 20%;
    padding-top: 1vh;
    display: flex;
    justify-content: flex-end;
  }
}
.categoryMinus {
  position: absolute;
  width: 38vw;
  height: 70vh;
  text-align: left;
  padding: 1vh;
  color: Color.$black;
  z-index: 999;
  border-radius: 1vh;
  box-shadow: 0.2vh 0.2vh 0.5vh 0.1vh #aaa;
  background-color: Color.$white;
  &__header {
    font-size: 1.3rem;
    height: 8%;
    text-align: center;

    & > svg {
      float: right;
      cursor: pointer;
    }
  }
  &__body {
    height: 60vh;
    padding: 1vh;
    border-top: 1px solid Color.$gray;
    border-bottom: 1px solid Color.$gray;
    &__tabs {
      padding-bottom: 1vh;
      .lanRadiobtn {
        height: 3vh;
      }
      .btn {
        display: flex;
        align-items: center;
        font-size: 0.9rem;
      }
    }
    &__cateBox {
      &__list {
        height: 53vh;
        overflow-y: auto;
        // 스크롤 커스텀
        &::-webkit-scrollbar {
          width: 0.8vw;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgb(117, 117, 117);
          border-radius: 0.5vh;
          background-clip: padding-box;
          border: 2px solid transparent;
        }
        &::-webkit-scrollbar-track {
          background-color: #dcdcdc;
          border-radius: 0.5vh;
          box-shadow: inset 0px 0px 5px white;
        }
        & > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: lighten(Color.$background_sub, 1%);
          border-bottom: 1px solid #ccc;
          .btn {
            border: none;
          }
          svg:hover {
            fill: orangered;
          }
        }
      }
    }
  }
}

.toggle__menu__tabs > span {
  width: 50%;
  border-radius: 2px;
  align-items: center;
  text-align: center;
  line-height: 3.8vh;
  height: 3.5vh;
  cursor: pointer;
  display: inline-block;
  margin: 0;
  font-size: 1rem;
}

.toggle__menu__tabs > span:after {
  display: block;
  border-bottom: solid 3px Color.$white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.toggle__menu__tabs {
  & .open {
    opacity: 1;
    color: Color.$color_1;
  }
}

.toggle__menu__tab {
  z-index: 2;
  font-weight: 700;
  font-size: 1rem;
  color: #808080;
}

.toggle__menu__tab:hover {
  color: Color.$color_1;
}

.toggle__menu__tabs > span:hover:after {
  opacity: 1;
}

.toggle__menu__tab__glide {
  box-shadow: 0 5px 0px -1px Color.$color_1;
  opacity: 1;
  position: absolute;
  transition: all 200ms linear;
  box-sizing: border-box;
}

.adminList {
  width: 100%;
  max-height: 84vh;
  margin: 0;

  text-decoration: none;
  list-style: none;
  overflow-y: scroll;
  text-overflow: ellipsis;
  display: grid;
  align-items: center;
  & .accordion-body {
    padding: 0;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.userToggleList {
  width: 100%;
  max-height: 89vh;
  margin: 0;
  padding: 1vh 0;
  text-decoration: none;
  list-style: none;
  overflow-y: scroll;
  text-overflow: ellipsis;
  display: grid;
  align-items: center;
  & .accordion-body {
    padding: 0;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.togglecollapse {
  &__headermanual {
    width: 15vw;
    display: flex;
    justify-content: space-between;
    padding: 0.4vw 0 0 0.4vw;
    text-align: left;

    & > span {
      font-size: 0.8rem;
      max-width: 15vw;
      margin: 0 8px 15px 0;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;

      & > svg {
        color: Color.$pointColor;
      }

      & > a {
        color: Color.$white;
      }

      &.open > a {
        color: Color.$pointColor;
      }
    }
  }

  &__header {
    display: flex;
    width: 15vw;
    height: 4vh;
  }
  &__title {
    width: 13vw;
    height: 4vh;
    line-height: 4vh;
    padding: 0 0.3vw;
    font-size: 0.8rem;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    &:hover {
      color: Color.$color_1;
    }
    &.on {
      color: Color.$color_1;
    }
  }

  &__togglewrapper {
    width: 2vw;
    height: 4vh;
    line-height: 4vh;
    display: flex; /* Flexbox를 사용하여 내부 아이템을 정렬 */
    align-items: center; /* 세로 방향으로 가운데 정렬 */
    justify-content: center; /* 가로 방향으로 가운데 정렬 */
  }

  &__toggle {
    transition: all ease 0.3s;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      color: Color.$color_1;
    }

    &.open {
      transform: rotate(180deg);
      color: Color.$color_1;
    }
  }

  &__content {
    height: 0px;
    overflow: hidden;
    transition: all ease 0.1s;

    &.open {
      height: auto;
      display: flex;
      flex-direction: column;
      margin-bottom: 2vh;
    }

    &.open > a {
      display: block;
      max-width: 14.4vw;
      padding: 0.7vh 0 0.7vh 2vw;
      overflow-x: hidden;
    }

    &__sub {
      text-align: left;
      font-size: 0.75rem;
      white-space: nowrap;
      // text-overflow: ellipsis;가 먹힐려면 inline-block 설정
      // display: inline-block;
      // overflow: hidden;
      // text-overflow: ellipsis;

      &:hover {
        color: Color.$color_1;
        cursor: pointer;
      }
      &.on {
        color: Color.$color_1;
      }
      &.open {
        color: Color.$color_1;
      }
    }
  }
}
