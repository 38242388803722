@use '../Color.scss';

.profileUpdate {
  width: 100%;
  height: 80vh;
  max-height: 80vh;
  display: block;

  & th {
    width: 15vw;
    height: 9.5vh;
    padding-left: 3.3vw;
  }

  &__check {
    height: 80vh;
    border-top: 2px solid Color.$background;
    display: block;
    text-align: center;
    padding-top: 20vh;
    & > div {
      max-width: 28vw;
      height: 20vh;
      margin: 0 auto;
      padding-top: 3vh;
      border-radius: 1vh;
      box-shadow: 0.4vh 0.4vh 1vh 0.1vh #aaa;

      .profileUpdate__checkform {
        display: flex;
        justify-content: center;
        & input {
          width: auto;
          height: 5vh;
          border: 1px solid Color.$gray;
          padding: 2.5vh;
          border-radius: 8px;
          margin-right: 1vw;
          &:focus {
            border: 1px solid Color.$gray;
            box-shadow: none;
          }
        }
      }
      & p {
        font-size: 1.2rem;
        padding-right: 3vw;
      }
    }
  }

  &__main {
    height: 75vh;

    .centerLine {
      padding: 0;
      margin: 4vh 0;
      border: Color.$gray solid 1px;
    }

    &__content {
      display: flex;
      height: 80vh;
      border-top: 2px solid Color.$background;

      .inputBox__left {
        width: 50%;
        padding: 5vh 2vw;

        .inputprofileimg {
          height: 50%;
        }

        .inputprofileimg__title {
          height: 5vh;
        }
        .inputprofileimg__body {
          display: flex;
          flex-direction: column;
          align-items: center;

          .inputprofileimg__container {
            width: 140px;
            height: 140px;
            border-radius: 50%;
            overflow: hidden;
            box-shadow: 2px 2px 3px 1px Color.$gray;

            & img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .inputprofileimg__btns {
            position: relative;
          }
          .inputprofileimg-label {
            margin-top: 2vh;
            margin-bottom: 1vh;
            font-size: 12pt;
            span {
              color: Color.$white;
              background-color: Color.$background;
              padding: 5px 10px;
              border-radius: 5px;
              cursor: pointer;
              &:hover {
                color: Color.$color;
              }
            }
          }
        }

        .inputsignatureimg {
          border: Color.$gray solid 1px;
          border-radius: 10px;
          height: 50%;
        }

        .inputsignatureimg__title {
          padding-left: 5vw;
          .title {
            background-color: Color.$white;
            font-size: large;
            position: relative;
            right: 2vw;
            bottom: 1.4vh;
            padding: 0 0.5vw;
          }
        }
        .inputsignatureimg__body {
          padding-top: 5vh;
          padding-bottom: 7vh;
          display: flex;
          flex-direction: column;
          gap: 2vh;
          align-items: center;

          .inputsignatureimg__wrap {
            width: 25vw;
            display: flex;
          }

          .inputsignatureimg__container {
            width: 100px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: gray solid 1px;

            & img {
              display: block;
              width: 46px;
              height: 46px;
            }
          }

          .inputsignatureimg__uploadinfo {
            padding: 1vh 2vw;

            > div {
              margin-bottom: 1vh;
            }
          }

          .inputsignatureimg__btns {
            width: 25vw;
            display: flex;
            gap: 1vw;
          }
        }
      }
      .inputBox__right {
        width: 50%;
        height: 100%;
        padding: 5vh 2vw;
        & input {
          width: auto;
          height: 5vh;
          border: none;
          padding: 2.5vh;
          box-shadow: 2px 2px 3px 1px Color.$gray;
          border-radius: 8px;
        }

        .inputContent {
          .inputCheck {
            margin-right: 1vw;
          }

          .checkText {
            margin-left: 0.8vw;
            color: Color.$background;
          }
        }
      }
    }
  }
  &__button {
    display: flex;
    justify-content: flex-end;
    margin: 4vh 1vw 0 0;
  }

  .checkText {
    margin-top: 1vh;
  }
}
