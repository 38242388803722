@use '../Color.scss';

.revInput {
  margin-top: 2vh;
  display: flex;
  gap: 0.5vw;
  &_inputs {
    width: 50%;
  }

  &_checks {
    width: 50%;
  }
  &__sidelist {
    text-decoration: none;
    width: 15vw;
    // min-height: 93vh;
    &__body {
      max-height: 81vh;
      overflow-y: scroll;
      text-align: center;
      // color: Color.$white;
      & .accordion-body {
        padding: 0;
      }
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &__chapterlist {
    text-decoration: none;
    width: 12vw;
    // min-height: 93vh;
    max-height: 85vh;
    overflow-y: scroll;
    text-align: center;
    // color: Color.$white;
    & .accordion-body {
      padding: 0;
    }
    &::-webkit-scrollbar {
      display: none;
    }

    &__header {
      height: 4vh;
    }
    &__body {
      max-height: 81vh;
      overflow-y: scroll;
      text-align: center;
      // color: Color.$white;
      & .accordion-body {
        padding: 0;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      & > div {
        display: flex;
        flex-direction: column;
        padding: 0 0.5vw;
        & > button {
          margin-bottom: 0.5vh;
        }
      }
    }
  }
  &__managesection {
    width: 100%;

    &__body {
      min-height: 76vh;
      max-height: 76vh;
      overflow-y: auto;

      & > table {
        text-align: center;
        & > thead {
          background-color: Color.$background_sub;
          position: sticky;
          top: 0;
          font-weight: bold;
        }
        & > thead > th {
          position: sticky;
          top: 0; /* Don't forget this, required for the stickiness */
        }
        & > tbody > tr > td > .btn {
          padding: 2px;
          font-size: small;
        }
      }
    }
  }

  &__popup {
    min-width: 80vw;
    &__content {
      min-height: 50vh;
    }
    &__inputs {
      display: flex;
      & > div {
        width: 50%;
      }
    }
  }
}

.togglecollapse {
  &__content {
    &__children {
      text-align: left;
      font-size: 0.75rem;
      white-space: nowrap;
      overflow: hidden;
      padding: 0.7vh 0 0.7vh 2vw;

      &:hover {
        color: Color.$color_1;
        cursor: pointer;
      }
      &.on {
        color: Color.$color_1;
      }
    }
  }
}

.toggle__menu__tab__revglide {
  box-shadow: 0 5px 0px -1px Color.$color_1;
  opacity: 1;
  position: absolute;
  transition: all 200ms linear;
  box-sizing: border-box;
}
