@use '../Color.scss';
.gradechart {
  &__header {
    padding: 1vh 1vw;
    text-align: right;

    &__orgselect {
      text-align: left;
      display: inline-block;
      width: 12vw;
      height: 4vh;
    }

    & > Button {
      & > svg {
        margin-left: 0.5vw;
      }
    }
  }
  & .PopupSection {
    padding: 10px;
    // height: 12vw;
    width: 22vw;
    right: 2vw;
    position: absolute;
    z-index: 9999;
    border: 1px solid #ccc;
    box-shadow: 3px 3px 8px 0px;

    border-radius: 10px;
    background-color: Color.$white;

    &__header {
      text-align: center;
      font-size: 1.3rem;
      padding-bottom: 1.2vh;
      border-bottom: 1px solid Color.$background_sub;
    }
    &__body {
      padding-top: 1vh;
      &__description {
        display: flex;
        font-size: 0.9rem;
        text-align: left;
      }
    }
    &__bottom {
      margin-top: 2vh;
      margin-bottom: 2vh;
      text-align: right;
    }
    &__tail {
      & > div:nth-child(1) {
        background-color: Color.$background_sub;
      }

      &__rolesitem {
        display: flex;
        flex-wrap: wrap;
        & > span {
          margin: 5px 5px;
          padding: 1px 2px;
          border-radius: 5px;
          border: 2px solid Color.$background;
        }
      }
    }
  }

  &__scrollwrap {
    // margin-top: 3vh;
    height: 74vh;
    overflow: auto;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }

  &__table {
    & thead {
      background-color: Color.$background_sub;
      position: sticky;
      top: 0px;
    }

    &.table > thead > tr th {
      text-align: center;
    }

    & .collapsegrade__body {
      text-align: center;
      width: 100%;

      & td:nth-child(1) {
        // padding: 0 10px;
        & > div {
          display: flex;
          align-items: center;
          justify-content: center;
          & span {
            margin-left: 0.5vw;
            margin-right: 0.5vw;
          }
        }
        & svg {
          fill: Color.$background;
          &:hover {
            cursor: pointer;
            fill: Color.$color_1;
          }
          &:active {
            cursor: pointer;
            fill: Color.$color_1;
          }
        }
      }
      & td:nth-child(3) {
        width: 100%;
      }

      & .authsrolesTd {
        display: inline-flex;
        float: left;
        width: auto;

        &__empty {
          height: 4vh;
        }

        & .default {
          width: 7vw;
          height: 4vh;
          display: flex;
          align-items: center;
          background-color: gray;
        }
      }

      & .addbtnTd {
        position: relative;

        & .addbtnTd__addBox {
          padding-top: 1.2vh;
          cursor: pointer;
        }

        & .addbtnTd__addBox.open > svg {
          color: Color.$color_1;
        }

        & .addbtnTd__addBox.open + .addbtnTd__addModal {
          position: absolute;
          right: 3vw;
          top: 0;
          z-index: 2;
          width: 20vw;
          height: auto;
          background-color: white;
          box-shadow: 0.4vh 0.4vh 1vh 0.1vh #aaa;
          border-radius: 10px;
        }

        &__addModal {
          // display: flex;
          &__header {
            padding: 10px;
            .btn {
              padding: 0;
              float: right;
              border: none;
              border-radius: 10px;
              background-color: #fff;
              &:hover {
                background-color: #fff;
              }
              &:active {
                background-color: #fff;
              }
              & svg {
                font-size: 1.2rem;
                fill: #000;
              }
            }
          }

          &__body {
            width: 20vw;
            display: flex;
            flex-wrap: wrap;
          }
          &__list {
            padding: 1vh;

            & span {
              padding-right: 10px;
            }
          }
          &__addBtn {
            float: right;
            margin: 1vh;
            border: none;
          }
        }
      }

      &__gradeChange {
        padding-top: 2vh;
        cursor: pointer;
        :hover {
          color: Color.$pointColor;
        }
      }

      &__view {
        padding-top: 2vh;
        text-align: center;
        :hover {
          color: Color.$pointColor;
        }
      }
      &__togglebtn {
        cursor: pointer;

        &.open {
          transform: rotate(180deg);
          color: orange;
          transition-duration: 0.3s;
        }
        &.hide {
          transform: rotate(360deg);
          transition-duration: 0.3s;
        }
      }
    }
    .collapsegrade__toggle {
      &.open {
        .viewTd {
          padding-top: 1vh;
          text-align: center;
        }
        & .viewrolesTd {
          width: 100%;
          padding-left: 2vw;
          // display: table-cell;

          &__head {
            float: left;
            width: 7.5%;
            height: 2vw;
          }

          &__badges {
            float: left;
            width: 92.5%;
            flex-wrap: wrap;
          }
          & .btn {
            margin: 0;
          }
        }
        & .viewrolesBtns {
          & > div {
            padding: 2vw;
          }

          & > Button {
            margin-top: 5.6vw;
            background-color: gray;
            border-color: gray;
          }
        }
      }
    }

    .authbadge {
      font-size: 0.9rem;
      min-width: 5vw;
      max-width: 7vw;
      height: 4vh;
      display: flex;
      margin: 0 0.5vw 0.8vh 0;
      padding: 0.5vh;
      flex-grow: 1;
      border-radius: 0.8vh;
      align-items: center;
      color: white;
      background-color: gray;
      text-align: center;

      &__rolebtn {
        width: 80%;
        text-align: center;
        align-items: center;
      }

      &__delbtn {
        width: 20%;
        text-align: center;
        align-items: center;
        :hover {
          fill: rgb(0, 0, 0);
        }
      }

      & span svg {
        width: 80%;
        display: block;
        margin: auto;
        cursor: pointer;
        user-select: none;
      }
    }

    .viewbadge {
      min-width: 6vw;
      max-width: 10vw;
      height: 3.2vh;
      display: inline-flex;
      justify-content: space-around;
      margin: 0 0.5vw 0.8vh 0;
      padding: 1vh;
      flex-grow: 1;
      border-radius: 0.8vh;
      color: white;
      font-size: 1.1rem;
      border: 1px solid Color.$gray;
      text-align: center;

      &__roleBtn {
        padding-left: 5px;
      }

      &__svgBtn {
        padding-left: 7px;
      }

      #viewbadge__closeBtn {
        :hover {
          fill: rgb(212, 19, 19);
        }
      }

      & span {
        display: inline-flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        :hover {
          fill: gray;
        }
      }
      & svg {
        cursor: pointer;
        user-select: none;
      }

      &.m {
        color: rgb(255, 106, 106);
      }
      &.sp {
        color: orange;
      }
      &.p {
        color: #ffdd1c;
      }
      &.i {
        color: rgb(29, 134, 29);
      }
      &.d {
        color: rgb(38, 38, 253);
      }
      &.ocd {
        color: rgb(128, 20, 128);
      }
      &.e {
        color: purple;
      }
      &.etc {
        color: skyblue;
      }
    }
  }

  .modal-content {
    .btn {
      border: none;
    }
  }
  // 등급변경 모달창
  .gradeChange__modal {
    &__title {
      padding-left: 5vw;
    }
    &__selectBox {
      display: flex;
      justify-content: space-around;
      margin: 2vh 0 2vh 0;
      .selectBox__users {
        width: 19vw;
        display: block;
        & > div {
          padding-top: 2vh;
        }
      }
      .selectBox__grades {
        width: 19vw;
        & > div {
          padding-top: 2vh;
        }
      }
    }
  }
}
