@use '../Color.scss';

//~ ModRuleChangeApproval.jsx
.modRuleOne__changeModal {
  .modal-dialog {
    display: flex;
    justify-content: center;
  }
  .modal-content {
    width: 54vw;
    min-width: 54vw;
  }
  .modRuleOne__changeModal__body {
    width: 50vw;
    height: 60vh;
    text-align: center;
    line-height: 5vh;

    .changePayline {
      margin: 0 auto;
      width: 45vw;
      min-height: 15vh;
      max-height: 15vh;
      margin-top: 3vh;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 1vh;
      box-shadow: 3px 3px 5px 2px #ccc;

      .css-b62m3t-container {
        width: 15vw;
      }
      .css-13cymwt-control {
        width: 15vw;
      }
      .css-13cymwt-control {
        width: 15vw;
      }
      .addSelect {
        // width: 20vw;
        padding: 0;
        margin: 0;
      }
      .addList {
        width: 20vw;
        & p {
          width: 100%;
          margin: 0;
        }
        &__nameList {
          float: left;
          & span {
            margin-right: 0.5vh;
            & svg:hover {
              fill: orangered;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
.approvalmodal__warning {
  & > div {
    height: 3vh;
    text-align: right;
  }
}

.approvalmodal__body {
  display: flex;
  margin-bottom: 1vh;
  & > .list {
    width: 32vw;
    display: flex;
    gap: 1vw;

    .list__orgs__body {
      min-height: 51vh;
      max-height: 51vh;
      width: 12vw;
      padding-left: 10px;
      padding-right: 10px;
      overflow-y: auto;
      overflow-x: hidden;
      border: 1px solid Color.$gray;

      & > .list__orgs__item > .list__orgs__item__title {
        margin-bottom: 0.5vh;
        border-bottom: 1px solid Color.$gray;
        height: 3.5vh;
        & > span {
          line-height: 3.5vh;
        }
      }
    }

    .list__orgs__item {
      &__title {
        cursor: pointer;

        &:hover {
          background-color: beige;
        }
        &.active {
          background-color: Color.$pointColor_1;
        }
      }

      &__title > span:first-child {
        padding-right: 0.3vw;
      }

      & > .orgchild > .list__orgs__item {
        padding-left: 1vw;
      }
    }

    .list__users__body {
      min-height: 51vh;
      max-height: 51vh;
      width: 18vw;
      padding-left: 5px;
      padding-right: 5px;
      border: 1px solid Color.$gray;
      overflow-y: auto;
      overflow-x: hidden;

      & > table {
        width: 100%;
        text-align: center;

        & > thead {
          position: sticky;
          top: 0;
          height: 3.5vh;

          background-color: Color.$white;
          & > tr > th {
            box-shadow: inset 0 -1px 0 Color.$gray;
          }
        }
        & > tbody > tr > td {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .list__users__item {
          cursor: pointer;
          &:hover {
            background-color: beige;
          }
          &.active {
            background-color: Color.$pointColor_1;
          }
        }
      }
    }
  }

  & > .approvalContainer {
    width: 22vw;
    > .examineBox,
    .approveBox,
    .referenceBox {
      display: flex;
      height: 17vh;
      max-height: 17vh;
    }
  }

  & > .approvalContainer > div .arrowBox {
    width: 4vw;
    height: 17vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5%;
    & > div svg {
      cursor: pointer;
    }
    & > div:first-child > svg:hover {
      color: green;
    }
    & > div:nth-child(2) > svg:hover {
      color: red;
    }
  }

  & > .approvalContainer > div .listContainer {
    & > .listBox {
      width: 17vw;
      height: 14vh;
      border: 2px solid #ccc;
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .btn {
        display: block;
        border: none;
      }
      .btn:hover,
      .btn:focus {
        border: none;
        color: Color.$pointColor_1;
      }
    }
  }
}

//! ModRuleChangeApproval.jsx end

//~ ModRuleConfirmApproval.jsx

.modRuleOne__confirmModal {
  min-width: 62vw;
  justify-content: center;
  & ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  & .modRuleOne__line {
    width: 100%;
    display: flex;
    justify-content: center;

    &__itemBox {
      display: flex;
      overflow-x: auto;
      padding: 2vh 1vh;

      &__subSection {
        display: flex;
        margin-right: 3vh;
      }
    }
    .modRuleBox {
      min-width: 8vw;
      max-width: 10vw;
      height: 18vh;
      text-align: center;
      border: 2px solid gray;
      margin-right: 1vh;
      border-radius: 0.5vh;
      &__thead {
        width: 100%;
        height: 3vh;
        // display: flex;
        color: Color.$black;
        border-bottom: 2px solid gray;
        border-top-right-radius: 0.5vh;
        border-top-left-radius: 0.5vh;
      }
      &__tbody {
        & ul {
          min-width: 7.8vw;
          max-width: 9.8vw;
          height: 14.5vh;
          & li {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          & li:nth-child(1) {
            height: 3vh;
          }
          & li:nth-child(2) {
            width: 100%;
            height: 5.6vh;
            gap: 5%;
            .signatureimg {
              // width: 100%;
              height: 100%;
            }

            .btn {
              border: none;
              background-color: Color.$pointColor_1;
            }
          }
          & li:nth-child(3) {
            height: 3vh;
          }
          & li:nth-child(4) {
            height: 3vh;
          }
        }
      }
    }

    .drafterBox {
      margin-right: 3vh;
    }

    .reviewerBox {
      border: 2px solid Color.$color_1;
      &__thead {
        color: Color.$color_1;
        border-bottom: 2px solid Color.$color_1;
      }
    }

    .paymentBox {
      border: 2px solid Color.$pointColor_1;
      &__thead {
        color: Color.$pointColor_1;
        border-bottom: 2px solid Color.$pointColor_1;
      }
    }

    .readerBox {
      &__tbody {
        height: 14.5vh;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }
        & ul {
          min-width: 8vw;
          max-width: 10vw;
          height: 3.5vh;
          line-height: 4vh;
        }
      }
    }
  }
}
