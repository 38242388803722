@use '../Color.scss';

.modRuleSetting {
  padding: 0 1.5vw;
  .approvList {
    height: 30vh;
    margin-top: 3vh;
    border: 1px solid Color.$gray;
    position: relative;

    & > p {
      position: relative;
      min-width: 6vw;
      // text-align: center;
      display: inline-block;
      top: -1.5vh;
      left: 0.5vh;
      padding: 0 0.2vw;
      margin: 0;
      font-size: 1.2rem;
      background-color: Color.$white;
    }

    &__contentsBox {
      height: 26vh;
      padding-top: 2.5vh;

      &__addList {
        // padding: 1vh;
        color: Color.$pointColor_1;
        border: none;
      }
      .addListPopup {
        z-index: 9999;
        position: absolute;
        top: -1vw;
        left: 45vh;
        width: 50vw;
        padding: 1vw;
        box-shadow: 1px 1px 8px 1px Color.$gray;
        background-color: Color.$white;

        &__header {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > span {
            font-size: 1.2rem;
          }
        }

        &__body {
          position: relative;
          height: 52vh;
          margin-top: 4vh;
          &__listName {
            & > div {
              gap: 2%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .form-control {
              width: 50%;
              box-shadow: none;
            }
          }
          &__contents {
            margin-top: 4vh;
            padding: 1vh;
            display: flex;
            align-items: center;
            gap: 2%;
            box-shadow: 1px 1px 4px 1px Color.$gray;
            .addBox {
              width: 49%;
              height: 10vh;
              display: block;
              .css-13cymwt-control {
                margin-top: 1vh;
                width: 100%;
              }
              .css-b62m3t-container {
                margin-top: 1vh;
                width: 100%;
              }
            }
            .newList {
              width: 49%;
              height: 10vh;
              p {
                margin: 0;
              }
              & > div {
                height: 5.4vh;
                overflow-x: auto;
              }
              &__userBox {
                float: left;
                display: flex;
                width: 4vw;
                & span {
                  display: flex;
                  align-items: center;
                }
                svg {
                  cursor: pointer;
                  &:hover {
                    fill: orangered;
                  }
                }
              }
            }
            .compareList {
              width: 49%;
              height: 10vh;

              &__oldList {
                height: 5vh;
                overflow-x: auto;
                margin-bottom: 1vh;
                &__userName {
                  margin-right: 0.5vh;
                }
              }
              &__newList {
                height: 5vh;
                overflow-x: auto;
                &__userName {
                  margin-left: 0.5vh;
                  svg {
                    cursor: pointer;
                    &:hover {
                      fill: orangered;
                    }
                  }
                }
              }
            }
          }
        }
        .btn {
          position: relative;
          margin-top: 4vh;
          float: right;
        }
      }

      &__table {
        position: relative;
        width: 98%;
        margin: 0 auto;
        max-height: 19vh;
        overflow-y: auto;
        // background-color: antiquewhite;
        &::-webkit-scrollbar {
          display: none;
        }

        .table {
          & thead {
            position: sticky;
            top: 0;
            background-color: Color.$background_sub;
          }
          & tbody {
            text-align: center;
            & tr {
              & td:nth-child(5) {
                .btn {
                  border: none;
                  width: 2.7vw;
                  height: 1.5vw;
                  display: flex;
                  float: left;
                  margin-left: 1vh;
                  justify-content: center;
                  align-items: center;
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
