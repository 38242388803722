@use '../Color.scss';

.userlog {
  &__header {
    margin-top: 2.5vh;
    display: flex;

    & a {
      width: 12vw;
      height: 4vh;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.3vw;
      background-color: Color.$background_sub;
      color: Color.$black;
      border: none;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &:hover {
        background-color: Color.$background;
      }
      &:active {
        background-color: Color.$background;
      }

      & a.on {
        border-bottom: none;
      }
    }

    .btn-check:checked + .btn,
    :not(.btn-check) + .btn:active,
    .btn:first-child:active,
    .btn.active,
    .btn.show {
      background-color: Color.$background;
      color: Color.$color;
      border: none;
    }
  }
  &__body {
    border-top: solid 2px Color.$background;

    .dateSearch {
      display: flex;
      height: 15vh;
      align-items: center;
      justify-content: space-around;
      margin: 0 auto;
      margin: 1vh 0 1vh 0;

      &__rightBox {
        width: 42%;
        height: 10vh;
        padding: 1vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &__loginRanking {
        width: 20vw;
        height: 15vh;
        padding: 0.5vh;
        border-radius: 1vh;
        display: table-column;
        box-shadow: 1px 1px 4px 1px #bbb;
        & > div {
          margin-top: 0.5vh;
          height: 11vh;
          overflow: auto;
          border-bottom-left-radius: 1vh;
          border-bottom-right-radius: 1vh;
          // 스크롤바 커스텀
          &::-webkit-scrollbar {
            width: 0.8vw;
          }
          &::-webkit-scrollbar-thumb {
            background-color: rgb(117, 117, 117);
            border-radius: 0.5vh;
            background-clip: padding-box;
            border: 2px solid transparent;
          }
          &::-webkit-scrollbar-track {
            background-color: #dcdcdc;
            border-radius: 0.5vh;
            box-shadow: inset 0px 0px 5px white;
          }
        }
        .table {
          text-align: center;
          font-size: 0.8rem;
          & > thead {
            position: sticky;
            top: 0;
            background-color: Color.$background_sub;
          }
        }
      }

      &__ruleRanking {
        width: 42%;
        height: 15vh;
        padding: 0.5vh;
        border-radius: 1vh;
        box-shadow: 1px 1px 4px 1px #bbb;

        & > div {
          margin-top: 0.5vh;
          height: 11vh;
          overflow: auto;
          border-bottom-left-radius: 1vh;
          border-bottom-right-radius: 1vh;
          // 스크롤바 커스텀
          &::-webkit-scrollbar {
            width: 0.8vw;
          }
          &::-webkit-scrollbar-thumb {
            background-color: rgb(117, 117, 117);
            border-radius: 0.5vh;
            background-clip: padding-box;
            border: 2px solid transparent;
          }
          &::-webkit-scrollbar-track {
            background-color: #dcdcdc;
            border-radius: 0.5vh;
            box-shadow: inset 0px 0px 5px white;
          }
        }
        .table {
          font-size: 0.8rem;
          & > thead {
            position: sticky;
            top: 0;
            background-color: Color.$background_sub;
          }
        }
      }

      &__dateBox {
        width: 55%;
        height: 15vh;
        display: flex;
        align-items: center;
        border-radius: 1vh;
        box-shadow: 1px 1px 4px 1px #bbb;
        justify-content: space-around;

        & Button {
          background-color: Color.$white;
          border-color: Color.$black;
          color: Color.$black;
          cursor: pointer;
          margin-right: 0.5vw;
        }
      }

      .btn-check:checked + .btn,
      :not(.btn-check) + .btn:active,
      .btn:first-child:active,
      .btn.active,
      .btn.show {
        color: Color.$white;
        background-color: Color.$background;
      }
      .periodbtns {
        margin-left: 1vw;
      }

      .dateSelect {
        display: flex;
        align-items: center;

        & span {
          margin: 0.5vw;
        }

        & input {
          margin-right: 0.5vw;
          padding: 6px;
          border: 1px solid Color.$background;
          border-radius: 5px;
        }

        &__dateBtn {
          border-color: Color.$black;
          color: Color.$black;
          margin-right: 0.5vw;
        }
      }
    }
  }

  .userlogBody {
    width: 90vw;
    margin: 0 auto;

    &__subHeader {
      display: flex;
      justify-content: space-between;

      & .btnbox {
        display: flex;
        justify-content: space-between;
        gap: 0.5vw;
        .btn {
          height: 4vh;
        }
      }
    }

    .userlogSearch {
      margin-bottom: 0.5vh;
      display: flex;

      & > select {
        border: none;
      }

      &__searchBox {
        width: 20vw;
        input {
          height: 3.8vh;
        }
        .btn {
          height: 3.8vh;
          svg {
            display: flex;
            align-items: center;
            margin: 0;
          }
        }
      }

      & .reset__btn {
        margin-left: 0.5vw;
        // height: 3.3vh;
        // width: 5vw;
        font-size: 0.9rem;
        align-self: center;
        // padding: 0.4vh 0;
      }
    }

    &__logSearch {
      margin-bottom: 1vh;
      display: flex;

      #dropdown-basic {
        height: 3.5vh;
        margin-right: 10px;
        line-height: 5px;
        border: none;
      }

      .dropdown-toggle::after {
        margin-left: 1.3rem;
      }

      .dropdown-item:active {
        color: Color.$black;
        background-color: Color.$gray;
      }
      &__category {
        border-bottom: 2px solid Color.$pointColor;
        margin-right: 10px;
      }

      &__subCategory {
        border-bottom: 2px solid Color.$pointColor;
        padding-left: 10px;
      }
      .dropdown-menu.show {
        max-height: 50vh;
        max-width: 40vw;
        overflow: auto;
        overflow-x: hidden;
      }
    }

    &__table {
      max-height: 58vh;
      overflow-y: auto;
      // 스크롤바 커스텀
      &::-webkit-scrollbar {
        width: 0.8vw;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgb(117, 117, 117);
        border-radius: 0.5vh;
        background-clip: padding-box;
        border: 2px solid transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: #dcdcdc;
        border-radius: 0.5vh;
        box-shadow: inset 0px 0px 5px white;
      }
    }
    & Table {
      #dropdown-basic {
        line-height: 5px;
        border: none;
      }
      .dropdown-item:active {
        color: Color.$black;
        background-color: Color.$gray;
      }
      & thead {
        position: sticky;
        top: 0;
        text-align: center;
        background-color: Color.$background_sub;
        & tr {
          height: 4vh;
          & th {
            position: sticky;
            top: 0;
          }
        }
      }
      & tbody {
        max-height: 53vh;
        text-align: center;
      }
    }
  }
}
